import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createScheduleFeedSecretQueryKey } from '@/utils/queryKeys';
import {
  GetScheduleFeedQuery,
  GetScheduleFeedQueryVariables,
} from './useScheduleFeed.generated';

const query = gql`
  query GetScheduleFeed($scheduleId: ID!) {
    getSchedule(scheduleId: $scheduleId) {
      id
      feedSecret {
        slug
        enabled
      }
    }
  }
`;

const selector = (data: GetScheduleFeedQuery) => data.getSchedule;

export const useScheduleFeedSecret = (enabled: boolean) => {
  const { scheduleId } = useScheduleContext();

  return useQuery({
    queryKey: createScheduleFeedSecretQueryKey(scheduleId),
    queryFn: () =>
      gqlClient.request<GetScheduleFeedQuery, GetScheduleFeedQueryVariables>(
        query,
        { scheduleId }
      ),
    enabled,
    refetchOnWindowFocus: false,
    select: selector,
  });
};
