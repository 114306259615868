import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  ToggleScheduleFeedEnabledMutation,
  ToggleScheduleFeedEnabledMutationVariables,
} from './useToggleScheduleFeedEnabled.generated';

const query = gql`
  mutation ToggleScheduleFeedEnabled($scheduleId: ID!, $enabled: Boolean!) {
    toggleFeedSecret(scheduleId: $scheduleId, enabled: $enabled)
  }
`;

export const useToggleScheduleFeedEnabled = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    ToggleScheduleFeedEnabledMutation,
    QueryError,
    ToggleScheduleFeedEnabledMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        ToggleScheduleFeedEnabledMutation,
        ToggleScheduleFeedEnabledMutationVariables
      >(query, variables),
    onMutate: (variables) => {
      return {
        optimisticData: {
          id: scheduleId,
          feedSecret: {
            enabled: variables.enabled,
          },
        },
        rollbackData: {
          id: scheduleId,
          feedSecret: !variables.enabled,
        },
      };
    },
  });

  const toggleEnabled = (
    enabled: boolean,
    options?: MutateOptions<
      ToggleScheduleFeedEnabledMutation,
      QueryError,
      ToggleScheduleFeedEnabledMutationVariables
    >
  ) => mutate({ scheduleId, enabled }, options);

  return {
    toggleEnabled,
  };
};
