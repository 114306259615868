import { Button, ButtonProps } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

type Props = ButtonProps & {
  to: string;
};

export const Link = ({ to, ...props }: Props): JSX.Element => {
  return (
    <Button
      as={RouterLink}
      fontWeight="normal"
      to={to}
      variant="link"
      {...props}
    />
  );
};
