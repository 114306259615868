import { Button } from '@chakra-ui/react';
import { getSigninUrl } from '@/components/auth/utils/googleCalendar';
import { useCurrentUserContext } from '@/contexts';
import { useIsExtension } from '@/pages/QuickCreate/hooks/useIsExtension';
import { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';

type Props = {
  integration: DecoratedIntegration;
};

export const ReconnectButton = ({ integration }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { isExtension } = useIsExtension();

  const signinUrl = getSigninUrl(
    currentUser,
    location.pathname,
    {},
    {
      email: integration.externalId,
    }
  );

  return (
    <Button
      as="a"
      colorScheme="dark"
      href={signinUrl}
      size="xs"
      target={isExtension ? '_blank' : '_self'}
      variant="secondary"
    >
      Enable Magic
    </Button>
  );
};
