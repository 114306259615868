import { User } from 'configcat-common';
import { useFeatureFlag as useConfigCatFeatureFlag } from 'configcat-react';
import { v4 as uuidv4 } from 'uuid';
import { useCurrentUserContext } from '@/contexts';
import { useUnsafeScheduleContext } from '@/pages/Schedule/contexts';
import { type FeatureFlag, defaultFeatureValues } from './defaults';

export const useFeatureFlag = <T extends FeatureFlag>(feature: T) => {
  const { currentUser } = useCurrentUserContext();
  const scheduleContext = useUnsafeScheduleContext();
  const defaultValue = defaultFeatureValues[feature];

  const customProps = {
    campaign: currentUser?.campaign ?? '',
    currentScheduleId: scheduleContext?.scheduleId ?? '',
  };

  const user: User = currentUser
    ? {
        identifier: currentUser.id,
        email: currentUser.email ?? currentUser.id,
        custom: {
          ...customProps,
          userStatus: currentUser.status,
        },
      }
    : {
        identifier: uuidv4(),
        custom: {
          ...customProps,
          userStatus: 'public',
        },
      };

  return useConfigCatFeatureFlag(feature, defaultValue, user);
};
