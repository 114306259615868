import { Outlet, useLocation } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { ScheduleContextProvider } from '@/pages/Schedule/contexts';
import {
  SnippetContainer,
  SnippetLocationState,
} from '@/pages/Schedule/pages/ScheduleSnippet';
import { SuspenseContainer } from './SuspenseContainer';

/**
 * Render the suspense fallback loader inside a ScheduleContext
 * (and with it, the full ScheduleHeader) if the previous route
 * provided a scheduleId.
 */
export const SnippetSuspenseFallback = () => {
  const state = useLocation().state as SnippetLocationState;
  const scheduleId = state?.scheduleId;

  const fallback = scheduleId ? (
    <ScheduleContextProvider scheduleSlug={scheduleId}>
      <SnippetContainer scheduleId={scheduleId}>
        <AppLoader />
      </SnippetContainer>
    </ScheduleContextProvider>
  ) : (
    <AppLoader />
  );

  return (
    <SuspenseContainer fallback={fallback}>
      <Outlet />
    </SuspenseContainer>
  );
};
