import { Flex } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import { clsx } from 'clsx';
import { MutableRefObject, forwardRef } from 'react';
import {
  RichTextEditor,
  RichTextEditorProps,
} from '@/pages/Schedule/components';
import classes from './PromptTextArea.module.scss';

type Props = Omit<RichTextEditorProps, 'ref' | 'value'> & {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  value: string;
};

export const PromptTextArea = forwardRef<Editor, Props>(
  function PromptTextAreaWithRef({ containerRef, className, ...props }, ref) {
    const editorClasses = clsx(className, classes.editor);

    return (
      <Flex
        borderColor="gray.400"
        borderRadius="xl"
        borderWidth="1px"
        cursor="text"
        h="250px"
        overflowY="auto"
        p="3"
        ref={containerRef}
        shadow="0px 0px 30px 2px rgba(0, 0, 0, 0.1)"
        transition="all 0.2s ease-in-out"
      >
        <RichTextEditor className={editorClasses} ref={ref} {...props} />
      </Flex>
    );
  }
);
