import { forwardRef, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { MotionFlex } from '@/components/MotionFlex';
import { EntriesJobType } from '@/types/gql.generated';
import { Prompt } from '../../types';
import { PromptForm } from './PromptForm';

type Props = {
  instructions: string;
  jobType: EntriesJobType;
  prompt: Prompt;
  promptRef: (node: HTMLDivElement | null) => void;
  onCancel: () => void;
  onChangeJobType: (jobType: EntriesJobType) => void;
  onInstructionsChange: (instructions: string) => void;
  onManualClick: () => void;
  onPromptChange: (prompt: Partial<Prompt>) => void;
  onSubmit: () => void;
  onDropText: (text: string) => void;
};

export const PromptState = forwardRef(
  (
    {
      instructions,
      jobType,
      prompt,
      promptRef,
      onCancel,
      onChangeJobType,
      onInstructionsChange,
      onManualClick,
      onPromptChange,
      onSubmit,
      onDropText,
    }: Props,
    ref
  ) => {
    const { t } = useTranslation('batchCreate');

    return (
      <MotionFlex
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 1 }}
        ref={ref}
        transition={{ duration: 0.1 }}
        w="100%"
      >
        <Flex direction="column" gap="5" ref={promptRef} w="100%">
          <Text>
            <Trans i18nKey="byline" t={t} />
          </Text>
          <PromptForm
            instructions={instructions}
            jobType={jobType}
            prompt={prompt}
            onCancel={onCancel}
            onChangeJobType={onChangeJobType}
            onDropText={onDropText}
            onInstructionsChange={onInstructionsChange}
            onManualClick={onManualClick}
            onPromptChange={onPromptChange}
            onSubmit={onSubmit}
          />
        </Flex>
      </MotionFlex>
    );
  }
);
