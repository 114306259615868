import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  UpdateScheduleDescriptionMutation,
  UpdateScheduleDescriptionMutationVariables,
} from './useUpdateScheduleDescription.generated';

const query = gql`
  mutation UpdateScheduleDescription($scheduleId: ID!, $description: String) {
    updateSchedule(scheduleId: $scheduleId, description: $description) {
      id
      description
    }
  }
`;

export const useUpdateScheduleDescription = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    UpdateScheduleDescriptionMutation,
    QueryError,
    UpdateScheduleDescriptionMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateScheduleDescriptionMutation,
        UpdateScheduleDescriptionMutationVariables
      >(query, variables),
  });

  const updateScheduleDescription = (
    description: string,
    options?: MutateOptions<
      UpdateScheduleDescriptionMutation,
      QueryError,
      UpdateScheduleDescriptionMutationVariables
    >
  ) => {
    return mutate({ scheduleId, description }, options);
  };

  return {
    updateScheduleDescription,
  };
};
