import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { TIME_ZONE_UTC } from '@/constants';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { EntryType } from '@/types/gql.generated';
import { createContextId } from '../../utils';

export const createDraftEntryObject = (
  startDate: DateTime = DateTime.now().startOf('day'),
  scheduleId: string,
  props: Partial<DecoratedEntry> = {}
): DecoratedEntry => {
  const endDate = startDate.startOf('day').plus({ days: 1 });
  const entryId = uuidv4();
  const contextId = createContextId(scheduleId, entryId);

  const timeZone =
    props.recurrences?.[0].isOnDay === false
      ? startDate.zoneName
      : TIME_ZONE_UTC;

  return {
    title: '',
    type: EntryType.Schedule,
    locationWithPlace: {
      name: '',
    },
    emoji: null,
    category: null,
    feed: null,
    description: '',
    isFollowing: false,
    rsvpSettings: {
      enabled: false,
    },
    exclusions: [],
    hidden: [],
    timeZone,
    recurrences: [
      {
        startDate,
        endDate,
        isOnDay: true,
        rule: null,
      },
    ],
    labels: [],
    whoLabels: [],
    createdAt: DateTime.now(),
    ...props,
    id: entryId,
    contextId,
  };
};
