import { GraphQLErrorExtensions } from 'graphql';
import { ClientError as GraphQLError } from 'graphql-request';
import { i18n } from '@/i18n';
import { ErrorCodes } from '@/types/gql.generated';

export { GraphQLError };
export type QueryError = GraphQLError | Error;
export type ErrorExtensionsData = Record<string, unknown>;

const getDefaultErrorCodeMessage = (code: ErrorCodes): string | undefined => {
  const t = i18n.getFixedT('en', 'common', 'default_error_code_messages');

  const defaultErrorCodeMessages: { [key in ErrorCodes]?: string } = {
    [ErrorCodes.EntryScheduledMessageLimitReached]: t(
      'EntryScheduledMessageLimitReached'
    ),
    [ErrorCodes.EntryMessageInPast]: t('EntryMessageInPast'),
  };

  return defaultErrorCodeMessages[code];
};

export const getGQLErrorCode = (error: GraphQLError): ErrorCodes => {
  return error.response.errors?.[0].extensions?.code as ErrorCodes;
};

export const getGQLErrorMessage = (error: GraphQLError): string | undefined => {
  return error.response.errors?.[0]?.message;
};

/**
 * Is the `error` input a GQL error vs a network or some other type of error?
 */
export const isGQLError = (error: unknown): error is GraphQLError => {
  return error instanceof GraphQLError;
};

/**
 * If the `error` input a GQL error AND an error with a specific error code
 * that we've defined?
 */
export const isGQLErrorOfType = (
  error: unknown,
  type: keyof typeof ErrorCodes
): error is GraphQLError => {
  if (isGQLError(error)) {
    return getGQLErrorCode(error) === ErrorCodes[type];
  }
  return false;
};

/**
 * Extract an error message from a react-query error
 */
export const getErrorMessage = (error: QueryError): string => {
  if (!isGQLError(error)) {
    return error.message;
  }

  const code = getGQLErrorCode(error);
  let message = getGQLErrorMessage(error);

  // server defaults to using the error code as the message when
  // a human readable message isn't provided
  if (code && (!message || message === code)) {
    message = getDefaultErrorCodeMessage(code);
  }
  if (!message) {
    message = error.message;
  }

  return message;
};

const isGQLErrorExtensions = (
  extensions: unknown
): extensions is GraphQLErrorExtensions => {
  return typeof extensions === 'object' && extensions !== null;
};

const isGQLErrorExtensionsData = (
  extensionsData: unknown
): extensionsData is ErrorExtensionsData => {
  return (
    !!extensionsData &&
    typeof extensionsData === 'object' &&
    extensionsData !== null
  );
};

/**
 * Return custom data associated with a GraphQL error (if exists).
 */
export const getGQLErrorExtensionData = (
  error: GraphQLError
): ErrorExtensionsData | undefined => {
  const extensions = error.response?.errors?.[0].extensions;

  if (
    isGQLErrorExtensions(extensions) &&
    isGQLErrorExtensionsData(extensions.data)
  ) {
    return extensions.data;
  }

  return undefined;
};

/**
 * Generic way to wrap an error with one of our existing error codes.
 * This is commonly used when fetching a job that has failed (network request is 200)
 * but the job contains an error code in its response.
 */
export class ErrorWithCode extends Error {
  public code: ErrorCodes;

  constructor(message: string, code?: ErrorCodes | null) {
    super(message);
    this.code = code || ErrorCodes.UnknownError;
  }
}

export const isErrorWithCode = (error: unknown): error is ErrorWithCode => {
  return error instanceof ErrorWithCode;
};
