import { useCallback } from 'react';
import {
  NavigateOptions,
  createSearchParams,
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import { Schedule } from '@/types/gql.generated';
import { getScheduleSlug } from '@/utils/schedule';

export const useNavigateToSchedule = () => {
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();

  const navigateToSchedule = useCallback(
    (schedule: Pick<Schedule, 'id' | 'title'>, options?: NavigateOptions) => {
      // TODO make this easier
      const newParams = createSearchParams(Object.fromEntries(queryParams));
      newParams.delete('categories');
      newParams.delete('who');
      const slug = getScheduleSlug(schedule);
      let url = generatePath('/schedule/:slug', { slug });
      if (window.location.pathname.includes('/messages')) {
        url += '/messages';
      }
      url += `?${newParams.toString()}`;
      navigate(url, options);
    },
    [navigate, queryParams]
  );

  return { navigateToSchedule };
};
