import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ScheduleFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { isGQLErrorOfType } from '@/utils/errors';
import { createScheduleQueryKey } from '@/utils/queryKeys';
import { DecoratedSchedule } from '../types';
import {
  GetScheduleQuery,
  GetScheduleQueryVariables,
} from './useSchedule.generated';

const query = gql`
  ${ScheduleFragment}
  query GetSchedule($scheduleId: ID!) {
    getSchedule(scheduleId: $scheduleId) {
      ...Schedule
    }
  }
`;

const selector = (data: GetScheduleQuery): DecoratedSchedule => {
  return data.getSchedule;
};

export const useSchedule = (scheduleId: string) => {
  return useQuery({
    queryKey: createScheduleQueryKey(scheduleId),
    select: selector,
    queryFn: () => {
      return gqlClient.request<GetScheduleQuery, GetScheduleQueryVariables>(
        query,
        { scheduleId }
      );
    },
    refetchOnWindowFocus: (query) => {
      if (
        isGQLErrorOfType(query.state.error, 'ScheduleNotFound') ||
        isGQLErrorOfType(query.state.error, 'InvalidPermission')
      ) {
        return false;
      }
      return true;
    },
  });
};
