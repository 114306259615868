import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';

type Props = {
  note?: string;
  onSubmit: (text: string | null, close: () => void) => void;
  isLoading?: boolean;
  flip?: boolean;
};

export const NotesButton = ({
  note: defaultText,
  isLoading = false,
  flip,
  onSubmit,
}: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const measureRef = useRef<HTMLDivElement>(null);
  const debouncedPending = useDebounceLoading(isLoading);
  const { t } = useTranslation('rsvpBar', { keyPrefix: 'notes' });
  const [value, setValue] = useState<string>(defaultText || '');

  useEffect(() => {
    setValue(defaultText ?? '');
  }, [defaultText]);

  const ensureInView = () => {
    setTimeout(() => {
      measureRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }, 1);
  };

  return (
    <Popover
      flip={flip}
      initialFocusRef={textareaRef}
      modifiers={[{ name: 'preventOverflow', options: { padding: 24 } }]}
      onOpen={ensureInView}
    >
      {({ onClose, isOpen }) => {
        const handleSubmit = (inputValue: string | null) => {
          const value =
            (typeof inputValue === 'string' && inputValue.trim()) || null;

          if (value !== defaultText) {
            onSubmit(value, onClose);
          } else {
            onClose();
          }
        };

        return (
          <>
            <PopoverTrigger>
              <Button
                color="customgray.mid"
                colorScheme="dark"
                size="sm"
                textAlign="left"
                variant="link"
                whiteSpace="normal"
              >
                {defaultText ? (
                  <Text
                    as="span"
                    flexShrink="1"
                    maxW={{ md: '232px' }}
                    minW="0"
                    noOfLines={{ base: 2, md: 3 }}
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {defaultText}
                  </Text>
                ) : (
                  <span>{t('add_note')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent borderRadius="lg">
                <PopoverArrow />
                <PopoverBody
                  as="form"
                  pos="relative"
                  py="4"
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(value);
                  }}
                >
                  <Text fontWeight="medium">{t('title')}</Text>
                  <Textarea
                    mt="2"
                    placeholder={t('placeholder')}
                    ref={textareaRef}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === 'Escape') {
                        onClose();
                        event.stopPropagation();
                      }
                    }}
                  />
                  <Flex align="center" gap="4" justify="space-between" pt="3">
                    {defaultText && isOpen && (
                      <Button
                        colorScheme="dark"
                        size="sm"
                        variant="link"
                        onClick={() => handleSubmit(null)}
                      >
                        {t('remove')}
                      </Button>
                    )}
                    <Flex
                      gap="2"
                      justify="flex-end"
                      justifySelf="flex-end"
                      ml="auto"
                    >
                      <Button size="sm" variant="secondary" onClick={onClose}>
                        {t('cancel')}
                      </Button>
                      <Button
                        isLoading={debouncedPending}
                        size="sm"
                        type="submit"
                      >
                        {t('submit')}
                      </Button>
                    </Flex>
                  </Flex>

                  <Box
                    bottom="-5"
                    left="0"
                    pos="absolute"
                    ref={measureRef}
                    top={{ base: '-44', md: '-24' }}
                    w="1px"
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        );
      }}
    </Popover>
  );
};
