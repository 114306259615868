import { Button, ButtonProps, Divider, Flex, Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { BiPlusCircle } from 'react-icons/bi';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { BannerButton } from './BannerButton';
import { useMouseWithinPixelsFromTop } from './useWithinPixelsFromTop';

export const AddBannerButton = (props: ButtonProps) => {
  const { t } = useTranslation('schedule', { keyPrefix: 'banner' });
  const isMobileBreakpoint = useIsMobileBreakpoint();

  // 175 is roughly the height of the header + action bar + the space in between
  const activated = useMouseWithinPixelsFromTop(175);

  return (
    <Flex justify="center" transform="translateY(-50%)" w="100%">
      {isMobileBreakpoint ? (
        <>
          <Divider />
          <Button
            alignItems="center"
            alignSelf="center"
            bg="white"
            colorScheme="dark"
            leftIcon={<Icon as={BiPlusCircle} boxSize="18px" />}
            pos="absolute"
            size="sm"
            variant="ghost"
            {...props}
          >
            Add banner
          </Button>
        </>
      ) : (
        <BannerButton
          _hover={{ bg: 'customgray.darker' }}
          alignSelf="center"
          as={motion.button}
          bg="customgray.alsodarker"
          opacity={activated ? 1 : 0}
          pos="absolute"
          size="xs"
          zIndex="2"
          {...props}
        >
          {t('add_action')}
        </BannerButton>
      )}
    </Flex>
  );
};
