import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSchedulesCache } from '@/hooks/useSchedulesCache';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import { createSchedulesQueryKey } from '@/utils/queryKeys';
import {
  DeleteScheduleMutation,
  DeleteScheduleMutationVariables,
} from './useDeleteSchedule.generated';

const query = gql`
  mutation DeleteSchedule($scheduleId: ID!) {
    deleteSchedule(scheduleId: $scheduleId)
  }
`;

export const useDeleteSchedule = () => {
  const { scheduleId } = useScheduleContext();
  const { removeSchedule } = useSchedulesCache();

  const { mutate, ...rest } = useMutation<
    DeleteScheduleMutation,
    QueryError,
    DeleteScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteScheduleMutation,
        DeleteScheduleMutationVariables
      >(query, variables),
  });

  const deleteSchedule = (
    options?: MutateOptions<
      DeleteScheduleMutation,
      QueryError,
      DeleteScheduleMutationVariables
    >
  ) => {
    mutate(
      { scheduleId },
      {
        onSuccess: (...args) => {
          removeSchedule(scheduleId);

          // Timeout ensures state is updated before navigating
          setTimeout(() => options?.onSuccess?.(...args));

          // clear everything in case this schedule is a source on another schedule
          queryClient.removeQueries({
            queryKey: createSchedulesQueryKey(),
          });
        },
      }
    );
  };

  return {
    ...rest,
    deleteSchedule,
  };
};
