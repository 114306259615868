import { StyleFunctionProps } from '@chakra-ui/react';

export const Popover = {
  baseStyle: ({ colorMode }: StyleFunctionProps) => ({
    content: {
      bg: colorMode === 'dark' ? 'dark.600' : undefined,
      color: colorMode === 'dark' ? 'white' : undefined,
      borderRadius: '2xl',
    },
  }),
};
