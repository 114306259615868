import { create } from 'zustand';
import { PlatformTypes } from '@/types/gql.generated';
import { DecoratedQuickCreateEntry } from '../types';

type State = {
  isOpen: boolean;
  platform: PlatformTypes | null;
  relatedEntry: DecoratedQuickCreateEntry | null;
};

type Actions = {
  trigger: (
    entry: DecoratedQuickCreateEntry | null,
    platform: PlatformTypes
  ) => void;
  dismiss: () => void;
};

type Store = State & Actions;

const initialState: State = {
  isOpen: false,
  platform: null,
  relatedEntry: null,
};

export const usePinChangeTip = create<Store>((set) => ({
  ...initialState,
  dismiss: () => set({ isOpen: false, platform: null, relatedEntry: null }),
  trigger: (relatedEntry, platform) =>
    set({ isOpen: true, platform, relatedEntry }),
}));
