import { gql } from 'graphql-request';
import { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { gqlWsClient, handleError } from '@/lib/gql-client';
import { queryClient } from '@/lib/query-client';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import {
  EntriesAddedSubscription,
  EntriesAddedSubscriptionVariables,
} from './useEntriesAddedSubscription.generated';

const query = gql`
  subscription EntriesAdded(
    $scheduleId: ID!
    $includeMessages: Boolean!
    $includeRsvps: Boolean!
  ) {
    entriesAdded(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const useEntriesAddedSubscription = (scheduleId: string) => {
  const handleData = useCallback(
    ({ data }: ExecutionResult<EntriesAddedSubscription>) => {
      if (data?.entriesAdded) {
        queryClient.invalidateQueries({
          queryKey: createEntriesQueryKey(scheduleId),
        });
      }
    },
    [scheduleId]
  );

  useEffect(() => {
    const variables: EntriesAddedSubscriptionVariables = {
      scheduleId,
      // It's not possible to create an item with messages or rsvps in a single transaction
      includeMessages: false,
      includeRsvps: false,
    };

    const unsubscribe = gqlWsClient.subscribe<EntriesAddedSubscription>(
      {
        query: query,
        variables,
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, handleData]);
};
