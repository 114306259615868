import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CopyLinkButton } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useTogglePublicSchedule } from '../hooks/useTogglePublicSchedule';

export const PublicLinkSection = () => {
  const { t } = useTranslation('shareModal');
  const { schedule } = useScheduleContext();
  const { togglePublicSchedule } = useTogglePublicSchedule();
  const isPublic = schedule?.isPublic ?? false;

  return (
    <Flex align="center" justify="space-between" w="100%">
      <Menu isLazy>
        <MenuButton
          as={Button}
          border="none"
          colorScheme="dark"
          fontSize="md"
          fontWeight="normal"
          ml="-4"
          rightIcon={<ChevronDownIcon height="5" width="5" />}
          variant="ghost"
        >
          {isPublic ? (
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              Anyone with the link{' '}
              <strong style={{ fontWeight: '500' }}>can view</strong>
            </Text>
          ) : (
            <span>
              Only invited users{' '}
              <strong style={{ fontWeight: '500' }}>can access</strong>
            </span>
          )}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            value={String(isPublic)}
            onChange={(value) => togglePublicSchedule(value === 'true')}
          >
            <MenuItemOption value="true">
              {t('public_link_access.public')}
            </MenuItemOption>
            <MenuItemOption value="false">
              {t('public_link_access.private')}
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      {isPublic && <CopyLinkButton />}
    </Flex>
  );
};
