import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { AiDisabledAlert } from '@/components/AiDisabledAlert';
import { Collapse } from '@/components/Collapse';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useCreateDraftEntry } from '@/pages/Schedule/hooks';
import { EntriesJobType } from '@/types/gql.generated';
import { ConversionType, getConversionType } from '@/utils/convertEntry';
import { ConfirmDialog } from '../ConfirmDialog';
import { ContentContainer } from './components/ContentContainer';
import { CreateButton } from './components/CreateButton';
import { EmptyState } from './components/EmptyState';
import { LoadingState } from './components/LoadingState';
import { ModalBody } from './components/ModalBody';
import { ModalContent } from './components/ModalContent';
import { PromptState } from './components/PromptState';
import { ResultsState } from './components/ResultsState';
import { SuccessState } from './components/SuccessState';
import { useCreateEntriesJobFromFile } from './hooks/useCreateEntriesJobFromFile';
import { useCreateEntriesJobFromPrompt } from './hooks/useCreateEntriesJobFromPrompt';
import { useEntryModalScrollFix } from './hooks/useEntryModalScrollFix';
import { useMeasurePromptHeight } from './hooks/useMeasurePromptHeight';
import { usePollEntriesJobStatus } from './hooks/usePollEntriesJobStatus';
import { usePrompt } from './hooks/usePrompt';
import { usePublishJobEntries } from './hooks/usePublishJobEntries';
import { useResetKey } from './hooks/useResetKey';

type Props = ButtonProps & {
  buttonComponent?: JSX.Element;
};

export const BatchCreateModal = ({ buttonComponent, ...props }: Props) => {
  const { trackEvent } = useAnalytics();
  const [instructions, setInstructions] = useState('');
  const [jobType, setJobType] = useState(EntriesJobType.Prompt);
  const { t } = useTranslation('batchCreate');
  const { isOpen, onOpen: open, onClose: close } = useDisclosure();
  const { createDraftEntry } = useCreateDraftEntry();
  const { prompt, setPrompt, resetPrompt } = usePrompt();
  const { resetKey, generateResetKey } = useResetKey();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { isEntryModalOpen } = useEntryModalScrollFix();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );
  const {
    isOpen: isConfirmOpen,
    onOpen: openConfirm,
    onClose: closeConfirm,
  } = useDisclosure();

  const {
    poll,
    job,
    error: pollJobError,
    isSuccess: pollJobSuccess,
    reset: resetPollJob,
  } = usePollEntriesJobStatus();

  const {
    createEntriesJobFromPrompt,
    error: fromPromptError,
    reset: resetFromPrompt,
  } = useCreateEntriesJobFromPrompt();

  const {
    createEntriesJobFromFile,
    error: fromFileError,
    reset: resetFromFile,
  } = useCreateEntriesJobFromFile();

  const {
    publishJobEntries,
    isPending: isPublishLoading,
    isSuccess: isPublishSuccess,
    reset: resetPublish,
  } = usePublishJobEntries();

  const { promptRef, promptHeight, setMeasurePromptHeightEnabled } =
    useMeasurePromptHeight();

  const error = fromPromptError || fromFileError || pollJobError;

  const [isPending, setPending] = useState(false);
  useEffect(() => {
    if (pollJobError || pollJobSuccess) {
      setPending(false);
    }
  }, [pollJobError, pollJobSuccess]);

  const handleSubmit = async () => {
    setPending(true);
    setMeasurePromptHeightEnabled(false);

    if (jobType === EntriesJobType.Prompt) {
      trackEvent('ai-add:convert content', {
        conversionType: ConversionType.FormInput,
      });
      createEntriesJobFromPrompt(prompt.html, {
        onSuccess: (data) => {
          if (data.createEntriesJobFromPrompt?.id) {
            poll(data.createEntriesJobFromPrompt.id);
          }
        },
      });
    } else if (jobType === EntriesJobType.File && prompt.file) {
      trackEvent('ai-add:convert content', {
        conversionType: getConversionType(
          {
            file: prompt.file,
            base64: null,
            html: '',
            text: '',
          },
          'user-input'
        ),
      });
      createEntriesJobFromFile(prompt.file, instructions, {
        onSuccess: (data) => {
          if (data.createEntriesJobFromFile?.id) {
            poll(data.createEntriesJobFromFile.id);
          }
        },
      });
    }
  };

  const handleManualClick = () => {
    close();
    createDraftEntry();
    trackEvent('ai-add:click add manually');
  };

  const handlePublish = () => {
    if (job) {
      publishJobEntries(job.id);
      trackEvent('ai-add:click add to schedule');
    }
  };

  const handleClose = () => {
    if (isPending || (!isPublishSuccess && job && job?.entries.length > 0)) {
      openConfirm();
    } else {
      close();
    }
  };

  const handleCloseConfirm = () => {
    closeConfirm();
    close();
  };

  const handleDropText = (text: string) => {
    setPrompt({ html: text });
    setJobType(EntriesJobType.Prompt);
  };

  const handleReset = () => {
    resetFromFile();
    resetFromPrompt();
    resetPollJob();
    resetPrompt();
    resetPublish();
    setPending(false);
    setMeasurePromptHeightEnabled(true);
    setInstructions('');
    generateResetKey();
  };

  return (
    <>
      <CreateButton
        {...props}
        buttonComponent={buttonComponent}
        onClick={open}
      />

      <ConfirmDialog
        cancelButtonText={t('close_confirm.cancel')}
        confirmButtonText={t('close_confirm.confirm')}
        isOpen={isConfirmOpen}
        title={t('close_confirm.title')}
        onCancel={closeConfirm}
        onConfirm={handleCloseConfirm}
      />

      <Modal
        blockScrollOnMount={!isEntryModalOpen}
        closeOnEsc={!isPending}
        closeOnOverlayClick={!isPending}
        isCentered
        isOpen={isOpen}
        onClose={handleClose}
        onCloseComplete={handleReset}
      >
        <ModalOverlay />

        <ModalContent key={resetKey} pollJobSuccess={pollJobSuccess}>
          <ModalHeader
            borderBottomColor="customgray.gray"
            borderBottomWidth="1px"
            fontWeight="medium"
            lineHeight="1"
            pb="6"
            pt="7"
            px={{ base: 6, md: 8 }}
          >
            {t('title')}
          </ModalHeader>

          <ModalCloseButton right={{ base: 6, md: 8 }} top="22px" />

          <ModalBody pollJobSuccess={pollJobSuccess}>
            {disableAllAiFeatures ? (
              <>
                <AiDisabledAlert />
                <Button
                  colorScheme="dark"
                  mt="4"
                  rightIcon={<Icon as={FiArrowRight} />}
                  size="lg"
                  variant="link"
                  onClick={handleManualClick}
                >
                  {t('manual_entry')}
                </Button>
              </>
            ) : (
              <ContentContainer pollJobSuccess={pollJobSuccess}>
                {error && (
                  <Collapse in={!!error} w="100%">
                    {error && (
                      <QuickCreateErrorMessage error={error} w="100%" />
                    )}
                  </Collapse>
                )}

                <AnimatePresence
                  // let the loading state animate out first before bringing
                  // in the results. prevents the loading state from fading
                  // out while the expanding animation is in progress, which
                  // doesn't look great'
                  mode={
                    pollJobSuccess && !isMobileBreakpoint ? 'wait' : 'popLayout'
                  }
                >
                  {isPending ? (
                    <LoadingState key="loading" minH={`${promptHeight}px`} />
                  ) : isPublishSuccess ? (
                    <SuccessState
                      key="success"
                      onClose={handleClose}
                      onReset={handleReset}
                    />
                  ) : job && !job.entries.length ? (
                    <EmptyState
                      key="empty"
                      onClose={handleClose}
                      onReset={handleReset}
                    />
                  ) : job && job.entries.length > 0 ? (
                    <ResultsState
                      isPublishLoading={isPublishLoading}
                      job={job}
                      key="results"
                      onPublish={handlePublish}
                    />
                  ) : (
                    <PromptState
                      instructions={instructions}
                      jobType={jobType}
                      key="prompt"
                      prompt={prompt}
                      promptRef={promptRef}
                      onCancel={close}
                      onChangeJobType={setJobType}
                      onDropText={handleDropText}
                      onInstructionsChange={setInstructions}
                      onManualClick={handleManualClick}
                      onPromptChange={setPrompt}
                      onSubmit={handleSubmit}
                    />
                  )}
                </AnimatePresence>
              </ContentContainer>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
