import { gql } from 'graphql-request';
import { useCallback, useState } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { createAuthActionQueryKey } from '@/utils/queryKeys';
import {
  GetAuthActionQuery,
  GetAuthActionQueryVariables,
} from './useAuthAction.generated';

const query = gql`
  query GetAuthAction($email: LowercaseString!) {
    getAuthAction(email: $email)
  }
`;

export const useAuthAction = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getAuthAction = useCallback(async (email: string) => {
    setIsLoading(true);

    const result = await queryClient.fetchQuery({
      queryKey: createAuthActionQueryKey(email),
      queryFn: async () => {
        const result = await gqlClient.request<
          GetAuthActionQuery,
          GetAuthActionQueryVariables
        >(query, { email });

        return result.getAuthAction;
      },
    });

    setIsLoading(false);

    return result;
  }, []);

  return {
    isLoading,
    getAuthAction,
  };
};
