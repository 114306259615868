import { Link, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export const Cta = () => {
  return (
    <Link
      _hover={{ bgColor: '#f6f1ff' }}
      as={NavLink}
      bgColor="white"
      borderRadius="full"
      pb="1.5"
      pt="2"
      px="3"
      target="_blank"
      textDecor="none"
      to="/magic"
    >
      <Text as="span" fontSize="md" fontWeight="bold" textStyle="magic">
        Try Magic AI
      </Text>
    </Link>
  );
};
