import {
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { colors } from './colors';
import * as components from './components';
import { layerStyles } from './layerStyles';
import { textStyles } from './textStyles';

const fonts = {
  heading: 'BasisGrotesquePro, sans-serif',
  body: 'BasisGrotesquePro, sans-serif',
};

const breakpoints = {
  xs: '450px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1536px',
  '3xl': '1920px',
  'magic-max-width': '1280px',
};

const sizes = {
  10: '42px',
  container: {
    xl: '1352px',
  },
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    sizes,
    fonts,
    colors,
    components: { ...components }, // Convert import collection to plain object
    breakpoints,
    layerStyles,
    textStyles,
    config,
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/semantic-tokens.ts
    semanticTokens: {
      lineHeights: {
        base: 1.4,
      },
      colors: {
        'chakra-body-text': {
          _light: 'customgray.textdark',
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'brand' })
);

export { colors, components, theme, breakpoints };
