import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ScheduleFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { CategoryInput, LabelInput, MemberInput } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import { FamilyFlowFormValues } from '../types';
import {
  SetupScheduleMutation,
  SetupScheduleMutationVariables,
} from './useSetupSchedule.generated';

const query = gql`
  ${ScheduleFragment}
  mutation SetupSchedule(
    $scheduleId: ID!
    $members: [MemberInput!]
    $labels: [LabelInput!]
    $whoLabels: [LabelInput!]
    $categories: [CategoryInput!]
  ) {
    setupSchedule(
      scheduleId: $scheduleId
      members: $members
      whoLabels: $whoLabels
      labels: $labels
      categories: $categories
    ) {
      ...Schedule
    }
  }
`;

export const useSetupSchedule = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending, isSuccess, isError } = useMutation<
    SetupScheduleMutation,
    QueryError,
    SetupScheduleMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        SetupScheduleMutation,
        SetupScheduleMutationVariables
      >(query, variables);
    },
  });

  const setupSchedule = (
    values: FamilyFlowFormValues,
    options?: MutateOptions<
      SetupScheduleMutation,
      QueryError,
      SetupScheduleMutationVariables
    >
  ) => {
    const whoLabels: LabelInput[] = values.who
      .filter((who) => !!who.name.trim())
      .map((who) => ({ text: who.name }));

    const labels = ['Doctor', 'No School', 'Travel', 'Special Event'].map(
      (text) => ({
        text,
      })
    );

    const members: MemberInput[] | undefined = values.who
      .filter((who) => !!who.formattedPhoneNumber.trim() && !!who.name.trim())
      .map((who) => ({
        name: who.name,
        phoneNumber: who.formattedPhoneNumber,
      }));

    const categories: CategoryInput[] = values.who
      .filter((who) => !!who.name.trim() && !!who.category.trim())
      .map((who) => ({
        id: who.category,
        text: `${who.name} activity`,
      }));

    return mutate(
      { scheduleId, members, labels, whoLabels, categories },
      options
    );
  };

  return { setupSchedule, isPending, isSuccess, isError };
};
