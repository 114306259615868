import { useState } from 'react';

// This hook generates a unique ID per job session within the modal
// and is used to force the modal to reset to its initial state,
// skipping animations
export const useResetKey = () => {
  const [key, setKey] = useState(Date.now());

  return {
    resetKey: String(key),
    generateResetKey: () => setKey(Date.now()),
  };
};
