import {
  Box,
  BoxProps,
  Flex,
  HStack,
  IconButton,
  Text,
  useToken,
} from '@chakra-ui/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLightningCharge } from 'react-icons/bs';
import { useOptimisticValue } from '@/hooks/useOptimisticValue';
import { HiddenIcon, IconTooltip } from '@/pages/Schedule/components';
import { DecoratedInstance } from '@/pages/Schedule/types';
import { areInstancesEqual } from '@/pages/Schedule/utils';
import {
  formatInstanceTime,
  getEntryItemStyles,
} from '@/pages/Schedule/utils/entry';

type Props = Omit<BoxProps, 'onClick'> & {
  instance: DecoratedInstance;
  onClick: (instance: DecoratedInstance) => void;
  onToggleHideEntryClick?: (instance: DecoratedInstance) => void;
};

const Component = ({
  instance,
  onClick,
  onToggleHideEntryClick,
  ...props
}: Props) => {
  const { t } = useTranslation(['common', 'schedule']);
  const [customGrayDark] = useToken('colors', ['customgray.dark']);
  const [localInstance, setLocalInstance] = useOptimisticValue(instance);
  const time = formatInstanceTime(instance);

  const styles = useMemo(
    () => getEntryItemStyles(localInstance),
    [localInstance]
  );

  return (
    <Box
      borderRadius="12"
      data-entry-id={instance.id}
      data-testid="entry-item"
      role="button"
      onClick={() => onClick(instance)}
      {...styles.container}
      {...props}
    >
      <Flex align="center" p="4">
        <Box flex="1">
          <Text fontWeight="medium">
            {instance.title || t('common:untitled_entry')}
          </Text>

          {!!time && (
            <Text color="customgray.dark" fontSize="14" mt="0">
              {time}
            </Text>
          )}

          {(instance.feed || onToggleHideEntryClick) && (
            <Flex align="center" mt="3">
              {(instance.feed || onToggleHideEntryClick) && (
                <HStack>
                  {instance.feed && (
                    <Box display="inline-flex">
                      <IconTooltip
                        icon={
                          <BsLightningCharge color={customGrayDark} size="14" />
                        }
                        label={t('schedule:imported_from', {
                          feed: instance.feed.title,
                        })}
                      />
                    </Box>
                  )}
                  {onToggleHideEntryClick && (
                    <Box display="inline-flex">
                      <IconButton
                        colorScheme="dark"
                        icon={<HiddenIcon isHidden={instance.isHidden} />}
                        size="xs"
                        variant="ghost"
                        aria-label={
                          instance.isHidden
                            ? t('common:unhide_entry')
                            : t('common:hide_entry')
                        }
                        onClick={(event) => {
                          event.stopPropagation();
                          setLocalInstance({
                            ...localInstance,
                            isHidden: !localInstance.isHidden,
                          });
                          onToggleHideEntryClick?.(localInstance);
                        }}
                        {...styles.hideIcon}
                      />
                    </Box>
                  )}
                </HStack>
              )}
            </Flex>
          )}
        </Box>

        {instance.emoji && (
          <Box fontSize="32px" lineHeight="1">
            {instance.emoji}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export const MobileEntryItem = memo(Component, (prevProps, nextProps) => {
  return (
    prevProps.onClick === nextProps.onClick &&
    prevProps.onToggleHideEntryClick === nextProps.onToggleHideEntryClick &&
    areInstancesEqual(prevProps.instance, nextProps.instance)
  );
});
