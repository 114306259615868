import { cssVar, ComponentStyleConfig } from '@chakra-ui/react';

const $tooltipArrowBg = cssVar('popper-arrow-bg');

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: 'customgray.dark',
    color: 'customgray.light',
    [$tooltipArrowBg.variable]: 'colors.customgray.dark',
  },
  variants: {
    light: {
      bg: 'white',
      color: 'customgray.mid',
      lineHeight: 1.4,
      padding: 3,
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
      [$tooltipArrowBg.variable]: 'white',
    },
  },
};
