import { Text } from '@chakra-ui/react';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { getEntryCategoryColors } from '@/pages/Schedule/utils';
import { Pill } from './Pill';

type Props = {
  category: DecoratedEntry['category'];
};

export const Category = ({ category }: Props) => {
  const colors = getEntryCategoryColors(category?.color);
  const hasCategory = Boolean(category?.text);

  return (
    <Pill
      bg={colors.backgroundColor}
      borderColor={colors.borderColor}
      {...(!hasCategory && {
        w: '50px',
        h: '25px',
      })}
    >
      {category?.text && (
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {category.text}
        </Text>
      )}
    </Pill>
  );
};
