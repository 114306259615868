import { config } from '@/config';
import { DecoratedProfile } from '@/contexts';
import { wrapWebViewEscape } from '@/lib/webViewUtils';

const GOOGLE_URL = 'https://accounts.google.com/o/oauth2/v2/auth/identifier';
const REDIRECT_PATH = '/integration/google';

const AUTH_SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
];

export const READ_CALENDAR_SCOPES = [
  'https://www.googleapis.com/auth/calendar.readonly',
];

export const WRITE_SCOPES = ['https://www.googleapis.com/auth/calendar.events'];

export const CALENDAR_SCOPES = [...READ_CALENDAR_SCOPES, ...WRITE_SCOPES];

export type Options = {
  /**
   * Hint to Google auth which account to use. Google will only show this
   * account and the user will have to click to see other accounts if they
   * really want to switch
   */
  email?: string;
  isExtension?: boolean;
};

export const getSigninUrl = (
  currentUser: DecoratedProfile | undefined,
  redirectTo = '/',
  state: Record<string, string | number | boolean>,
  { email, isExtension }: Options
): string => {
  const scopes = [...AUTH_SCOPES, ...CALENDAR_SCOPES];

  const payload: Record<string, string> = {
    redirect_uri: `${config.server.url}${REDIRECT_PATH}`,
    response_type: 'code',
    access_type: 'offline',
    client_id: config.googleAuth.clientId,
    scope: scopes.join(' '),
    prompt: 'consent',
    state: JSON.stringify({
      ...state,
      redirectTo: isExtension ? '/magic-auth/success' : redirectTo,
    }),
  };

  if (email) {
    payload.login_hint = email;
  }

  const query = new URLSearchParams(payload);

  const signinUrl = `${GOOGLE_URL}?${query.toString()}`;

  return wrapWebViewEscape(signinUrl);
};
