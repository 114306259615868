import {
  Container,
  Flex,
  FlexProps,
  ContainerProps,
  forwardRef,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';

type Props = FlexProps & {
  leftColumn?: ReactNode;
  leftColumnProps?: FlexProps;
  middleColumn?: ReactNode;
  rightColumn?: ReactNode;
  rightColumnProps?: FlexProps;
  logoContainerProps?: FlexProps;
  profileContainerProps?: FlexProps;
  hideProfileMenu?: boolean;
  containerProps?: ContainerProps;
  logoHref?: {
    to: string;
    external?: boolean;
  };
};

export const Layout = forwardRef(
  (
    {
      leftColumn,
      leftColumnProps,
      middleColumn,
      rightColumn,
      rightColumnProps,
      containerProps,
      ...props
    }: Props,
    ref
  ) => {
    const isLgBreakpoint = useIsBreakpoint('lg');

    return (
      <Flex
        as="header"
        bg="white"
        borderBottomColor="customgray.gray"
        borderBottomWidth={{ base: 0, md: '1px' }}
        direction="column"
        h="64px"
        ref={ref}
        {...props}
      >
        <Container
          display="flex"
          flex="1"
          px="0"
          variant="max"
          {...containerProps}
        >
          {/* Left column */}
          <Flex
            align="center"
            display="flex"
            flexBasis={middleColumn ? '0%' : 'auto'}
            flexGrow="0"
            flexShrink={isLgBreakpoint ? 0 : 1}
            h="100%"
            {...leftColumnProps}
          >
            {leftColumn}
          </Flex>

          {middleColumn && (
            <Flex align="center" overflow="hidden" px={{ base: 0, md: 2 }}>
              {middleColumn}
            </Flex>
          )}

          {rightColumn && (
            <Flex
              align="center"
              flex="1"
              gap={isLgBreakpoint ? 3 : 0}
              h="100%"
              justify="flex-end"
              {...rightColumnProps}
            >
              {rightColumn}
            </Flex>
          )}
        </Container>
      </Flex>
    );
  }
);
