import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import {
  CopyScheduleMutation,
  CopyScheduleMutationVariables,
} from './useCopySchedule.generated';

const query = gql`
  mutation CopySchedule($scheduleId: ID!) {
    copySchedule(scheduleId: $scheduleId)
  }
`;

export const useCopySchedule = () => {
  const { mutate, isPending } = useMutation<
    CopyScheduleMutation,
    QueryError,
    CopyScheduleMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        CopyScheduleMutation,
        CopyScheduleMutationVariables
      >(query, variables);
    },
  });

  const copySchedule = (
    scheduleId: string,
    options?: MutateOptions<
      CopyScheduleMutation,
      QueryError,
      CopyScheduleMutationVariables
    >
  ) => {
    const variables = { scheduleId };
    return mutate(variables, options);
  };

  return { copySchedule, isPending };
};
