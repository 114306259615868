import { defineStyleConfig } from '@chakra-ui/react';

export const Link = defineStyleConfig({
  variants: {
    // https://github.com/chakra-ui/chakra-ui/issues/2870#issuecomment-758652112
    primary: ({ colorScheme = 'brand' }) => ({
      color: `${colorScheme}.500`,
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
});
