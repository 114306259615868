import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient, Sentry } from '@/lib';
import { Visibility } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import { useSnippet } from '../../../hooks/useSnippet';
import {
  UpdateSnippetVisibilityMutation,
  UpdateSnippetVisibilityMutationVariables,
} from './useUpdateSnippetVisibility.generated';

const query = gql`
  mutation UpdateSnippetVisibility(
    $scheduleId: ID!
    $snippetId: ID!
    $visibility: Visibility!
  ) {
    updateSnippetVisibility(
      scheduleId: $scheduleId
      snippetId: $snippetId
      visibility: $visibility
    )
  }
`;

export const useUpdateSnippetVisiblity = () => {
  const { data: snippet } = useSnippet();

  const { mutate } = useMutation<
    UpdateSnippetVisibilityMutation,
    QueryError,
    UpdateSnippetVisibilityMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateSnippetVisibilityMutation,
        UpdateSnippetVisibilityMutationVariables
      >(query, variables),
    onMutate: (variables) => {
      return {
        optimisticData: {
          visibility: variables.visibility,
        },
        rollbackData: {
          visibility: snippet?.visibility,
        },
      };
    },
  });

  const updateVisibility = (visibility: Visibility) => {
    if (!snippet?.scheduleId) {
      const err = new Error('Snippet scheduleId is not defined');
      console.error(err);
      Sentry.captureException(err, { extra: { snippet } });
      return;
    }
    const variables: UpdateSnippetVisibilityMutationVariables = {
      snippetId: snippet.id,
      scheduleId: snippet.scheduleId,
      visibility,
    };
    return mutate(variables);
  };

  return { updateVisibility };
};
