import { Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiPencil } from 'react-icons/hi2';
import { HeaderButton, HeaderButtonProps } from './HeaderButton';

export const EditButton = (props: HeaderButtonProps) => {
  const { t } = useTranslation('batchCreate');

  return (
    <HeaderButton
      aria-label={t('aria_edit')}
      icon={<Icon as={HiPencil} />}
      {...props}
    />
  );
};
