import { Flex, FlexProps } from '@chakra-ui/react';
import Autolinker from 'autolinker';
import { RichTextEditor } from '@/pages/Schedule/components';
import { useItemCardContext } from '../context/useItemCardContext';

export const Description = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();
  const description = instance?.description || entry.description;

  if (!description) {
    return null;
  }

  return (
    <Flex {...props}>
      <RichTextEditor
        key={description}
        lineClamp={15}
        readOnly
        value={Autolinker.link(description)}
      />
    </Flex>
  );
};
