import { ChakraProps } from '@chakra-ui/react';

export const layerStyles: Record<string, ChakraProps> = {
  magicBackground: {
    bgImage: 'linear-gradient(#dcd3ef, rgba(245, 225, 239, 0.5))',
    bgAttachment: 'fixed',
  },
  magicBackgroundSecondary: {
    bgImage: 'linear-gradient(180deg, #D1D6F4 0%, #F9EAF3 100%)',
    bgAttachment: 'fixed',
  },
};
