import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  Input,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { ChangeEventHandler, DragEventHandler, useId, useState } from 'react';
import { FaFileUpload } from 'react-icons/fa';
import { useToast } from '@/hooks/useToast';
import { EntriesJobFile, QuickEntriesJobFile } from '@/types/gql.generated';
import { UploadPreview } from './UploadPreview';

const allowedMimeTypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'application/pdf',
];

type Props = FlexProps & {
  file?: File | QuickEntriesJobFile | EntriesJobFile | null;
  instructions: string;
  isDisabled?: boolean;
  dragOverProps?: FlexProps;
  onSelectFile: (file: File | null) => void;
  onInstructionsChange: (instructions: string) => void;
  onDropText: (text: string) => void;
};

export const UploadTarget = forwardRef(
  (
    {
      file,
      instructions,
      isDisabled,
      dragOverProps,
      onSelectFile,
      onInstructionsChange,
      onDropText,
      ...props
    }: Props,
    ref
  ) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const toast = useToast();
    const inputId = useId();

    const handleDragOver: DragEventHandler = (event) => {
      event.preventDefault();

      if (!isDisabled) {
        setIsDragOver(true);
      }
    };

    const handleDrop: DragEventHandler = (event) => {
      event.preventDefault();

      setIsDragOver(false);

      if (isDisabled) {
        return;
      }

      const item = event.dataTransfer.items[0];

      // user most likely dragged a png from another browser tab
      if (item.kind === 'string' && item.type === 'text/uri-list') {
        toast.info({
          title: "We don't support dragging files this way :(",
          description:
            'Please download the file to your computer first and then upload it here.',
        });
      } else if (item.kind === 'string' && item.type === 'text/plain') {
        item.getAsString(onDropText);
      } else if (item.kind === 'file') {
        const file = item.getAsFile();

        if (!file) {
          return;
        } else if (!allowedMimeTypes.includes(file.type)) {
          toast.info('Image or PDF files only please!');
        } else {
          onSelectFile(file);
        }
      }
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const file = event.target.files?.[0];
      if (file) {
        onSelectFile(file);
      }
    };

    const filename = file instanceof File ? file.name : file?.filename;

    return (
      <Flex
        align="center"
        bg="gray.100"
        borderColor="gray.300"
        borderRadius="md"
        borderStyle="dashed"
        borderWidth="3px"
        flex="1"
        justify="center"
        minH={!file || isDragOver ? '160px' : 'auto'}
        ref={ref}
        transition="all 0.1s ease-in-out"
        w="100%"
        onDragLeave={() => setIsDragOver(false)}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        {...props}
        {...(isDragOver && {
          bg: 'gray.200',
          borderColor: 'gray.400',
          ...dragOverProps,
        })}
      >
        <Input
          accept={allowedMimeTypes.join(',')}
          display="none"
          id={inputId}
          key={filename} // ensures the same file can be selected again after remove it
          type="file"
          onChange={handleInputChange}
        />

        {file && !isDragOver ? (
          <UploadPreview
            file={file}
            inputId={inputId}
            instructions={instructions}
            isDisabled={isDisabled}
            onInstructionsChange={onInstructionsChange}
          />
        ) : (
          <>
            {isDragOver ? (
              <Text fontWeight="medium">Drop file to upload</Text>
            ) : (
              <Box textAlign="center">
                <Icon as={FaFileUpload} boxSize="64px" color="gray.400" />
                <Text mt="4">
                  <Button
                    as="label"
                    colorScheme="dark"
                    cursor="pointer"
                    fontSize="md"
                    htmlFor={isDisabled ? undefined : inputId}
                    isDisabled={isDisabled}
                    textDecor="underline"
                    variant="link"
                  >
                    Choose a file
                  </Button>{' '}
                  or drag one here
                </Text>
              </Box>
            )}
          </>
        )}
      </Flex>
    );
  }
);
