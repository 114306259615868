import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import {
  useScheduleContext,
  useEntriesCache,
  useUnsafeScheduleFilters,
} from '@/pages/Schedule/contexts';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import { LabelType } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  DeleteLabelMutation,
  DeleteLabelMutationVariables,
} from './useDeleteLabel.generated';

const query = gql`
  mutation DeleteLabel($scheduleId: ID!, $labelId: ID!) {
    deleteLabel(scheduleId: $scheduleId, labelId: $labelId)
  }
`;

export const useDeleteLabel = (labelType: LabelType) => {
  const { scheduleId } = useScheduleContext();
  const scheduleFilters = useUnsafeScheduleFilters();
  const scheduleCache = useScheduleCache();
  const entriesCache = useEntriesCache();

  const { mutate } = useMutation<
    DeleteLabelMutation,
    QueryError,
    DeleteLabelMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<DeleteLabelMutation, DeleteLabelMutationVariables>(
        query,
        variables
      ),
    onSuccess: (_, { labelId }) => {
      scheduleCache.deleteLabel(labelType, labelId);
      entriesCache.deleteLabel(labelType, labelId);

      // Remove label from active filters if we're on a page
      // that makes filters available
      if (labelType === LabelType.Default) {
        scheduleFilters?.removeLabel(labelId);
      } else {
        scheduleFilters?.removeWhoLabel(labelId);
      }
    },
  });

  const deleteLabel = useCallback(
    (
      labelId: string,
      options?: MutateOptions<
        DeleteLabelMutation,
        QueryError,
        DeleteLabelMutationVariables
      >
    ) => {
      return mutate({ scheduleId, labelId }, options);
    },
    [scheduleId, mutate]
  );

  return {
    deleteLabel,
  };
};
