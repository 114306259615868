import { useState, useEffect } from 'react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';

export const useMouseWithinPixelsFromTop = (pixels: number) => {
  const [activated, setActivated] = useState(false);
  const isMobileBreakpoint = useIsMobileBreakpoint();

  useEffect(() => {
    if (isMobileBreakpoint) {
      return;
    }

    function listener(event: MouseEvent) {
      setActivated(event.clientY <= pixels);
    }

    document.documentElement.addEventListener('mousemove', listener);

    return () => {
      document.documentElement.removeEventListener('mousemove', listener);
    };
  }, [pixels, isMobileBreakpoint]);

  return activated;
};
