import { Flex, FlexProps } from '@chakra-ui/react';

export const PanelBody = (props: FlexProps) => {
  return (
    <Flex
      direction="column"
      flex="1"
      overflowY="auto"
      px="8"
      py="6"
      {...props}
    />
  );
};
