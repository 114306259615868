import { memo } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import {
  ItemCard,
  ItemCardContent,
  ItemCardHeader,
  ItemCardMetadata,
} from '@/pages/Schedule/components/ItemCard';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { DeleteButton } from './components/DeleteButton';
import { EditButton } from './components/EditButton';
import { MessagesButton } from './components/MessagesButton';

type Props = {
  entry: DecoratedEntry;
  onEditClick: (entry: DecoratedEntry) => void;
  onMessagesClick: (entry: DecoratedEntry) => void;
  onDeleteClick: (entry: DecoratedEntry) => void;
};

export const EntryCard = memo(function MemoizedEntryCard({
  entry,
  onEditClick,
  onMessagesClick,
  onDeleteClick,
}: Props) {
  const { trackEvent } = useAnalytics();

  const handleEditClick = () => {
    onEditClick(entry);
    trackEvent('ai-add:click entry edit');
  };

  const handleMessagesClick = () => {
    onMessagesClick(entry);
    trackEvent('ai-add:click entry messages');
  };

  const handleDeleteClick = () => {
    onDeleteClick(entry);
    trackEvent('ai-add:click entry delete');
  };

  return (
    <ItemCard entry={entry} flexGrow="0">
      <ItemCardContent>
        <ItemCardHeader
          buttons={[
            <EditButton key="edit" onClick={handleEditClick} />,
            <MessagesButton key="messages" onClick={handleMessagesClick} />,
            <DeleteButton key="delete" onClick={handleDeleteClick} />,
          ]}
        />
        <ItemCardMetadata />
      </ItemCardContent>
    </ItemCard>
  );
});
