import { Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from 'react-icons/io5';
import { HeaderButton, HeaderButtonProps } from './HeaderButton';

export const DeleteButton = (props: HeaderButtonProps) => {
  const { t } = useTranslation('batchCreate');

  return (
    <HeaderButton
      aria-label={t('aria_delete')}
      icon={<Icon as={IoTrashOutline} />}
      {...props}
    />
  );
};
