import { Container, Flex, FlexProps, forwardRef } from '@chakra-ui/react';

export const ScheduleContent = forwardRef(
  ({ children, ...props }: FlexProps, ref) => {
    return (
      <Container
        display="flex"
        flex="1"
        flexDir="column"
        pb={{ base: 0, md: 6 }}
        pt={{ base: 0, xl: 6 }}
        px={{ base: 0, xl: '20px' }}
        variant="max"
      >
        <Flex
          bg="white"
          borderRadius={{ base: 0, sm: 'lg' }}
          direction="column"
          flex="1"
          p={{ base: 4, sm: 8 }}
          pt={{ base: 2, md: 8 }}
          ref={ref}
          {...props}
        >
          {children}
        </Flex>
      </Container>
    );
  }
);
