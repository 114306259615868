import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex } from '@chakra-ui/react';
import { offset, useFloating } from '@floating-ui/react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  scheduleId: string;
};

export const SuccessState = ({ scheduleId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('schedule', { keyPrefix: 'follow_schedule' });
  const { floatingStyles, refs } = useFloating({
    placement: 'bottom',
    middleware: [offset(10)],
  });

  const handleShowMeClick = () => {
    navigate('/', {
      state: {
        highlightScheduleId: scheduleId,
      },
    });
  };

  return (
    <>
      <Flex align="center" color="green" gap="2" ref={refs.setReference}>
        <CheckIcon /> {t('follow_success')}
      </Flex>
      <Box
        bg="white"
        borderColor="gray.200"
        borderRadius="lg"
        borderWidth="1px"
        px="3"
        py="2"
        ref={refs.setFloating}
        shadow="lg"
        style={floatingStyles}
        w="300px"
      >
        <Trans i18nKey="follow_success_popover" t={t}>
          This schedule will now appear in your dashboard of schedules.{' '}
          <Button variant="link" onClick={handleShowMeClick}>
            Show me →
          </Button>
        </Trans>
      </Box>
    </>
  );
};
