import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, forwardRef, useColorMode } from '@chakra-ui/react';
import { getEntryCategoryColors } from '@/pages/Schedule/utils/entry';
import type { Category } from '@/pages/Schedule/components/Category';

type Props = {
  category: Category;
  isSelected: boolean;
  onClick: (category: Category) => void;
};

export const CategoryButton = forwardRef(
  ({ category, isSelected, onClick }: Props, ref) => {
    const { colorMode } = useColorMode();
    const colors = getEntryCategoryColors(category.color, colorMode);

    return (
      <Flex bg="white" borderRadius="3" w="100%">
        <Button
          alignItems="center"
          bg={colors.backgroundColor}
          borderRadius="3"
          color="customgray.dark"
          display="flex"
          flex="1"
          fontSize="sm"
          fontWeight="medium"
          h="auto"
          key={category.id}
          lineHeight="normal"
          pl="3"
          pr="3"
          py="2.5"
          ref={ref}
          variant="unstyled"
          _hover={{
            bg: colors.hoverBackgroundColor,
          }}
          onClick={() => onClick(category)}
        >
          <Box flex="1" textAlign="left">
            {category.text || <>&nbsp;</>}
          </Box>
          {isSelected && <CheckIcon />}
        </Button>
      </Flex>
    );
  }
);
