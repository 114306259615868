import { Box, Button, Center, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';
import happyCalendar from '@/pages/Schedule/assets/happy-calendar-blue.png';

type Props = {
  children: ReactNode;
  cta?: {
    text: string;
    onClick: () => void;
  };
};

export const EmptyState = ({ children, cta }: Props) => {
  return (
    <Center flexDir="column" m="auto" maxW="65%" mt="14">
      <Image src={happyCalendar} w="60%" />
      <Box
        color="customgray.mid"
        fontSize="lg"
        mb={cta ? 8 : 0}
        textAlign="center"
      >
        {children}
      </Box>
      {cta && (
        <Button variant="secondary" onClick={cta.onClick}>
          {cta.text}
        </Button>
      )}
    </Center>
  );
};
