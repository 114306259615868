import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib/gql-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import { DecoratedEntry } from '../../types';
import {
  UnfollowEntryMutation,
  UnfollowEntryMutationVariables,
} from './useUnfollowEntry.generated';

const query = gql`
  mutation UnfollowEntry($scheduleId: ID!, $entryId: ID!) {
    unfollowEntry(scheduleId: $scheduleId, entryId: $entryId)
  }
`;

export const useUnfollowEntry = (entry: DecoratedEntry) => {
  const { scheduleId } = useScheduleContext();
  const { id: entryId, contextId } = entry;

  const { mutate, isPending } = useMutation<
    UnfollowEntryMutation,
    QueryError,
    UnfollowEntryMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<UnfollowEntryMutation, UnfollowEntryMutationVariables>(
        query,
        variables
      ),
    onMutate: () => {
      return {
        optimisticData: {
          contextId,
          isFollowing: false,
        },
        rollbackData: {
          contextId,
          isFollowing: true,
        },
      };
    },
  });

  const unfollowEntry = useCallback(
    (
      options?: MutateOptions<
        UnfollowEntryMutation,
        QueryError,
        UnfollowEntryMutationVariables
      >
    ) => mutate({ scheduleId, entryId }, options),
    [mutate, scheduleId, entryId]
  );

  return {
    unfollowEntry,
    isPending,
  };
};
