import { Box, forwardRef, Tab as ChakraTab, TabProps } from '@chakra-ui/react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';

type Props = TabProps & {
  icon: JSX.Element;
};

export const Tab = forwardRef(({ icon, children, ...props }: Props, ref) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  return (
    <ChakraTab
      {...props}
      _first={{ borderTopLeftRadius: '2xl' }}
      _hover={{ bg: 'dark.500' }}
      _last={{ borderTopRightRadius: '2xl' }}
      _selected={{ bg: 'dark.500' }}
      alignItems="center"
      borderBottom="none"
      display="flex"
      fontSize="sm"
      fontWeight="medium"
      gap="2"
      lineHeight="normal"
      py="4"
      ref={ref}
    >
      {!isMobileBreakpoint && (
        <Box h="18px" w="18px">
          {icon}
        </Box>
      )}
      {children}
    </ChakraTab>
  );
});
