import { forwardRef } from '@chakra-ui/react';
import { Variants } from 'framer-motion';
import { MotionFlex, MotionFlexProps } from '@/components/MotionFlex';

const variants: Variants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

export const FadeInOut = forwardRef((props: MotionFlexProps, ref) => {
  return (
    <MotionFlex
      animate="show"
      exit="hide"
      initial="hide"
      ref={ref}
      transition={{ duration: 0.2 }}
      variants={variants}
      {...props}
    />
  );
});
