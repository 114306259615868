import { chakra, ChakraComponent } from '@chakra-ui/react';
import { ComponentProps, KeyboardEventHandler, MouseEventHandler } from 'react';

type Props = ComponentProps<ChakraComponent<'div', object>>;
type Handler<E> = KeyboardEventHandler<E> & MouseEventHandler<E>;

const captureEvent: Handler<HTMLDivElement> = (event) => {
  event.stopPropagation();
};

export const EventCapture = (props: Props): JSX.Element => {
  return (
    <chakra.div
      {...props}
      onClick={captureEvent}
      onKeyDown={captureEvent}
      onKeyUp={captureEvent}
    />
  );
};
