import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLink } from 'react-icons/hi';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useToast } from '@/hooks/useToast';

export const CopyLinkButton = (props: ButtonProps) => {
  const { t } = useTranslation('schedule', {
    keyPrefix: 'copy_link',
  });
  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const handleCopyClick = () => {
    trackEvent('schedule:click copy-share-link');
    navigator.clipboard.writeText(window.location.toString());
    toast.notify(t('copy_success'));
  };

  return (
    <Button
      colorScheme="dark"
      leftIcon={<HiOutlineLink />}
      variant="ghost"
      onClick={handleCopyClick}
      {...props}
    >
      {props.children || t('copy_button')}
    </Button>
  );
};
