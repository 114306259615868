import { Icon, IconProps } from '@chakra-ui/react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = IconProps & {
  isHidden: boolean;
};

export const HiddenIcon = ({
  color = 'customgray.dark',
  boxSize = '18px',
  isHidden,
  ...props
}: Props) => {
  return isHidden ? (
    <Icon as={AiOutlineEye} boxSize={boxSize} color={color} {...props} />
  ) : (
    <Icon
      as={AiOutlineEyeInvisible}
      boxSize={boxSize}
      color={color}
      {...props}
    />
  );
};
