import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { definitions as labelDefinitions } from '@/pages/Schedule/utils/labelDefinitions';
import { LabelType, Label } from '@/types/gql.generated';
import { createScheduleQueryKey } from '@/utils/queryKeys';
import {
  DecoratedSchedule,
  GetScheduleQuery,
  useScheduleContext,
} from '../contexts';

export type ScheduleCategory = DecoratedSchedule['categories'][number];
type CategoryMetadata = Pick<ScheduleCategory, 'color' | 'text'>;

export const useScheduleCache = () => {
  const queryClient = useQueryClient();
  const { scheduleId } = useScheduleContext();
  const queryKey = createScheduleQueryKey(scheduleId);

  const getSchedule = useCallback(() => {
    return queryClient.getQueryData<GetScheduleQuery>(queryKey);
  }, [queryClient, queryKey]);

  const updateSchedule = useCallback(
    (replacement: Partial<DecoratedSchedule>) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData || !replacement) {
            return oldData;
          }
          return {
            ...oldData,
            getSchedule: {
              ...oldData.getSchedule,
              ...replacement,
            },
          };
        }
      );
    },
    [queryClient, queryKey]
  );

  const addActiveUserToSchedule = useCallback(
    (userId: string) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            getSchedule: {
              ...oldData.getSchedule,
              activeUsers: Array.from(
                new Set([...oldData.getSchedule.activeUsers, userId])
              ),
            },
          };
        }
      );
    },
    [queryClient, queryKey]
  );

  const getCategory = useCallback(
    (categoryId: string) => {
      const data = queryClient.getQueryData<GetScheduleQuery | undefined>(
        queryKey
      );
      if (!data) {
        return data;
      }
      return data.getSchedule.categories.find(
        (category) => category.id === categoryId
      );
    },
    [queryClient, queryKey]
  );

  const addCategory = useCallback(
    (categoryId: string, metadata: CategoryMetadata) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            getSchedule: {
              ...oldData.getSchedule,
              categories: [
                { id: categoryId, ...metadata },
                ...oldData.getSchedule.categories,
              ],
            },
          };
        }
      );
    },
    [queryClient, queryKey]
  );

  const removeCategory = useCallback(
    (categoryId: string) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            getSchedule: {
              ...oldData.getSchedule,
              categories: oldData.getSchedule.categories.filter(
                (category) => category.id !== categoryId
              ),
            },
          };
        }
      );
    },
    [queryClient, queryKey]
  );

  const getLabelsFromSchedule = (
    labelType: LabelType,
    scheduleQuery: GetScheduleQuery | undefined
  ): Label[] => {
    if (!scheduleQuery) {
      return [];
    }
    const labelDefinition = labelDefinitions[labelType];
    return scheduleQuery?.getSchedule[labelDefinition.scheduleKey] || [];
  };

  const setLabels = useCallback(
    (labelType: LabelType, labels: Label[]) => {
      const labelDefinition = labelDefinitions[labelType];
      return queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (data) => {
          if (!data) {
            return data;
          }

          return {
            ...data,
            getSchedule: {
              ...data.getSchedule,
              [labelDefinition.scheduleKey]: labels,
            },
          };
        }
      );
    },
    [queryClient, queryKey]
  );

  const addLabel = useCallback(
    (labelType: LabelType, label: Label) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          const labels = getLabelsFromSchedule(labelType, oldData);
          return setLabels(labelType, [...labels, label]);
        }
      );
    },
    [queryClient, queryKey, setLabels]
  );

  const updateLabel = useCallback(
    (labelType: LabelType, label: Label) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          const labels = getLabelsFromSchedule(labelType, oldData);
          const updatedLabels = labels.map((item) => {
            if (item.id === label.id || item.text === label.text) {
              return label;
            }
            return item;
          });

          return setLabels(labelType, updatedLabels);
        }
      );
    },
    [queryClient, queryKey, setLabels]
  );

  const deleteLabel = useCallback(
    (labelType: LabelType, labelId: string) => {
      queryClient.setQueryData<GetScheduleQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          const labels = getLabelsFromSchedule(labelType, oldData);
          const updatedLabels = labels.filter((label) => label.id !== labelId);

          return setLabels(labelType, updatedLabels);
        }
      );
    },
    [queryClient, queryKey, setLabels]
  );

  return {
    getSchedule,
    updateSchedule,
    getCategory,
    addCategory,
    removeCategory,
    addActiveUserToSchedule,
    setLabels,
    addLabel,
    updateLabel,
    deleteLabel,
  };
};
