import { SetStateAction, useCallback, useState } from 'react';
import {
  INITIAL_FILE_PROMPT,
  INITIAL_PROMPT,
  INITIAL_TEXT_PROMPT,
} from '../../constants';
import { Prompt } from '../../types';
import { cleanPrompt } from '../../utils/prompt';

export const usePrompt = () => {
  const [prompt, _setPrompt] = useState<Prompt>(INITIAL_PROMPT);

  const setPrompt = (value: SetStateAction<Partial<Prompt>>) => {
    _setPrompt((prevPrompt) =>
      cleanPrompt({
        ...prevPrompt,
        ...value,
      })
    );
  };

  const resetPrompt = useCallback(() => {
    setPrompt(INITIAL_PROMPT);
  }, []);

  const clearTextPrompt = () => {
    setPrompt(INITIAL_TEXT_PROMPT);
  };

  const clearFilePrompt = () => {
    setPrompt(INITIAL_FILE_PROMPT);
  };

  return {
    prompt,
    setPrompt,
    resetPrompt,
    clearTextPrompt,
    clearFilePrompt,
  };
};
