import { DateTime } from 'luxon';
import {
  EndDateAdjuster,
  StartDateAdjuster,
  TodayEndDateAdjuster,
  TodayStartDateAdjuster,
} from '../types';

export const adjustStartDate: StartDateAdjuster = (requestedStartDate) =>
  requestedStartDate.startOf('day').minus({ months: 6 });

export const adjustEndDate: EndDateAdjuster = (startDate) => {
  return startDate.startOf('day').plus({ months: 18 });
};

export const adjustTodayStartDate: TodayStartDateAdjuster = (
  scheduleTimeZone
) => {
  return adjustStartDate(DateTime.local({ zone: scheduleTimeZone }));
};

export const adjustTodayEndDate: TodayEndDateAdjuster = (scheduleTimeZone) => {
  return adjustEndDate(DateTime.local({ zone: scheduleTimeZone }));
};
