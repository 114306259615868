import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { ProfileFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import { LoginMutation, LoginMutationVariables } from './useLogin.generated';

const query = gql`
  ${ProfileFragment}
  mutation Login($email: LowercaseString!, $password: String!) {
    login(email: $email, password: $password) {
      ...Profile
    }
  }
`;

export const useLogin = () => {
  const { setUser } = useCurrentUserContext();

  const { mutate, ...result } = useMutation<
    LoginMutation,
    QueryError,
    LoginMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<LoginMutation, LoginMutationVariables>(
        query,
        variables
      ),
  });

  const login = (
    email: string,
    password: string,
    options?: MutateOptions<LoginMutation, QueryError, LoginMutationVariables>
  ) => {
    mutate(
      { email, password },
      {
        ...options,
        onSuccess: (data, ...args) => {
          setUser(data.login);
          options?.onSuccess?.(data, ...args);
        },
      }
    );
  };

  return {
    ...result,
    login,
  };
};
