import { Box, Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { cloneElement, ComponentProps, useMemo } from 'react';
import { Avatar } from '@/components/Avatar';

type User = {
  id: string;
  name?: string | null;
  email?: string | null;
  avatar?: string | null;
  isDimmed?: boolean;
};

type AvatarGroupProps = Omit<FlexProps, 'children'> & {
  children: JSX.Element[];
  max?: number;
};

type AvatarStackProps = Omit<AvatarGroupProps, 'children'> & {
  users: User[];
  max?: number;
};

const TooltipAvatar = (props: ComponentProps<typeof Avatar>) => (
  <Tooltip
    bg="customgray.dark"
    borderRadius={3}
    label={props.name}
    lineHeight="normal"
    py="1"
  >
    <Avatar {...props} />
  </Tooltip>
);

const AvatarGroup = ({ children, max = 5, ...props }: AvatarGroupProps) => {
  const avatars = useMemo(() => {
    const childrenWithinMax = max ? children.slice(0, max) : children;
    const reversedChildren = childrenWithinMax.reverse();

    return reversedChildren.map((child, index) => {
      const isFirstAvatar = index === 0;

      return cloneElement(child, {
        marginEnd: isFirstAvatar ? 0 : '-0.75rem',
        size: 'sm',
        showBorder: true,
      });
    });
  }, [children, max]);

  const excess = children.slice(max);
  const excessLabel = excess.map(({ props }) => props.name).join(', ');

  return (
    <Flex align="center" direction="row-reverse" justify="flex-end" {...props}>
      {excess.length > 0 && (
        <Tooltip bg="customgray.dark" label={excessLabel}>
          <Box
            cursor="default"
            fontWeight="500"
            ml="2"
          >{`+${excess.length}`}</Box>
        </Tooltip>
      )}
      {avatars}
    </Flex>
  );
};

export const AvatarStack = ({ users, ...props }: AvatarStackProps) => {
  return (
    <AvatarGroup {...props}>
      {users.map(({ id, name, email, avatar, isDimmed }) => (
        <TooltipAvatar
          aria-label={name || email || ''}
          bg="brand.500"
          cursor="default"
          key={id}
          name={name || email || ''}
          showBorder
          src={avatar ?? undefined}
          transition="background 0.2s ease"
          _after={
            isDimmed
              ? {
                  content: '""',
                  background: 'white',
                  position: 'absolute',
                  borderRadius: '50%',
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }
              : undefined
          }
        />
      ))}
    </AvatarGroup>
  );
};
