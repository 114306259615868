import { forwardRef, Input, InputProps, useColorMode } from '@chakra-ui/react';

export const TimeInput = forwardRef((props: InputProps, ref) => {
  const { colorMode } = useColorMode();

  return (
    <Input
      pattern="[0-9]{2}:[0-9]{2}" // fallback for older browsers
      ref={ref}
      type="time"
      sx={
        colorMode === 'dark'
          ? {
              '::-webkit-calendar-picker-indicator': {
                filter: 'invert(1) opacity(0.5)',
              },
            }
          : undefined
      }
      {...props}
    />
  );
});
