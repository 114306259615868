import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { ProfileFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './useResetPassword.generated';

const query = gql`
  ${ProfileFragment}
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      ...Profile
    }
  }
`;

export const useResetPassword = () => {
  const { setUser } = useCurrentUserContext();

  const { mutate, ...result } = useMutation<
    ResetPasswordMutation,
    QueryError,
    ResetPasswordMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<ResetPasswordMutation, ResetPasswordMutationVariables>(
        query,
        variables
      ),
  });

  const resetPassword = async (token: string, password: string) => {
    mutate(
      { token, password },
      {
        onSuccess: (result) => setUser(result.resetPassword),
      }
    );
  };

  return {
    ...result,
    resetPassword,
  };
};
