import { CloseButton, Flex, FlexProps } from '@chakra-ui/react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useSettingsModal } from '../store';
import { Navigation } from './Navigation';

export const PanelHeader = ({ children, ...props }: FlexProps) => {
  const close = useSettingsModal((state) => state.close);
  const isMobileBreakpoint = useIsMobileBreakpoint();

  return (
    <Flex
      align="center"
      borderBottomColor="gray.200"
      borderBottomWidth="1px"
      fontSize="lg"
      fontWeight="medium"
      justify="space-between"
      lineHeight="normal"
      pl="8"
      pr="4"
      py="5"
      {...props}
    >
      <Flex align="center" gap="3">
        {isMobileBreakpoint && <Navigation />}
        {children}
      </Flex>
      <CloseButton onClick={close} />
    </Flex>
  );
};
