import { MutationOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import { QueryError } from '@/utils/errors';
import {
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
} from './useCreateCategory.generated';
import type { CompleteCategory } from '../types';

const query = gql`
  mutation CreateCategory(
    $scheduleId: ID!
    $categoryId: ID!
    $text: String!
    $color: String!
  ) {
    createCategory(
      scheduleId: $scheduleId
      categoryId: $categoryId
      text: $text
      color: $color
    ) {
      id
      text
      color
      sortOrder
    }
  }
`;

export const useCreateCategory = () => {
  const scheduleCache = useScheduleCache();
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    CreateCategoryMutation,
    QueryError,
    CreateCategoryMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateCategoryMutation,
        CreateCategoryMutationVariables
      >(query, variables),
    onMutate: (variables) => {
      scheduleCache.addCategory(variables.categoryId, {
        color: variables.color,
        text: variables.text,
      });
    },
    onError: (_, variables) => {
      scheduleCache.removeCategory(variables.categoryId);
    },
  });

  const createCategory = useCallback(
    (
      category: CompleteCategory,
      options?: MutationOptions<
        CreateCategoryMutation,
        QueryError,
        CreateCategoryMutationVariables
      >
    ) => {
      return mutate(
        {
          scheduleId,
          categoryId: category.id,
          color: category.color,
          text: category.text || '',
        },
        options
      );
    },
    [mutate, scheduleId]
  );

  return { createCategory, isPending };
};
