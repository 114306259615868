import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import { AttendeeStatus } from '@/types/gql.generated';
import { createEntryInvitesQueryKey } from '@/utils/queryKeys';
import {
  GetEntryInvitesQuery,
  GetEntryInvitesQueryVariables,
} from './useEntryInvites.generated';

export type Invite = GetEntryInvitesQuery['getEntry']['invites'][number];
type GroupedInvites = Record<AttendeeStatus, Invite[]>;

const query = gql`
  query GetEntryInvites($scheduleId: ID!, $entryId: ID!) {
    getEntry(scheduleId: $scheduleId, entryId: $entryId) {
      invites {
        id
        email
        status
        notes
        memberSubmission {
          label
          value
          type
        }
      }
    }
  }
`;

export const useEntryInvites = (entryId: string) => {
  const { scheduleId } = useScheduleContext();
  const draftMode = useDraftEntryStore((state) => state.draftMode);
  const enabled = draftMode === 'edit';

  const selector = (data: GetEntryInvitesQuery): GroupedInvites => {
    return data.getEntry.invites.reduce<GroupedInvites>(
      (acc, invite) => {
        acc[invite.status].push(invite);
        return acc;
      },
      {
        [AttendeeStatus.Accepted]: [],
        [AttendeeStatus.Declined]: [],
      }
    );
  };

  const { data, isLoading } = useQuery({
    queryKey: createEntryInvitesQueryKey(scheduleId, entryId),
    queryFn: () =>
      gqlClient.request<GetEntryInvitesQuery, GetEntryInvitesQueryVariables>(
        query,
        {
          scheduleId,
          entryId,
        }
      ),
    select: selector,
    enabled,
  });

  return {
    invites: data ?? [],
    isLoading,
  };
};
