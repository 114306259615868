import { forwardRef } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { MotionBox } from '@/components/MotionBox';

export const SlideContainer = forwardRef<{ children: ReactNode }, 'div'>(
  ({ children }, ref) => {
    return (
      <MotionBox animate={{ opacity: 1 }} initial={{ opacity: 0 }} ref={ref}>
        {children}
      </MotionBox>
    );
  }
);
