import { useMemo } from 'react';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ScheduleView, ScheduleViews } from '@/pages/Schedule/types';
import { DecoratedViewModule, ViewFeatures } from '@/pages/Schedule/views';
import { VIEW_TO_MODULE } from '@/pages/Schedule/views/views';

const useViewFeatures = (): ViewFeatures => {
  const { value: magicResultsInPlatformEnabled } = useFeatureFlag(
    'magicResultsInPlatformEnabled'
  );

  return useMemo(
    () => ({
      agenda: true,
      month: true,
      week: true,
      conflict: true,
      summary: magicResultsInPlatformEnabled,
    }),
    [magicResultsInPlatformEnabled]
  );
};

export const useViewModules = () => {
  const features = useViewFeatures();

  const viewModules = useMemo<DecoratedViewModule[]>(
    () =>
      ScheduleViews.map((view: ScheduleView) => {
        const viewModule = VIEW_TO_MODULE[view];

        return {
          ...viewModule,
          isEnabled: features[view],
        };
      }),
    [features]
  );

  return {
    viewModules,
  };
};
