import { ChakraProps, ColorMode } from '@chakra-ui/react';
import orderBy from 'lodash.orderby';
import { DateTime } from 'luxon';
import { i18n } from '@/i18n';
import { Sentry } from '@/lib';
import { Category } from '@/types/gql.generated';
import { setAlpha } from '@/utils/colors';
import { isAllDay } from '@/utils/dates';
import { DEFAULT_ENTRY_COLOR } from '../constants/entry';
import { DraftComposer, DraftMode } from '../stores';
import {
  DecoratedEntry,
  DecoratedInstance,
  isDecoratedInstance,
  MinimumDecoratedInstanceProps,
} from '../types';

export const isRecurringEntry = (entry: DecoratedEntry): boolean => {
  return entry.recurrences.some((recurrence) => recurrence.rule);
};

/**
 * Format an instance time in h:mm format
 */
export const formatInstanceTime = ({
  startDate,
  endDate,
  isOnDay,
  sequence,
  isFirstInSequence,
  isLastInSequence,
}: {
  startDate: DateTime;
  endDate: DateTime;
  isOnDay?: boolean;
  sequence?: number;
  isFirstInSequence?: boolean;
  isLastInSequence?: boolean;
}): string | null => {
  const startTimeFormat = startDate.minute > 0 ? 'h:mm a' : 'h a';
  const endTimeFormat = endDate.minute > 0 ? 'h:mm a' : 'h a';
  const format = (date: DateTime, format: string) =>
    date.toFormat(format).toLowerCase();

  // If we're dealing with a multi-day non-recurring entry
  if (typeof sequence === 'number' && !isOnDay) {
    if (isFirstInSequence) {
      return i18n.t('schedule:entry_time.starts_at', {
        time: format(startDate, startTimeFormat),
      });
    }
    if (isLastInSequence) {
      return i18n.t('schedule:entry_time.ends_at', {
        time: format(endDate, endTimeFormat),
      });
    }
    // Hide time entirely for middle instances
    return null;
  }

  // Hide time if it's all or or happens to be 12am-12am
  if (isOnDay || isAllDay(startDate, endDate)) {
    return null;
  }

  const startTime = format(startDate, startTimeFormat);
  const endTime = format(endDate, endTimeFormat);
  return `${startTime} - ${endTime}`;
};

type ColorScheme = {
  base: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  hoverLightBackgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  tagBackgroundColor?: string;
};

export const getEntryCategoryColors = (
  color: string | undefined | null,
  mode: ColorMode = 'light'
): ColorScheme => {
  if (mode === 'dark') {
    return {
      base: color ?? DEFAULT_ENTRY_COLOR,
      backgroundColor: setAlpha(color ?? DEFAULT_ENTRY_COLOR, 0.3),
      hoverBackgroundColor: setAlpha(color || DEFAULT_ENTRY_COLOR, 0.37),
    };
  }
  return {
    base: color ?? DEFAULT_ENTRY_COLOR,
    backgroundColor: setAlpha(color ?? DEFAULT_ENTRY_COLOR, 0.12),
    hoverBackgroundColor: setAlpha(color || DEFAULT_ENTRY_COLOR, 0.17),
    hoverLightBackgroundColor: setAlpha(color || DEFAULT_ENTRY_COLOR, 0.05),
    textColor: color ? color : 'customgray.dark',
    borderColor: setAlpha(color || DEFAULT_ENTRY_COLOR, 0.1),
    tagBackgroundColor: color ? setAlpha(color, 0.15) : 'gray.100',
  };
};

export const getEntryItemStyles = <
  I extends {
    category?: Pick<Category, 'color'> | null;
    isHidden?: boolean;
  },
>(
  instance: I
) => {
  const colors = getEntryCategoryColors(instance.category?.color);
  const isHidden = isDecoratedInstance(instance) ? instance.isHidden : false;

  const background = isHidden ? undefined : colors.backgroundColor;
  const backgroundSize = isHidden ? '15.56px 15.56px' : undefined;
  const backgroundImage = isHidden
    ? `linear-gradient(135deg, #eea7ca 4.55%, #e8e8e8 4.55%, #e8e8e8 50%, #eea7ca 50%, #eea7ca 54.55%, #e8e8e8 54.55%, #e8e8e8 100%)`
    : undefined;

  const hoverBackground = isHidden ? undefined : colors.hoverBackgroundColor;

  const container = {
    background,
    backgroundSize,
    backgroundImage,
    _hover: {
      background: hoverBackground,
    },
  };

  const category: ChakraProps = {
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: 'normal',
    backgroundColor: setAlpha(colors.base, 0.1),
  };

  // @deprecated
  const categoryTag: ChakraProps = {
    background: colors.tagBackgroundColor,
    color: 'customgray.dark',
  };

  const hideIcon: ChakraProps = {
    _hover: {
      bg: colors.hoverBackgroundColor,
    },
    _active: {
      bg: colors.hoverBackgroundColor,
    },
  };

  const tag: ChakraProps = {
    px: 2,
    pt: '5px',
    pb: 1,
    fontSize: '11px',
    fontWeight: 'medium',
    lineHeight: 'normal',
    background: 'white',
    borderRadius: '40px',
    borderWidth: '1px',
    borderColor: 'rgba(0,0,0,0.2)',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 1,
    minW: '25%',
  };

  return {
    container,
    category,
    categoryTag,
    hideIcon,
    tag,
  };
};

/**
 * Validates the correctness of an entry before it's persisted to the server.
 * This acts as a sanity check/line of defense to prevent invalid entries
 * from being created.
 *
 * If an error is produced here then there's a bug somewhere.
 */
export const validateEntry = (entry: DecoratedEntry): Error | undefined => {
  let error: Error | undefined;

  for (const recurrence of entry.recurrences) {
    if (!recurrence.isOnDay) {
      continue;
    }

    const { startDate, endDate } = recurrence;

    if (startDate.hour !== 0) {
      error = new Error('startDate must start at midnight for on-day entries');
    }
    if (endDate.hour !== 0) {
      error = new Error('endDate must start at midnight for on-day entries');
    }
    if (endDate <= startDate) {
      error = new Error('endDate must be after startDate');
    }
  }

  if (error) {
    Sentry.captureException(error, {
      extra: {
        entry,
      },
    });
  }

  return error;
};

export const sortEntryInstances = <T extends MinimumDecoratedInstanceProps>(
  instances: T[],
  draftEntry?: DecoratedEntry,
  draftEntryMode?: DraftMode,
  draftEntryComposer?: DraftComposer
) => {
  return draftEntry &&
    draftEntryMode === 'create' &&
    draftEntryComposer === 'inline'
    ? orderBy(
        instances,
        [
          (instance) => instance.parentId === draftEntry.id,
          (instance) => instance.startDate.toMillis(),
          (instance) => instance.createdAt.toMillis(),
          (instance) => instance.title,
        ],
        ['desc', 'asc', 'desc', 'desc']
      )
    : orderBy(
        instances,
        [
          (instance) => instance.startDate.toMillis(),
          (instance) => instance.createdAt.toMillis(),
          (instance) => instance.title,
        ],
        ['asc', 'desc', 'desc']
      );
};

/** Does `entry` belong to `scheduleId`'s primary internal feed? */
export const isInstanceOnPrimaryFeed = (
  instance: DecoratedInstance,
  scheduleId: string
): boolean => {
  return instance.feed?.primarySchedule?.id === scheduleId;
};

export const createContextId = (
  scheduleId: string,
  entryId: string
): string => {
  return `${scheduleId}_${entryId}`;
};
