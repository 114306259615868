import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { ScheduleView } from '../types';

type UseSelectedViewStorage = (scheduleId: string) => {
  lastSelectedView: ScheduleView | undefined;
  setLastSelectedView: (view: ScheduleView) => void;
};

type State = {
  scheduleIdToView: Record<string, ScheduleView>;
  setLastSelectedView: (scheduleId: string, view: ScheduleView) => void;
};

const useStore = createWithEqualityFn<State>()(
  persist(
    (set, get) => ({
      scheduleIdToView: {},
      setLastSelectedView: (scheduleId: string, view: ScheduleView) =>
        set(() => ({
          scheduleIdToView: {
            ...get().scheduleIdToView,
            [scheduleId]: view,
          },
        })),
    }),
    {
      name: '_ah_last_selected_view',
    }
  ),
  shallow
);

export const useLastSelectedView: UseSelectedViewStorage = (scheduleId) => {
  const store = useStore();
  const lastSelectedView = store.scheduleIdToView[scheduleId];
  const setLastSelectedView = (view: ScheduleView) =>
    store.setLastSelectedView(scheduleId, view);

  return {
    lastSelectedView,
    setLastSelectedView,
  };
};
