import { Flex, FlexProps } from '@chakra-ui/react';

export const Badge = (props: FlexProps) => {
  return (
    <Flex
      align="center"
      bg="red.500"
      borderRadius="20px"
      color="white"
      fontSize="xs"
      fontWeight="medium"
      justify="center"
      lineHeight="normal"
      minH="20px"
      minW="20px"
      pos="absolute"
      px="1.5"
      py="0.5"
      right="-4px"
      top="-8px"
      {...props}
    />
  );
};
