import { Button, ButtonProps } from '@chakra-ui/react';

export const Tab = (props: ButtonProps) => {
  return (
    <Button
      _active={{ bg: 'gray.200' }}
      _hover={{ bg: 'gray.200' }}
      alignItems="center"
      borderRadius="md"
      display="flex"
      justifyContent="flex-start"
      lineHeight="normal"
      px="4"
      textAlign="left"
      variant="unstyled"
      w="100%"
      {...props}
    />
  );
};
