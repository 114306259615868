import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCurrentUserContext } from '@/contexts';
import { EntryFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { createSnippetQueryKey } from '@/utils/queryKeys';
import { SnippetFragment } from '../../fragments/SnippetFragment';
import {
  GetSnippetQuery,
  GetSnippetQueryVariables,
} from './useSnippet.generated';

const query = gql`
  ${SnippetFragment}
  ${EntryFragment}
  query GetSnippet(
    $snippetId: ID!
    $entriesStartDate: DateTime!
    $entriesEndDate: DateTime!
  ) {
    getSnippet(snippetId: $snippetId) {
      ...Snippet
      entries(startDate: $entriesStartDate, endDate: $entriesEndDate) {
        ...Entry
      }
    }
  }
`;

export type SnippetData = GetSnippetQuery['getSnippet'];

export const useSnippet = () => {
  const { snippetId = '' } = useParams<{ snippetId: string }>();
  const { isAuthenticated, currentUser } = useCurrentUserContext();
  const [searchParams] = useSearchParams();

  const queryKey = useMemo(
    () => createSnippetQueryKey(snippetId, currentUser?.id),
    [snippetId, currentUser?.id]
  );

  const { startDate, endDate } = useMemo(() => {
    const queryStart = searchParams.get('startDate');
    const queryStartDate = queryStart
      ? DateTime.fromFormat(queryStart, 'yyyy-MM-dd')
      : null;

    const queryEnd = searchParams.get('endDate');
    const queryEndDate = queryEnd
      ? DateTime.fromFormat(queryEnd, 'yyyy-MM-dd')
      : null;

    const startDate = queryStartDate?.isValid
      ? queryStartDate
      : DateTime.local().startOf('day');

    const endDate = queryEndDate?.isValid
      ? queryEndDate.plus({ days: 1 }) // make end date inclusive
      : startDate.plus({ years: 1 });
    return { startDate, endDate };
  }, [searchParams]);

  const result = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const variables: GetSnippetQueryVariables = {
        snippetId: snippetId,
        entriesStartDate: startDate.toUTC().toISO(),
        entriesEndDate: endDate.toUTC().toISO(),
      };

      return gqlClient.request<GetSnippetQuery, GetSnippetQueryVariables>(
        query,
        variables
      );
    },
    enabled: !!snippetId,
    placeholderData: keepPreviousData,
    select: (data) => data.getSnippet,
    staleTime: isAuthenticated ? 5_000 : 30_000,
    refetchOnWindowFocus: (query) => {
      if (query.state.error) {
        return false;
      }
      return true;
    },
  });

  return {
    data: result.data,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    isError: result.isError,
    error: result.error,
    queryKey,
    startDate,
    endDate,
  };
};
