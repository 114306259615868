import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { Role } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  createScheduleMembersQueryKey,
  createScheduleCollaboratorsQueryKey,
} from '@/utils/queryKeys';
import {
  UpdateCollaboratorMutation,
  UpdateCollaboratorMutationVariables,
} from './useUpdateCollaborator.generated';

const query = gql`
  mutation UpdateCollaborator($scheduleId: ID!, $userId: ID!, $role: Role!) {
    updateSchedulePermission(
      scheduleId: $scheduleId
      userId: $userId
      role: $role
    )
  }
`;

export const useUpdateCollaborator = () => {
  const queryClient = useQueryClient();
  const { scheduleId } = useScheduleContext();

  const { mutate, error, isPending, reset } = useMutation<
    UpdateCollaboratorMutation,
    QueryError,
    UpdateCollaboratorMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        UpdateCollaboratorMutation,
        UpdateCollaboratorMutationVariables
      >(query, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createScheduleCollaboratorsQueryKey(scheduleId),
      });
      queryClient.invalidateQueries({
        queryKey: createScheduleMembersQueryKey(scheduleId),
      });
    },
  });

  const updateCollaborator = (
    userId: string,
    role: Role,
    options?: MutateOptions<
      UpdateCollaboratorMutation,
      QueryError,
      UpdateCollaboratorMutationVariables
    >
  ) => mutate({ scheduleId, userId, role }, options);

  return {
    updateCollaborator,
    error,
    isPending,
    reset,
  };
};
