import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { ProfileFragment } from '@/fragments';
import { gqlClient, queryClient } from '@/lib';
import { isGQLErrorOfType } from '@/utils/errors';
import { createProfileQueryKey } from '@/utils/queryKeys';
import {
  DecoratedProfile,
  DecoratedSettings,
  UserSettingNameToValueMap,
} from '../types';
import {
  GetProfileQuery,
  GetProfileQueryVariables,
} from './useProfile.generated';

const query = gql`
  ${ProfileFragment}
  query GetProfile {
    getProfile {
      ...Profile
    }
  }
`;

const selector = (data: GetProfileQueryFnData): DecoratedProfile => {
  // Convert settings from an array to an object
  const settings = data.getProfile?.settings.reduce(
    (acc, setting) => {
      acc[setting.name] =
        setting.value as UserSettingNameToValueMap[typeof setting.name];
      return acc;
    },
    {} as Record<string, unknown>
  ) as DecoratedSettings;

  return data.getProfile ? { ...data.getProfile, settings } : null;
};

const queryKey = createProfileQueryKey();

type GetProfileQueryFnData = GetProfileQuery | { getProfile: null };

export const useProfile = () => {
  const {
    data: currentUser,
    error,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        return await gqlClient.request<
          GetProfileQuery,
          GetProfileQueryVariables
        >(query);
      } catch (err) {
        // This helps us with three things:
        // 1. Suppress console errors when user is unauthenticated
        // 2. Make `null` an acceptable value for `getProfile`, meaning not logged in
        // 3. Prevent the unauthenticated error from appearing in the query's `error` object
        if (isGQLErrorOfType(err, 'NotLoggedIn')) {
          return { getProfile: null };
        }
        throw err;
      }
    },
    select: selector,
    staleTime: 30_000,
  });

  const setUser = useCallback((user: GetProfileQueryFnData['getProfile']) => {
    queryClient.setQueriesData({ queryKey }, { getProfile: user });
  }, []);

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey });
  }, []);

  return {
    currentUser,
    setUser,
    error,
    refetch,
    invalidate,
    isLoading,
    isError,
    isAuthenticated: !!currentUser,
  };
};
