import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useCurrentUserContext } from '@/contexts';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { AddToCalendarModal } from '../../../AddToCalendarModal';
import { ShareModal } from '../../../ShareModal';
import { useToolbarButtons } from '../../hooks/useToolbarButtons';
import { Header } from '../Header';
import { CreateScheduleModal } from './CreateScheduleModal';
import { SharedUsersStack } from './SharedUsersStack';

export const PrivateHeader = () => {
  const { isPublicRole } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { isAuthenticated } = useCurrentUserContext();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { value: icsSharingEnabled } = useFeatureFlag('icsSharingEnabled');

  const {
    isOpen: isCreateScheduleModalOpen,
    onOpen: openCreateScheduleModal,
    onClose: closeCreateScheduleModal,
  } = useDisclosure();

  const toolbarButtons = useToolbarButtons(openCreateScheduleModal);

  const showAddToCalendarButton =
    !isMobileBreakpoint &&
    icsSharingEnabled &&
    hasSchedulePermission('SCHEDULE_EXPORT');

  const showShareButton =
    !isMobileBreakpoint && hasSchedulePermission('PERMISSIONS_MODIFY');

  return (
    <>
      <CreateScheduleModal
        isOpen={isCreateScheduleModalOpen}
        onClose={closeCreateScheduleModal}
      />
      <Header
        hideProfileMenu={!isMobileBreakpoint && !isAuthenticated}
        sx={{ '@media print': { display: 'none' } }}
        leftColumn={
          isMobileBreakpoint ? (
            <Text
              color="dark.500"
              fontSize="17px"
              fontWeight="extrabold"
              lineHeight="normal"
            >
              agendahero
            </Text>
          ) : (
            toolbarButtons
          )
        }
        rightColumn={
          isAuthenticated && (
            <Flex align="center" gap="3">
              {!isPublicRole && !isMobileBreakpoint && <SharedUsersStack />}
              {isMobileBreakpoint && toolbarButtons}
              {showAddToCalendarButton && <AddToCalendarModal />}
              {showShareButton && <ShareModal />}
            </Flex>
          )
        }
      />
    </>
  );
};
