import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { ScheduleCategory } from '@/pages/Schedule/hooks';
import { QueryError } from '@/utils/errors';
import {
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
} from './useUpdateCategory.generated';
import type { Category } from '../types';

const query = gql`
  mutation UpdateCategory(
    $scheduleId: ID!
    $categoryId: ID!
    $text: String
    $color: String
  ) {
    updateCategory(
      scheduleId: $scheduleId
      categoryId: $categoryId
      text: $text
      color: $color
    ) {
      id
      text
      color
    }
  }
`;

type Context = { prevCategory?: ScheduleCategory };

export const useUpdateCategory = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    UpdateCategoryMutation,
    QueryError,
    UpdateCategoryMutationVariables,
    Context
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateCategoryMutation,
        UpdateCategoryMutationVariables
      >(query, variables),
  });

  const updateCategory = useCallback(
    (
      category: Category,
      options?: MutateOptions<
        UpdateCategoryMutation,
        QueryError,
        UpdateCategoryMutationVariables,
        Context
      >
    ) =>
      mutate(
        {
          categoryId: category.id,
          scheduleId,
          color: category.color,
          text: category.text || null,
        },
        options
      ),
    [scheduleId, mutate]
  );

  return { updateCategory };
};
