import { Button, ButtonProps, forwardRef, Tooltip } from '@chakra-ui/react';
import { cloneElement } from 'react';

type Props = ButtonProps & {
  label?: string;
  isHighlighted?: boolean;
};

export const HeaderButton = forwardRef(
  (
    {
      isDisabled,
      isActive,
      isHighlighted,
      label,
      leftIcon,
      children,
      ...buttonProps
    }: Props,
    ref
  ) => {
    return (
      <Tooltip
        color="customgray.dark"
        hasArrow
        label={label}
        textAlign="center"
        variant="light"
      >
        <Button
          aria-label={label}
          color={isHighlighted ? 'brand.500' : undefined}
          colorScheme="dark"
          isActive={isActive}
          isDisabled={isDisabled}
          minW="auto"
          overflow="hidden"
          px="2"
          ref={ref}
          size="sm"
          transition="all 0.2s ease"
          variant="ghost"
          _active={{
            bg: 'gray.200',
            color: isHighlighted ? 'brand.500' : undefined,
          }}
          _hover={{
            bg: 'gray.100',
            color: isHighlighted
              ? 'brand.500'
              : isDisabled
                ? 'customgray.mid'
                : undefined,
          }}
          leftIcon={
            leftIcon
              ? cloneElement(leftIcon, {
                  color: isHighlighted
                    ? 'brand.500'
                    : isActive
                      ? 'customgray.dark'
                      : 'customgray.mid',
                })
              : undefined
          }
          sx={{
            span: {
              display: 'flex',
              mr: children ? undefined : 0,
            },
          }}
          onFocus={(event) => event.preventDefault()}
          {...buttonProps}
        >
          {children}
        </Button>
      </Tooltip>
    );
  }
);
