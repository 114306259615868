import { Select } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { getRelativeYears } from '@/utils/dates';

type Props = {
  value: DateTime;
  onChange: (value: number) => void;
};

export const YearSelect = ({ value, onChange }: Props) => {
  const options = getRelativeYears(value, 3, 3).map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));

  return (
    <Select
      fontSize="sm"
      size="sm"
      value={value.toFormat('yyyy')}
      w="90px"
      onChange={(event) => onChange(Number(event.target.value))}
    >
      {options}
    </Select>
  );
};
