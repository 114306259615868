import { useCallback, useRef } from 'react';

// https://github.com/facebook/react/issues/15176#issuecomment-512740882
export const useCallbackRef = <T>(
  rawCallback: (node: T | null) => (() => void) | undefined
) => {
  const cleanupRef = useRef<(() => void) | undefined>(undefined);

  return useCallback(
    (node: T | null) => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = undefined;
      }
      if (node) {
        cleanupRef.current = rawCallback(node);
      }
    },
    [rawCallback]
  );
};
