import { createEntryDate, getLocalTimeZone } from '@/utils/dates';
import { QuickEntriesJobFragment } from '../fragments/QuickEntriesJobFragment.generated';
import { QuickEntryFragment } from '../fragments/QuickEntryFragment.generated';
import {
  DecoratedQuickCreateEntry,
  DecoratedQuickCreateEntryJob,
} from '../types';

export const createDecoratedQuickCreateEntry = (
  rawEntry: QuickEntryFragment
): DecoratedQuickCreateEntry => {
  const localTimeZone = getLocalTimeZone();
  const { startDate, endDate, isOnDay } = rawEntry.recurrences[0];

  return {
    ...rawEntry,
    ...rawEntry.recurrences[0],
    startDate: createEntryDate(
      startDate,
      rawEntry.timeZone,
      localTimeZone,
      isOnDay
    ),
    endDate: createEntryDate(
      endDate,
      rawEntry.timeZone,
      localTimeZone,
      isOnDay
    ),
  };
};

export const createDecoratedQuickCreateEntryJob = (
  job: QuickEntriesJobFragment
): DecoratedQuickCreateEntryJob => {
  return {
    ...job,
    entries: job.quickEntries.map<DecoratedQuickCreateEntry>(
      createDecoratedQuickCreateEntry
    ),
  };
};
