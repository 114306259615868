import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsCalendar2Week } from 'react-icons/bs';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { RxMagicWand } from 'react-icons/rx';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { ScheduleFragment } from '@/fragments/schedule.generated';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useCreateSchedule } from '@/hooks/useCreateSchedule';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useNavigateToSchedule } from '@/hooks/useNavigateToSchedule';
import { useToast } from '@/hooks/useToast';
import { ScheduleType } from '@/types/gql.generated';
import { ImportOption } from './components/ImportOption';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateScheduleModal = ({ isOpen, onClose }: Props) => {
  const toast = useToast();
  const { t } = useTranslation('createScheduleModal');
  const navigate = useNavigate();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { createSchedule } = useCreateSchedule();
  const { navigateToSchedule } = useNavigateToSchedule();
  const { trackEvent } = useAnalytics();
  const { value: conflictScheduleEnabled } = useFeatureFlag(
    'conflictScheduleEnabled'
  );

  const handleSuccess = (schedule?: ScheduleFragment | null) => {
    if (schedule) {
      const navigateOptions: NavigateOptions | undefined =
        schedule.type === ScheduleType.Classic
          ? {
              state: {
                launch: 'onboarding',
              },
            }
          : undefined;
      navigateToSchedule(schedule, navigateOptions);
      toast.notify(t('success_toast'));
      trackEvent('schedule:create', { type: schedule.type });
      onClose();
    }
  };

  const handleCreate = (type: ScheduleType) => {
    createSchedule(
      {
        type,
      },
      {
        onSuccess: handleSuccess,
        onError: () => toast.error(t('error_toast_unknown')),
      }
    );
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      returnFocusOnClose={false}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius="20"
        minW={{ base: '95%', sm: '32rem' }}
        overflow="hidden"
        w={{ base: '95%' }}
      >
        <ModalBody display="flex" p="0">
          <Flex dir="column" flex="1">
            <Flex align="flex-start" direction="column" flex="1">
              <ImportOption
                borderTopRadius="20"
                py="10"
                title={t('from_scratch')}
                icon={
                  <Icon
                    as={BsCalendar2Week}
                    boxSize={isMobileBreakpoint ? 22 : 6}
                  />
                }
                onClick={() => handleCreate(ScheduleType.Classic)}
              />
              <ImportOption
                borderBottomRadius={conflictScheduleEnabled ? '0' : '20'}
                borderTopColor="gray.100"
                borderTopWidth="1px"
                py="10"
                title={t('from_prompt')}
                icon={
                  <Icon
                    as={RxMagicWand}
                    boxSize={isMobileBreakpoint ? 22 : 6}
                  />
                }
                onClick={() => navigate('/prompt')}
              />
              {conflictScheduleEnabled && (
                <ImportOption
                  borderBottomRadius="20"
                  borderTopWidth="1px"
                  py="10"
                  title={t('for_conflict')}
                  icon={
                    <Icon
                      as={FaRegCalendarTimes}
                      boxSize={isMobileBreakpoint ? 22 : 6}
                    />
                  }
                  onClick={() => handleCreate(ScheduleType.Conflict)}
                />
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
