import { ReactNode } from 'react';
import { createContext } from '@/utils';

type FamilyFlowContextValue =
  | {
      slide: number;
      slides: number;
    }
  | undefined;

type Props = {
  children: ReactNode;
  value: FamilyFlowContextValue;
};

export const FamilyFlowContext = createContext<FamilyFlowContextValue>(
  undefined,
  'FamilyFlowContext'
);

// This context mainly acts as a proxy to the useProfile hook while providing
// a logical space to perform one-time side-effects, like initializing LogRocket.
// If we didn't have side-effects then components could just use the useProfile
// hook directly.
export const FamilyFlowContextProvider = ({ children, value }: Props) => {
  return (
    <FamilyFlowContext.Provider value={value}>
      {children}
    </FamilyFlowContext.Provider>
  );
};
