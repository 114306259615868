import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import { createScheduleSnippetsQueryKey } from '@/utils/queryKeys';
import {
  DeleteSnippetMutation,
  DeleteSnippetMutationVariables,
} from './useDeleteSnippet.generated';

const query = gql`
  mutation DeleteSnippet($scheduleId: ID!, $snippetId: ID!) {
    deleteSnippet(scheduleId: $scheduleId, snippetId: $snippetId)
  }
`;

export const useDeleteSnippet = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    DeleteSnippetMutation,
    QueryError,
    DeleteSnippetMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<DeleteSnippetMutation, DeleteSnippetMutationVariables>(
        query,
        variables
      ),
  });

  const deleteSnippet = (
    snippetId: string,
    options?: MutateOptions<
      DeleteSnippetMutation,
      QueryError,
      DeleteSnippetMutationVariables
    >
  ) => {
    return mutate(
      { snippetId, scheduleId },
      {
        ...options,
        onSuccess: (...args) => {
          queryClient.invalidateQueries({
            queryKey: createScheduleSnippetsQueryKey(scheduleId),
          });
          options?.onSuccess?.(...args);
        },
      }
    );
  };

  return {
    deleteSnippet,
    isPending,
  };
};
