import { useEffect } from 'react';
import { useDraftEntryStore } from '../../../stores';

// Allows the entry modal to scroll on top of the add content modal.
// See https://github.com/chakra-ui/chakra-ui/issues/7588
export const useEntryModalScrollFix = () => {
  const isEntryModalOpen = useDraftEntryStore((state) =>
    Boolean(state.draftEntry)
  );

  useEffect(() => {
    const body = document.body;

    if (isEntryModalOpen) {
      body.style.overflow = 'hidden';
      body.style.touchAction = 'none';
    } else {
      body.style.overflow = '';
      body.style.touchAction = '';
    }
  }, [isEntryModalOpen]);

  return { isEntryModalOpen };
};
