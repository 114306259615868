import { Alert, AlertProps, Text } from '@chakra-ui/react';
import { getErrorMessage } from './getErrorMessage';
import { ErrorInput, PromptType } from './types';

type Props = AlertProps & {
  error: ErrorInput;
  type?: PromptType;
};

export const QuickCreateErrorMessage = ({ error, type, ...props }: Props) => {
  const { title, message } = getErrorMessage(error, type);

  return (
    <Alert alignItems="flex-start" flexDir="column" variant="purple" {...props}>
      <Text>{title}</Text>
      {message && <Text dangerouslySetInnerHTML={{ __html: message }} />}
    </Alert>
  );
};
