import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  ToggleScheduleHeaderEnabledMutation,
  ToggleScheduleHeaderEnabledMutationVariables,
} from './useToggleScheduleHeaderEnabled.generated';

const query = gql`
  mutation ToggleScheduleHeaderEnabled(
    $scheduleId: ID!
    $headerEnabled: Boolean
  ) {
    updateSchedule(scheduleId: $scheduleId, headerEnabled: $headerEnabled) {
      id
      header {
        id
        enabled
        imageBaseUrl
        type
      }
    }
  }
`;

export const useToggleScheduleHeaderEnabled = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    ToggleScheduleHeaderEnabledMutation,
    QueryError,
    ToggleScheduleHeaderEnabledMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        ToggleScheduleHeaderEnabledMutation,
        ToggleScheduleHeaderEnabledMutationVariables
      >(query, variables),
    onMutate: ({ scheduleId, headerEnabled }) => {
      return {
        optimisticData: {
          id: scheduleId,
          header: {
            enabled: headerEnabled,
          },
        },
        rollbackData: {
          id: scheduleId,
          header: {
            enabled: !headerEnabled,
          },
        },
      };
    },
  });

  const toggleScheduleHeaderEnabled = (
    headerEnabled: boolean,
    options?: MutateOptions<
      ToggleScheduleHeaderEnabledMutation,
      QueryError,
      ToggleScheduleHeaderEnabledMutationVariables
    >
  ) => {
    return mutate({ scheduleId, headerEnabled }, options);
  };

  return {
    toggleScheduleHeaderEnabled,
  };
};
