import { i18n } from '@/i18n';
import { QueryError, isGQLErrorOfType } from '@/utils/errors';

export const getAuthErrorMessage = (error: QueryError): string => {
  if (isGQLErrorOfType(error, 'UserExists')) {
    return i18n.t('auth:errors.user_exists');
  }
  if (isGQLErrorOfType(error, 'MerValidationErrFailedValidation')) {
    return i18n.t('auth:errors.validation_failed');
  }
  if (isGQLErrorOfType(error, 'InvalidLogin')) {
    return i18n.t('auth:errors.wrong_password');
  }
  if (isGQLErrorOfType(error, 'BadRequest')) {
    return i18n.t('auth:errors.unknown_error');
  }

  return error.message;
};
