import { Box, Image } from '@chakra-ui/react';
import { EntriesJobType } from '@/types/gql.generated';
import { RichTextEditor } from '../../../RichTextEditor';
import { useIsColumnLayout } from '../../hooks/useIsColumnLayout';
import { Job } from '../../types';
import { Panel } from './Panel';

type Props = {
  job: Job;
};

export const PromptPanel = ({ job }: Props) => {
  const { isColumnLayout } = useIsColumnLayout();

  return (
    <Panel flex={isColumnLayout ? 0 : 1}>
      <Box
        bg="white"
        borderColor="customgray.light"
        borderRadius="xl"
        borderWidth="1px"
        overflowY={isColumnLayout ? 'initial' : 'auto'}
        px={{ base: 4, md: 5 }}
        py={{ base: 3, md: 6 }}
      >
        {job.type === EntriesJobType.Prompt && job.prompt ? (
          <RichTextEditor readOnly value={job.prompt} />
        ) : job.file ? (
          <a href={job.file.url} rel="noopener noreferrer" target="_blank">
            <Image src={job.file.url} />
          </a>
        ) : null}
      </Box>
    </Panel>
  );
};
