import { List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsCalendar3 } from 'react-icons/bs';
import { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { getSortedCalendars, isPrimaryCalendar } from '@/utils/googleCalendar';

type Props = {
  integration: DecoratedIntegration;
};

export const CalendarList = ({ integration }: Props) => {
  const { t } = useTranslation('settingsModal', { keyPrefix: 'calendars' });
  const calendars = getSortedCalendars(integration).filter((calendar) => {
    return (
      calendar.scheduleCount > 0 || isPrimaryCalendar(integration, calendar)
    );
  });

  return (
    <List ml="6">
      {calendars.map((calendar) => (
        <ListItem data-calendar-uri={calendar.uri} key={calendar.uri}>
          <ListIcon as={BsCalendar3} boxSize="14px" />
          {calendar.name}
          {calendar.scheduleCount > 0 && (
            <Text as="span" fontSize="sm" fontWeight="bold" mx="2">
              &ndash;{' '}
              {t('used_in_schedules', { count: calendar.scheduleCount })}
            </Text>
          )}
        </ListItem>
      ))}
    </List>
  );
};
