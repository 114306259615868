import { useState, useRef } from 'react';
import { useCallbackRef } from '@/hooks/useCallbackRef';

export const useMeasurePromptHeight = () => {
  const enabled = useRef(true);
  const [promptHeight, setPromptHeight] = useState(0);

  const promptRef = useCallbackRef<HTMLDivElement | null>((node) => {
    if (node) {
      const resizeObserver = new ResizeObserver(() => {
        if (enabled.current) {
          setPromptHeight(node.getBoundingClientRect().height);
        }
      });

      resizeObserver.observe(node);

      return () => {
        resizeObserver.unobserve(node);
      };
    }
  });

  const setMeasurePromptHeightEnabled = (value: boolean) => {
    enabled.current = value;
  };

  return {
    promptRef,
    promptHeight,
    setMeasurePromptHeightEnabled,
  };
};
