import { Flex, FlexProps } from '@chakra-ui/react';
import { useIsColumnLayout } from '../hooks/useIsColumnLayout';

type Props = FlexProps & {
  pollJobSuccess: boolean;
};

export const ContentContainer = ({ pollJobSuccess, ...props }: Props) => {
  const { isColumnLayout } = useIsColumnLayout();

  return (
    <Flex
      align="center"
      direction="column"
      flex="1"
      gap="3"
      pos="relative"
      overflowY={
        !pollJobSuccess ? 'initial' : isColumnLayout ? 'auto' : 'hidden'
      }
      {...props}
    />
  );
};
