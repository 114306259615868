import { DeleteIcon } from '@chakra-ui/icons';
import {
  Flex,
  Icon,
  IconButton,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import {
  PlatformIcon,
  Props as PlatformIconProps,
} from '@/components/PlatformIcon';
import { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import { CalendarList } from './CalendarList';
import { useDisconnectDialog } from './DisconnectDialog';
import { MagicControl } from './MagicControl';

type ItemProps = {
  integration: DecoratedIntegration;
};

const GoogleIcon = (props: PlatformIconProps) => (
  <PlatformIcon {...props} platform={PlatformTypes.GoogleIntegration} />
);

export const IntegrationItem = ({ integration }: ItemProps) => {
  const { open: openDisconnectDialog } = useDisconnectDialog();

  return (
    <ListItem>
      <Flex align="center" fontWeight="normal" key={integration.id}>
        <ListIcon as={GoogleIcon} />

        <Text as="span" flexGrow="1">
          {integration.name}
        </Text>

        <Flex align="center" gap="2" justify="flex-start">
          <MagicControl integration={integration} />

          <Menu isLazy placement="bottom-end">
            <MenuButton
              aria-label="Options"
              as={IconButton}
              colorScheme="dark"
              h="5"
              variant="unstyled"
              icon={
                <Icon as={IoEllipsisHorizontalSharp} pos="relative" top="1" />
              }
            />

            <MenuList>
              <MenuItem
                icon={<DeleteIcon />}
                onClick={() => openDisconnectDialog(integration)}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <CalendarList integration={integration} />
    </ListItem>
  );
};
