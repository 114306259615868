import { MutableRefObject } from 'react';
import classes from './shakeElement.module.css';

export const shakeElement = async (
  ref: MutableRefObject<HTMLElement | null>
) => {
  return new Promise<void>((resolve) => {
    function onAnimationEnd() {
      ref.current?.classList.remove(classes.shake);
      ref.current?.removeEventListener('animationend', onAnimationEnd);
      resolve();
    }
    ref.current?.addEventListener('animationend', onAnimationEnd);
    ref.current?.classList.add(classes.shake);
  });
};
