import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

type Props = FlexProps & {
  href?: string;
  icon: JSX.Element;
  label: string;
};

export const SendToButton = ({
  children,
  icon,
  label,
  href,
  ...props
}: Props) => {
  return (
    <Flex
      _hover={{ bg: 'gray.100', textDecor: 'none' }}
      align="center"
      as="a"
      borderColor="customgray.gray"
      borderRadius="6px"
      borderWidth="1px"
      cursor="pointer"
      display="flex"
      flex="1"
      gap="6"
      href={href}
      p="5"
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      {children ? (
        children
      ) : (
        <>
          <Box>{icon}</Box>
          <Flex align="flex-start" direction="column">
            <Text
              color="customgray.mid"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Send to
            </Text>
            <Text fontWeight="medium">{label}</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
