import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeleteSchedule } from '@/hooks/useDeleteSchedule';
import { useToast } from '@/hooks/useToast';
import { ConfirmDialog } from '@/pages/Schedule/components/ConfirmDialog';
import { useScheduleContext } from '@/pages/Schedule/contexts';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const DeleteConfirmation = ({ isOpen, onCancel }: Props) => {
  const { t } = useTranslation('header');
  const { deleteSchedule, isPending } = useDeleteSchedule();
  const { schedule } = useScheduleContext();
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <ConfirmDialog
      confirmButtonText={t('schedule.delete_confirm.submit')}
      isLoading={isPending}
      isOpen={isOpen}
      title={t('schedule.delete_confirm.title', {
        title: schedule?.title || t('untitled_schedule', { ns: 'common' }),
      })}
      onCancel={onCancel}
      onConfirm={() => {
        deleteSchedule({
          onSuccess: () => navigate('/'),
          onError: () => toast.error('schedule.delete_confirm.error_toast'),
        });
      }}
    />
  );
};
