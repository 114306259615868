import { Container, Box } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLoader } from '@/components/AppLoader';
import { ActionBar, Banner } from '../components';
import { ScheduleHero } from '../components/ScheduleHero';
import { useScheduleContext } from '../contexts';
import { ViewProps } from './types';

// The views are imported here rather than in the module to avoid
// issues with hot module reloading
const WeekView = lazy(() => import('./WeekView/WeekView'));
const MonthView = lazy(() => import('./MonthView/MonthView'));
const AgendaView = lazy(() => import('./AgendaView/AgendaView'));
const ConflictView = lazy(() => import('./ConflictView/ConflictView'));
const SummaryView = lazy(() => import('./SummaryView/SummaryView'));

type Props = ViewProps & {
  onDateRangeChange: (startDate: DateTime, endDate?: DateTime) => void;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTodayClick: () => void;
};

export const View = ({
  onDateRangeChange,
  onNextClick,
  onPrevClick,
  onTodayClick,
  ...viewProps
}: Props) => {
  const { viewModule, schedule } = useScheduleContext();
  const { t } = useTranslation('schedule');

  if (schedule && !viewModule.scheduleTypes.includes(schedule.type)) {
    return <Box>{t('errors.unsupported_view')}</Box>;
  }

  return (
    <>
      {viewModule.bannerEnabled && <Banner />}
      {viewModule.heroEnabled && <ScheduleHero />}
      {viewModule.actionBarEnabled && (
        <ActionBar
          py={{ base: 3, md: 6 }}
          sx={{ '@media print': { display: 'none' } }}
          onDateRangeChange={onDateRangeChange}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          onTodayClick={onTodayClick}
        />
      )}

      <Container
        data-testid="schedule-view"
        display="flex"
        flex="1"
        flexDir="column"
        pt={viewModule.actionBarEnabled ? { base: 3, sm: 0, md: 2 } : 0}
        variant="max"
      >
        <Suspense fallback={<AppLoader data-testid="schedule-view-loader" />}>
          {viewModule.id === 'agenda' ? (
            <AgendaView {...viewProps} />
          ) : viewModule.id === 'week' ? (
            <WeekView {...viewProps} />
          ) : viewModule.id === 'month' ? (
            <MonthView {...viewProps} />
          ) : viewModule.id === 'conflict' ? (
            <ConflictView {...viewProps} />
          ) : viewModule.id === 'summary' ? (
            <SummaryView {...viewProps} />
          ) : null}
        </Suspense>
      </Container>
    </>
  );
};
