import { Flex, Icon, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaRegCompass } from 'react-icons/fa';
import { config } from '@/config';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { Layout } from '../Layout';
import { BrandLink } from './BrandLink';
import { useAuthClickHandler } from './hooks';
import { MobileMenu } from './MobileMenu';
import { Nav, NavLink } from './Nav';

export const PublicHeader = () => {
  const { t } = useTranslation(['header', 'auth']);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { schedule } = useScheduleContext();
  const { trackEvent } = useAnalytics();
  const { handleAuthClick } = useAuthClickHandler();

  const ctaLink = schedule?.cta?.link || config.marketingUrl;
  const ctaText = schedule?.cta?.text || t('readonly.tagline');

  return (
    <Layout
      containerProps={{
        maxW: 'container.xl',
        px: { base: '4', sm: '8' },
      }}
      leftColumn={
        <Flex align="center" gap="8">
          <BrandLink />

          {!isMobileBreakpoint && (
            <Link
              _hover={{ textDecoration: 'none', color: 'black' }}
              color="text"
              href={ctaLink}
              transition="color 0.1s ease-in-out"
              onClick={() => trackEvent('schedule:signup-cta')}
            >
              {ctaText}
            </Link>
          )}
        </Flex>
      }
      rightColumn={
        isMobileBreakpoint ? (
          <MobileMenu />
        ) : (
          <Nav>
            <NavLink href={`${config.marketingUrl}/gallery`}>
              <Text alignItems="center" display="inline-flex" gap="1">
                <Icon as={FaRegCompass} />
                {t('readonly.gallery')}
              </Text>
            </NavLink>
            <NavLink onClick={() => handleAuthClick('login')}>
              {t('auth:sign_in')}
            </NavLink>
            <NavLink primary onClick={() => handleAuthClick('register')}>
              {t('auth:create_for_free')}
            </NavLink>
          </Nav>
        )
      }
    />
  );
};
