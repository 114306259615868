import { Button, ButtonProps } from '@chakra-ui/react';

export const BannerButton = (props: ButtonProps) => (
  <Button
    _groupHover={{ opacity: 1 }}
    _hover={{ bg: 'customgray.dark' }}
    bg="customgray.alsodarker"
    cursor="pointer"
    opacity="0"
    size="sm"
    transition="opacity 0.2s ease"
    {...props}
  />
);
