import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  count: number;
  isPublishLoading: boolean;
  onClick: () => void;
};

export const PublishButton = ({ count, isPublishLoading, onClick }: Props) => {
  const { t } = useTranslation('batchCreate', { keyPrefix: 'header_card' });

  return (
    <Button
      _active={{ bg: undefined }}
      alignSelf={{ base: 'flex-start', md: 'center' }}
      colorScheme="dark"
      fontSize="md"
      fontWeight="bold"
      isLoading={isPublishLoading}
      loadingText={t('loading_text')}
      _hover={{
        bg: undefined,
        shadow: '0 0 8px 2px rgba(0, 0, 0, 0.15)',
      }}
      onClick={onClick}
    >
      {t('add', { count })}
    </Button>
  );
};
