import {
  Button,
  ButtonProps,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiShare } from 'react-icons/fi';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useScheduleCollaborators } from '@/pages/Schedule/hooks';
import { CollaboratorsSection } from './components/CollaboratorsSection';
import { PublicLinkSection } from './components/PublicLinkSection';
import { useShareModalStore } from './store';

type LaunchParams = {
  email?: string;
};

export const ShareModal = (buttonProps: ButtonProps) => {
  const { t } = useTranslation('shareModal');
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { close, defaults, isOpen, open } = useShareModalStore();
  const { collaborators, isLoading: isLoadingCollaborators } =
    useScheduleCollaborators();

  const debouncedIsLoadingCollaborators = useDebounceLoading(
    isLoadingCollaborators,
    250
  );

  useLauncherAction('share-modal', (params: LaunchParams) => open(params));

  const button = isMobileBreakpoint ? (
    <IconButton
      aria-label={t('button')}
      borderRadius="full"
      icon={<FiShare size="18" />}
      variant="secondary"
      onClick={() => open()}
      {...buttonProps}
    />
  ) : (
    <Button variant="secondary" onClick={() => open()} {...buttonProps}>
      {t('button')}
    </Button>
  );

  return (
    <>
      {button}

      <Modal
        isCentered
        isOpen={isOpen}
        returnFocusOnClose={false}
        variant="primary"
        onClose={close}
      >
        <ModalOverlay />

        <ModalContent
          data-testid="share-modal"
          maxH="var(--app-height)"
          minW={{ base: '100vw', md: '600px' }}
        >
          <ModalHeader>{t('heading')}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {debouncedIsLoadingCollaborators ? (
              <Center data-testid="share-loader" h="75px">
                <Spinner />
              </Center>
            ) : (
              <CollaboratorsSection
                collaborators={collaborators}
                defaultEmail={defaults.email}
              />
            )}
          </ModalBody>

          <ModalFooter alignItems="flex-start" flexDir="column">
            <PublicLinkSection />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
