import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { EntryModalMessage } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { QueryError } from '@/utils/errors';
import {
  CreateEntryMessagesMutation,
  CreateEntryMessagesMutationVariables,
} from './useCreateEntryMessages.generated';

const query = gql`
  mutation CreateEntryMessages(
    $scheduleId: ID!
    $entryId: ID!
    $messages: [EntryMessageInput!]!
  ) {
    createEntryMessages(
      scheduleId: $scheduleId
      entryId: $entryId
      messages: $messages
    ) {
      id
    }
  }
`;

export const useCreateEntryMessages = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    CreateEntryMessagesMutation,
    QueryError,
    CreateEntryMessagesMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateEntryMessagesMutation,
        CreateEntryMessagesMutationVariables
      >(query, variables),
  });

  const createEntryMessages = useCallback(
    (
      entry: DecoratedEntry,
      messages: EntryModalMessage[],
      options?: MutateOptions<
        CreateEntryMessagesMutation,
        QueryError,
        CreateEntryMessagesMutationVariables
      >
    ) => {
      const variables: CreateEntryMessagesMutationVariables = {
        scheduleId,
        entryId: entry.id,
        messages: messages.map((message) => ({
          id: message.id,
          body: message.body,
          deliveryTimeType: message.deliveryTimeType,
          hybridDeliveryTime: message.hybridDeliveryTime,
          hybridRelativeTime: message.hybridRelativeTime,
          hybridTimeZone: message.hybridTimeZone,
          relativeTimeToEntryStart: message.relativeTimeToEntryStart,
          relativeTimeToEntryStartUnit: message.relativeTimeToEntryStartUnit,
          relativeTimeToEntryStartDirection:
            message.relativeTimeToEntryStartDirection,
          instance: message.instance.toUTC().toISO(),
          recipients: message.recipients.map(({ id }) => id),
        })),
      };

      return mutate(variables, options);
    },
    [scheduleId, mutate]
  );

  return { createEntryMessages, isPending };
};
