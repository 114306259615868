import { RegisterModal } from '@/components/RegisterModal';
import { SettingsModal } from '@/components/SettingsModal';
import { UpdateAvailableCallout } from '@/components/UpdateAvailableCallout';
import { useCurrentUserContext } from '@/contexts';
import { useIsMagic } from '@/hooks/useIsMagic';
import { SnippetIngredientInUseModal } from '@/pages/Schedule/components';

export const GlobalComponents = () => {
  const { isAuthenticated } = useCurrentUserContext();
  const { isMagic } = useIsMagic();

  return (
    <>
      {isAuthenticated && (
        <>
          <SnippetIngredientInUseModal />
          <SettingsModal />
        </>
      )}

      {/**
       * This needs to be rendered outside of an isAuthenticated check
       * so it doesn't unmount before consumers are notified of
       * successful authentication
       */}
      <RegisterModal />

      {!isMagic && <UpdateAvailableCallout />}
    </>
  );
};
