import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';

export type HeaderButtonProps = Omit<IconButtonProps, 'aria-label'>;

export const HeaderButton = (props: IconButtonProps) => {
  return (
    <Tooltip label={props['aria-label']}>
      <IconButton size="sm" variant="secondary" {...props} />
    </Tooltip>
  );
};
