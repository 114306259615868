import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ComponentProps, ReactNode, useRef } from 'react';
import { ConfirmDialogTitle } from './ConfirmDialogTitle';

type Props = {
  children?: ReactNode;
  title: ReactNode;
  message?: string;
  isOpen: boolean;
  isLoading?: boolean;
  cancelButtonText?: string | null;
  confirmButtonText?: string;
  dialogOverlayProps?: ComponentProps<typeof AlertDialogOverlay>;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmDialog = ({
  children,
  title,
  message,
  isOpen,
  isLoading,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  dialogOverlayProps,
  onConfirm,
  onCancel,
}: Props) => {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      data-testid="confirm-dialog"
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      // `returnFocusOnClose`` is disabled because we can't guarantee that the element that
      // opened the modal will still be focusable when the modal closes. For example, this
      // modal can be opened from inside a `Menu` component on the agenda view and the menu
      // closes as soon as the menu item is clicked. Chakra is unable to return focus to the
      // menu item and the scroll position of the page is reset to the top.
      // https://github.com/chakra-ui/chakra-ui/issues/7734
      returnFocusOnClose={false}
      onClose={onCancel}
    >
      <AlertDialogOverlay {...dialogOverlayProps}>
        <AlertDialogContent
          borderRadius="20"
          minW={{ sm: '450px' }}
          mx={{ base: 4, sm: 0 }}
        >
          <AlertDialogBody
            px={{ base: 4, sm: '50px' }}
            py="10"
            textAlign="center"
          >
            {typeof title === 'string' ? (
              <ConfirmDialogTitle>{title}</ConfirmDialogTitle>
            ) : (
              title
            )}

            {!!message && (
              <Text color="customgray.mid" mt="2">
                {message}
              </Text>
            )}

            {children}

            <Flex gap="3" justify="center" mt="8">
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={onConfirm}
              >
                {confirmButtonText}
              </Button>
              {cancelButtonText && (
                <Button
                  isDisabled={isLoading}
                  ref={cancelRef}
                  variant="secondary"
                  onClick={onCancel}
                >
                  {cancelButtonText}
                </Button>
              )}
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
