import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { LaunchOption } from '@/hooks/useLauncher';
import { PlatformTypes } from '@/types/gql.generated';
import { LaunchReason } from './types';

type OpenFn = (args?: {
  reason: LaunchReason;
  platform?: PlatformTypes | null;
  launch?: LaunchOption;
}) => void;

type State = {
  isOpen: boolean;
  platform: PlatformTypes | null;
  reason: LaunchReason | null;
  launch: LaunchOption | null;
  open: OpenFn;
  close: () => void;
};

export const useAuthModal = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    platform: null,
    reason: null,
    launch: null,
    open: (args) => {
      const platform = args?.platform ?? null;
      const reason = args?.reason ?? null;
      const launch = args?.launch ?? null;
      set({ platform, reason, launch, isOpen: true });
    },
    close: () => set({ isOpen: false }),
  }),
  shallow
);
