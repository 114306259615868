import { ScheduleType } from '@/types/gql.generated';
import { ScheduleContextValue } from '../contexts';

export const getViewType = (
  schedule: ScheduleContextValue['schedule'],
  query: URLSearchParams
): ScheduleType => {
  const queryView = query.get('display');
  if (queryView === ScheduleType.Classic) {
    return ScheduleType.Classic;
  }
  if (queryView === ScheduleType.List) {
    return ScheduleType.List;
  }
  if (query.get('classic')) {
    return ScheduleType.Classic;
  }
  return schedule?.type || ScheduleType.Classic;
};
