import { Button, forwardRef, Portal, Text } from '@chakra-ui/react';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { StateCard, StateCardProps } from './StateCard';

type Props = StateCardProps & {
  onClose: () => void;
  onReset: () => void;
};

export const SuccessState = forwardRef(
  ({ onClose, onReset, ...props }: Props, ref) => {
    const { t } = useTranslation('batchCreate', { keyPrefix: 'success_state' });

    return (
      <StateCard ref={ref} {...props}>
        <Portal appendToParentPortal={false}>
          <Confetti
            gravity={0.1}
            numberOfPieces={100}
            style={{ zIndex: 'calc(var(--chakra-zIndices-modal) + 1)' }}
            colors={[
              '#E62350',
              '#EF6C2A',
              '#F8C83A',
              '#543CDE',
              '#2C88D9',
              '#207868',
              '#BC9055',
            ]}
          />
        </Portal>
        <Text fontSize="48px" lineHeight="1">
          🎉
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {t('title')}
        </Text>
        <Button colorScheme="dark" onClick={onClose}>
          {t('close')}
        </Button>
        <Text>
          {t('or')}{' '}
          <Button
            colorScheme="dark"
            fontSize="md"
            textDecor="underline"
            variant="link"
            onClick={onReset}
          >
            {t('add_more')}
          </Button>
        </Text>
      </StateCard>
    );
  }
);
