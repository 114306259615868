import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { cloneElement } from 'react';
import { FiChevronRight } from 'react-icons/fi';

type Props = Omit<FlexProps, 'title'> & {
  icon: JSX.Element;
  title: string;
  comingSoon?: boolean;
};

export const ImportOption = ({ icon, title, comingSoon, ...props }: Props) => (
  <Box
    _hover={props.onClick ? { bg: 'gray.100' } : undefined}
    as="button"
    cursor={props.onClick ? 'pointer' : 'default'}
    flex="1"
    px={{ base: 4, md: 6 }}
    transition="background-color 0.1s ease"
    w="100%"
    {...props}
  >
    <Flex align="center" flex="1">
      {cloneElement(icon, {
        opacity: comingSoon ? 0.5 : 1,
      })}
      <Box flex="1" px={{ base: 4, md: 6 }} textAlign="left">
        {comingSoon && (
          <Text
            color="pink.500"
            fontSize="xs"
            fontWeight="medium"
            mb="2"
            textAlign="left"
            textTransform="uppercase"
          >
            Coming soon
          </Text>
        )}
        <Box
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight="medium"
          opacity={comingSoon ? 0.5 : 1}
        >
          {title}
        </Box>
      </Box>
      <FiChevronRight size="28" />
    </Flex>
  </Box>
);
