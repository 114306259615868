import { CloseButton, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { HEADER_HEIGHT } from '../../../constants';
import { useEntryModalContext } from '../../../contexts';

type Props = {
  children: ReactNode;
};

export const PanelHeader = ({ children }: Props) => {
  const { closeSidebar, slideSidebarUpFromBottom } = useEntryModalContext();
  const canCloseSidebar = slideSidebarUpFromBottom;

  return (
    <Flex
      align="center"
      borderBottomColor="customgray.light"
      borderBottomWidth="1px"
      flexShrink="0"
      h={HEADER_HEIGHT}
      justify="space-between"
      lineHeight="normal"
      pl="8"
      pr="4"
      w="100%"
    >
      {children}
      {canCloseSidebar && <CloseButton onClick={closeSidebar} />}
    </Flex>
  );
};
