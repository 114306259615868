import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MotionFlex } from '@/components/MotionFlex';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useToggleScheduleHeaderEnabled,
  useSchedulePermissions,
  useToggleScheduleHeaderType,
} from '@/pages/Schedule/hooks';
import { ScheduleHeaderType } from '@/types/gql.generated';
import { AddBannerButton } from './AddBannerButton';
import { BannerButton } from './BannerButton';

export const Banner = () => {
  const { schedule, isPublicRole } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { toggleScheduleHeaderEnabled } = useToggleScheduleHeaderEnabled();
  const { toggleScheduleHeaderType, isPending: isTogglingType } =
    useToggleScheduleHeaderType();
  const toast = useToast();
  const { t } = useTranslation('schedule', { keyPrefix: 'banner' });

  const isEnabled = schedule?.header.enabled ?? false;
  const hasEditPermissions = hasSchedulePermission('SCHEDULE_RENAME');
  const canToggle = hasEditPermissions && Boolean(schedule?.header.id);

  const toggleEnabled = () => {
    toggleScheduleHeaderEnabled(!isEnabled, {
      onError: () =>
        toast.error(isEnabled ? t('error_removing') : t('error_adding')),
    });
  };

  const toggleType = () => {
    const nextType =
      schedule?.header.type === ScheduleHeaderType.Ai
        ? ScheduleHeaderType.Default
        : ScheduleHeaderType.Ai;
    toggleScheduleHeaderType(nextType, {
      onError: () =>
        nextType === ScheduleHeaderType.Ai
          ? t('error_toggling_ai')
          : t('error_toggling_default'),
    });
  };

  if (isPublicRole && !isEnabled) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      {isEnabled ? (
        <MotionFlex
          animate={{ opacity: 1 }}
          backgroundImage={schedule?.header.imageBaseUrl + 'header'}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderTopRadius={{ xl: 'xl' }}
          data-group
          exit={{ opacity: 0 }}
          h="0"
          initial={{ opacity: 0 }}
          key={schedule?.header.imageBaseUrl}
          position="relative"
          pt="calc(600 / 3000 * 100%)"
          w="100%"
        >
          {(hasEditPermissions || canToggle) && (
            <Flex
              bottom={4}
              gap="3"
              justify="center"
              left={0}
              pos="absolute"
              right={0}
            >
              {hasEditPermissions && (
                <BannerButton onClick={toggleEnabled}>
                  {t('remove_action')}
                </BannerButton>
              )}
              {canToggle && (
                <BannerButton isLoading={isTogglingType} onClick={toggleType}>
                  {schedule?.header.type === ScheduleHeaderType.Default
                    ? t('toggle_action_ai')
                    : t('toggle_action_default')}
                </BannerButton>
              )}
            </Flex>
          )}
        </MotionFlex>
      ) : hasEditPermissions ? (
        <AddBannerButton key="disabled" onClick={toggleEnabled} />
      ) : null}
    </AnimatePresence>
  );
};
