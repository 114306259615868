import { Icon, IconProps } from '@chakra-ui/react';
import { useMemo } from 'react';
import SvgLogo from './logo.svg?react';

const WIDTH = 31;
const HEIGHT = 33;
const aspectRatio = WIDTH / HEIGHT;

type Props = Omit<IconProps, 'w' | 'width' | 'h' | 'height'> & {
  width?: number;
  height?: number;
};

export const Logo = ({ width, height, ...props }: Props) => {
  const dimensions = useMemo(() => {
    if (width) {
      return {
        width: `${width}px`,
        height: `calc(${width}px * ${1 / aspectRatio})`,
      };
    }
    if (height) {
      return {
        width: `calc(${height}px * ${aspectRatio})`,
        height: `${height}px`,
      };
    }
    return { width: `${WIDTH}px`, height: `${HEIGHT}px` };
  }, [width, height]);

  return <Icon as={SvgLogo} {...props} {...dimensions} />;
};
