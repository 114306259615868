import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  forwardRef,
  SimpleGrid,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
} from '@chakra-ui/react';
import { getEntryCategoryColors } from '@/pages/Schedule/utils';
import { setAlpha } from '@/utils/colors';
import { COLORS } from '../colors';

const RadioColor = (props: UseRadioProps): JSX.Element => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const colorScheme = getEntryCategoryColors(props.value, 'dark');

  return (
    <Box as="label" bgColor="white" borderRadius="4" cursor="pointer">
      <input {...getInputProps()} />

      <Flex
        {...getRadioProps()}
        align="center"
        bgColor={colorScheme.backgroundColor}
        borderRadius="4"
        borderWidth="1px"
        boxSize="28px"
        justify="center"
        transition="all 0.15s ease"
        _checked={{
          boxShadow: `0 0 5px ${setAlpha(colorScheme.base, 0.2)}`,
          borderColor: 'white',
        }}
        _hover={{
          bg: setAlpha(colorScheme.base, 0.3),
          borderColor: 'rgba(0, 0, 0, .15)',
          _dark: {
            borderColor: 'rgba(255, 255, 255, .25)',
          },
        }}
      >
        <CheckIcon
          boxSize="12px"
          color={colorScheme.base}
          display={props.isChecked ? 'block' : 'none'}
        />
      </Flex>
    </Box>
  );
};

export const ColorInput = forwardRef(
  (props: UseRadioGroupProps, ref): JSX.Element => {
    const { getRootProps, getRadioProps } = useRadioGroup(props);

    return (
      <SimpleGrid
        columns={4}
        display="inline-grid"
        p="1"
        spacing="2"
        {...getRootProps()}
        ref={ref}
      >
        {COLORS.map((color) => {
          return (
            <RadioColor
              key={color.color}
              {...getRadioProps({ value: color.color })}
            />
          );
        })}
      </SimpleGrid>
    );
  }
);
