import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { BiCalendarAlt } from 'react-icons/bi';
import { useItemCardContext } from '../context/useItemCardContext';
import { summarizeTime } from '../utils';

export const Time = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();

  return (
    <Flex
      align="center"
      fontWeight="medium"
      gap="2.5"
      lineHeight="1"
      {...props}
    >
      <Icon
        as={BiCalendarAlt}
        boxSize="18px"
        pos="relative"
        top="-2px"
        w="20px"
      />

      <Text _firstLetter={{ textTransform: 'capitalize' }}>
        {summarizeTime(entry, instance)}
      </Text>
    </Flex>
  );
};
