import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { gqlClient } from '@/lib';
import { PlatformTypes } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  SetPreferredPlatformMutation,
  SetPreferredPlatformMutationVariables,
} from './usePreferredPlatform.generated';

const query = gql`
  mutation SetPreferredPlatform($platform: PlatformTypes) {
    setPreferredPlatform(preferredPlatform: $platform) {
      preferredPlatform
    }
  }
`;

export const useSetPreferredPlatform = () => {
  const { mutate, ...rest } = useMutation<
    SetPreferredPlatformMutation,
    QueryError,
    SetPreferredPlatformMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
  });
  const { invalidate: invalidateProfile } = useCurrentUserContext();

  const setPreferredPlatform = useCallback(
    (
      platform: PlatformTypes | null,
      options: MutateOptions<
        SetPreferredPlatformMutation,
        QueryError,
        SetPreferredPlatformMutationVariables
      > = {}
    ) => {
      return mutate(
        { platform },
        {
          ...options,
          onSuccess: (...args) => {
            invalidateProfile();
            options.onSuccess?.(...args);
          },
        }
      );
    },
    [mutate, invalidateProfile]
  );

  return {
    setPreferredPlatform,
    ...rest,
  };
};
