import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import {
  CopyPublicScheduleMutation,
  CopyPublicScheduleMutationVariables,
} from './useCopyPublicSchedule.generated';

const query = gql`
  mutation CopyPublicSchedule($scheduleId: ID!) {
    copyPublicSchedule(scheduleId: $scheduleId)
  }
`;

export const useCopyPublicSchedule = () => {
  const { mutate, isPending } = useMutation<
    CopyPublicScheduleMutation,
    QueryError,
    CopyPublicScheduleMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        CopyPublicScheduleMutation,
        CopyPublicScheduleMutationVariables
      >(query, variables);
    },
  });

  const copySchedule = (
    scheduleId: string,
    options?: MutateOptions<
      CopyPublicScheduleMutation,
      QueryError,
      CopyPublicScheduleMutationVariables
    >
  ) => {
    const variables = { scheduleId };
    return mutate(variables, options);
  };

  return { copySchedule, isPending };
};
