import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { TbNotes } from 'react-icons/tb';
import { MemberFieldType } from '@/types/gql.generated';
import { Invite } from '../hooks/useEntryInvites';

type Props = {
  invites: Invite[];
};

export const InviteList = ({ invites }: Props) => {
  return invites.map((invite) => {
    const name = invite.memberSubmission?.[0].value;
    const phone = invite.memberSubmission?.find(
      (field) => field.type === MemberFieldType.PhoneNumber
    )?.value;

    return (
      <Flex
        _last={{ pb: 2 }}
        align="center"
        gap="4"
        justify="space-between"
        key={invite.id}
      >
        {invite.memberSubmission ? (
          <Box lineHeight="normal">
            {name && (
              <Text fontWeight="medium" noOfLines={1}>
                {name}
              </Text>
            )}
            <Text
              color="customgray.mid"
              fontSize="sm"
              mb="1.5"
              mt="0.5"
              noOfLines={1}
            >
              {invite.email}
            </Text>
            {phone && (
              <Text color="customgray.mid" fontSize="sm">
                {phone}
              </Text>
            )}
          </Box>
        ) : (
          <Text fontWeight="medium" noOfLines={1}>
            {invite.email}
          </Text>
        )}

        {invite.notes && (
          <Tooltip label={invite.notes}>
            <Box lineHeight="normal">
              <Icon as={TbNotes} boxSize="18px" color="customgray.mid" />
            </Box>
          </Tooltip>
        )}
      </Flex>
    );
  });
};
