import {
  ModalContent as ChakraModalContent,
  ModalContentProps,
} from '@chakra-ui/react';
import { MotionFlex } from '@/components/MotionFlex';

type Props = ModalContentProps & {
  pollJobSuccess: boolean;
};

export const ModalContent = ({ pollJobSuccess, ...props }: Props) => {
  return (
    <ChakraModalContent
      as={MotionFlex}
      borderRadius={{ base: 0, md: '2xl' }}
      m="0"
      opacity="1"
      overflowY={pollJobSuccess ? 'hidden' : 'initial'}
      transitionDuration="0.5s"
      transitionProperty="max-width, height"
      transitionTimingFunction="ease-in-out"
      maxH={{
        base: 'var(--app-height)',
        md: pollJobSuccess ? 'calc(100vh - var(--chakra-space-20))' : 'none',
      }}
      maxW={{
        md: pollJobSuccess ? 'calc(100vw - var(--chakra-space-20))' : '700px',
        xl: pollJobSuccess ? 'calc(1400px - var(--chakra-space-20))' : '700px',
      }}
      minH={{
        base: 'var(--app-height)',
        md: 'auto',
      }}
      minW={{
        base: '100vw',
        md: 'auto',
      }}
      sx={{
        // the modal in the success state is always 900px tall unless
        // the screen is < 900px tall, at which point it's 100%
        // of the screen height with gutters. but only on desktop.
        // `min-width: 768px` is the `md` breakpoint (can't use css
        // vars in media queries)
        '@media (min-width: 768px) and (min-height: 900px)': {
          h: pollJobSuccess ? '900px' : '564px',
        },
        '@media (min-width: 768px) and (max-height: 900px)': {
          h: pollJobSuccess
            ? 'calc(var(--app-height) - var(--chakra-space-20))'
            : '564px',
        },
      }}
      {...props}
    />
  );
};
