import { Flex, Icon, FlexProps, Text } from '@chakra-ui/react';
import { BiMap } from 'react-icons/bi';
import { useItemCardContext } from '../context/useItemCardContext';

export const Location = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();
  const locationWithPlace =
    instance?.locationWithPlace ?? entry.locationWithPlace;

  if (!locationWithPlace?.name) {
    return null;
  }

  return (
    <Flex align="flex-start" gap="2.5" {...props}>
      {/* Icon is tactically aligned to always appear centered with the first line of text even if the location wraps */}
      <Icon as={BiMap} boxSize="20px" pos="relative" top="1px" />

      <Text
        lineHeight="22px"
        overflowWrap="anywhere"
        {...(locationWithPlace?.googlePlaceId &&
          locationWithPlace.name && {
            as: 'a',
            target: '_blank',
            textDecor: 'underline',
            href: `https://maps.google.com/maps?q=${encodeURIComponent(
              locationWithPlace.name
            )}`,
          })}
      >
        {locationWithPlace?.name}
      </Text>
    </Flex>
  );
};
