import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  createScheduleCollaboratorsQueryKey,
  createScheduleMembersQueryKey,
  createProfileQueryKey,
} from '@/utils/queryKeys';
import {
  RemoveCollaboratorMutation,
  RemoveCollaboratorMutationVariables,
} from './useRemoveCollaborator.generated';

const query = gql`
  mutation RemoveCollaborator($scheduleId: ID!, $userId: ID!) {
    removeSchedulePermission(scheduleId: $scheduleId, userId: $userId)
  }
`;

export const useRemoveCollaborator = () => {
  const queryClient = useQueryClient();
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    RemoveCollaboratorMutation,
    QueryError,
    RemoveCollaboratorMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        RemoveCollaboratorMutation,
        RemoveCollaboratorMutationVariables
      >(query, variables);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: createScheduleCollaboratorsQueryKey(variables.scheduleId),
      });
      queryClient.invalidateQueries({
        queryKey: createScheduleMembersQueryKey(variables.scheduleId),
      });
      queryClient.invalidateQueries({ queryKey: createProfileQueryKey() });
    },
  });

  const removeCollaborator = (
    userId: string,
    options?: MutateOptions<
      RemoveCollaboratorMutation,
      QueryError,
      RemoveCollaboratorMutationVariables
    >
  ) => mutate({ scheduleId, userId }, options);

  return {
    removeCollaborator,
    isPending,
  };
};
