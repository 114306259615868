import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/hooks/useToast';
import { ConfirmDialog } from '@/pages/Schedule/components/ConfirmDialog';
import { useDeleteEntry } from '@/pages/Schedule/hooks';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { isRecurringEntry } from '@/pages/Schedule/utils';
import { useIsColumnLayout } from '../../../hooks/useIsColumnLayout';
import { useJobCache } from '../../../hooks/useJobCache';
import { Job } from '../../../types';
import { Panel } from '../Panel';
import { EntryCard } from './EntryCard';
import { HeaderCard } from './HeaderCard';

type Props = {
  job: Job;
  isPublishLoading: boolean;
  onPublish: () => void;
};

export const ResultsPanel = ({ job, isPublishLoading, onPublish }: Props) => {
  const entries = job.entries ?? [];
  const { deleteEntry, isPending: isDeleting } = useDeleteEntry();
  const editDraftEntry = useDraftEntryStore((store) => store.editDraftEntry);
  const toast = useToast();
  const { isColumnLayout } = useIsColumnLayout();
  const { t } = useTranslation('batchCreate');
  const cache = useJobCache(job.id);
  const [confirmDeleteEntry, setConfirmDeleteEntry] =
    useState<DecoratedEntry | null>(null);

  const handleDelete = () => {
    if (confirmDeleteEntry) {
      deleteEntry(confirmDeleteEntry.id, {
        onError: () => toast.error(t('delete_error')),
        onSuccess: () => {
          cache.deleteEntry(confirmDeleteEntry.id);
          setConfirmDeleteEntry(null);
        },
      });
    }
  };

  const handleMessagesClick = (entry: DecoratedEntry) => {
    const isRecurring = isRecurringEntry(entry);

    editDraftEntry(entry, undefined, {
      launchAction: isRecurring ? undefined : 'create-message',
    });
  };

  return (
    <>
      <ConfirmDialog
        isLoading={isDeleting}
        isOpen={!!confirmDeleteEntry}
        title={t('delete_confirm', { name: confirmDeleteEntry?.title })}
        onCancel={() => setConfirmDeleteEntry(null)}
        onConfirm={handleDelete}
      />

      <Panel
        gap="3"
        overflowY={isColumnLayout ? 'initial' : 'auto'}
        pos="relative"
      >
        <HeaderCard
          count={entries.length}
          isPublishLoading={isPublishLoading}
          onPublish={onPublish}
        />
        {entries.map((entry) => (
          <EntryCard
            entry={entry}
            key={entry.id}
            onDeleteClick={setConfirmDeleteEntry}
            onEditClick={editDraftEntry}
            onMessagesClick={handleMessagesClick}
          />
        ))}
      </Panel>
    </>
  );
};
