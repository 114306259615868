import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { ProfileFragment } from '@/fragments';
import { gqlClient, queryClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import {
  createEntriesQueryKey,
  createProfileQueryKey,
} from '@/utils/queryKeys';
import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from './useUpdateProfile.generated';

const query = gql`
  ${ProfileFragment}
  mutation UpdateProfile(
    $name: String!
    $replaceAvatar: Boolean!
    $removeAvatar: Boolean!
    $updatedAvatar: Upload!
  ) {
    updateProfile(name: $name) {
      ...Profile
    }
    uploadProfileImage(image: $updatedAvatar) @include(if: $replaceAvatar)
    removeProfileImage @include(if: $removeAvatar)
  }
`;

export const useUpdateProfile = () => {
  const { setUser } = useCurrentUserContext();

  const { mutate, ...result } = useMutation<
    UpdateProfileMutation,
    QueryError,
    UpdateProfileMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        UpdateProfileMutation,
        UpdateProfileMutationVariables
      >(query, variables);
    },
    onSuccess: (result) => {
      setUser({
        ...result?.updateProfile,
        avatar:
          result.uploadProfileImage ?? result.updateProfile.avatar ?? null,
      });

      // Ensure profile updates are propagated to avatars
      queryClient.refetchQueries({ queryKey: createProfileQueryKey() });
      queryClient.invalidateQueries({ queryKey: createEntriesQueryKey() });
    },
  });

  const saveProfile = (
    name: string,
    avatar: File | string | null,
    options?: MutateOptions<
      UpdateProfileMutation,
      QueryError,
      UpdateProfileMutationVariables
    >
  ) => {
    return mutate(
      {
        name,
        updatedAvatar: avatar instanceof File ? avatar : new Blob(),
        replaceAvatar: avatar instanceof File,
        removeAvatar: !avatar,
      },
      options
    );
  };

  return {
    ...result,
    saveProfile,
  };
};
