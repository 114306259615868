import { queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createPollEntriesJobStatusQueryKey } from '@/utils/queryKeys';
import { PollEntriesJobStatusQuery } from './usePollEntriesJobStatus.generated';

export const useJobCache = (entriesJobId: string) => {
  const { scheduleId } = useScheduleContext();

  const deleteEntry = (entryId: string) => {
    queryClient.setQueriesData<PollEntriesJobStatusQuery | undefined>(
      {
        queryKey: createPollEntriesJobStatusQueryKey(scheduleId, entriesJobId),
      },
      (data) => {
        if (!data || !data.getEntriesJobForSchedule.entries) {
          return data;
        }
        return {
          ...data,
          getEntriesJobForSchedule: {
            ...data.getEntriesJobForSchedule,
            entries: data.getEntriesJobForSchedule.entries.filter(
              (entry) => entry.id !== entryId
            ),
          },
        };
      }
    );
  };

  return {
    deleteEntry,
  };
};
