export const SchedulesWithCustomCTA = {
  BEAUTY_AND_BEAST_PLAY: '6f3233f9-b714-4c05-bdb4-86706f0038d0',
  SAN_DIMAS_STARS: 'ea10b9ad-5d5b-4f4b-9fd2-858667578bec',
  SF_AI: '0f8899a0-3dbc-4d6a-ad05-58225b751316',
  FAIR_PLAY: '971a8cbe-f244-49d5-af2c-3ea2d53d303c',
  ELF_ON_A_SHELF: 'e9abe692-2d52-4a1d-8853-f45d18effeaa',
  ELF_MAINSTAGE_FAMILY_SCHEDULE: 'a93a99ac-d8d1-473e-9ca2-c7e6d8389422',
  MRS_B_TARAS_NANNY_SITTING: '6d8078e1-dbef-4591-ad8d-d578cb488501',
  MRS_B_TARAS_NANNY_GIFT_WRAPPING: '8ea444a3-daa6-4f58-b408-f447c6638b8d',
} as const;
