import { Flex, FlexProps } from '@chakra-ui/react';
import { ScheduleHeader } from '../ScheduleHeader';

type Props = FlexProps & {
  header?: JSX.Element | null;
};

export const ScheduleLayout = ({
  header = <ScheduleHeader />,
  children,
  ...props
}: Props) => {
  return (
    <Flex bg="customgray.bglight" direction="column" flex="1" {...props}>
      {header}
      {children}
    </Flex>
  );
};
