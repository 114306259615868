import { Select, SelectProps, forwardRef } from '@chakra-ui/react';
import { RawTimeZone, rawTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';

type RawTimeZoneWithOffset = RawTimeZone & {
  offset: string;
};

const precomputedTimeZones: RawTimeZoneWithOffset[] = rawTimeZones.map(
  (zone) => ({
    ...zone,
    offset: DateTime.local({ zone: zone.name }).toFormat('ZZ'),
  })
);

const createOption = (zone: RawTimeZoneWithOffset) => (
  <option key={zone.name} value={zone.name}>
    (GMT{zone.offset}) {zone.alternativeName} &ndash; {zone.mainCities[0]}
  </option>
);

export const TimeZoneSelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Select ref={ref} {...props}>
      <optgroup label="United States">
        {precomputedTimeZones
          .filter((zone) => zone.countryCode === 'US')
          .map(createOption)}
      </optgroup>
      <optgroup label="All time zones">
        {precomputedTimeZones.map(createOption)}
      </optgroup>
    </Select>
  );
});
