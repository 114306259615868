import {
  forwardRef,
  Popover as ChakraPopover,
  PopoverContent as ChakraPopoverContent,
  PopoverProps as ChakraPopoverProps,
  PopoverContentProps,
  useMergeRefs,
  usePopoverContext,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef } from 'react';

export type PopoverProps = Omit<ChakraPopoverProps, 'closeOnBlur'>;

/**
 * A wrapper around Chakra's `Popover` component that fixes
 * the `closeOnBlur` implementation.
 */
export const Popper = (props: PopoverProps) => {
  return <ChakraPopover {...props} closeOnBlur={false} />;
};

export const PopoverContent = forwardRef((props: PopoverContentProps, ref) => {
  const internalRef = useRef<HTMLElement>(null);
  const refs = useMergeRefs(ref, internalRef);
  const popover = usePopoverContext();
  const id = popover.getTriggerProps().id;

  useOutsideClick({
    ref: internalRef,
    handler: (event) => {
      const target = event.target as HTMLElement;
      const isTriggerButton = target.closest(`[id="${id}"]`);

      // If the click happened on the trigger button _for this popover_,
      // ignore and delegate closing back to Chakra. Otherwise Chakra
      // will toggle and we'll toggle and the popover will remain open
      if (!isTriggerButton) {
        popover.onClose();
      }
    },
  });

  return <ChakraPopoverContent ref={refs} {...props} />;
});

export {
  PopoverTrigger,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverFooter,
  type PopoverContentProps,
  type PopoverBodyProps,
} from '@chakra-ui/react';
