import { Flex, FlexProps } from '@chakra-ui/react';

export const Content = (props: FlexProps) => {
  return (
    <Flex
      direction="column"
      gap="4"
      px={{ base: '20px', md: '24px' }}
      {...props}
    />
  );
};
