import { Link, Image, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { QuickEntriesJobFile } from '@/types/gql.generated';
import { isPDF } from '@/utils/file';
import { PdfRenderer } from './PdfRenderer';

type Props = {
  file: File | QuickEntriesJobFile;
  pdfWidth: number;
  imageWidth?: number;
};

export const FilePreview = ({ file, pdfWidth, imageWidth }: Props) => {
  const src = useMemo(() => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file.url;
  }, [file]);

  return (
    <Flex justify="center" overflow="hidden">
      {isPDF(file) ? (
        <PdfRenderer file={file} width={pdfWidth} />
      ) : (
        <Link
          display="flex"
          href={src}
          justifyContent="center"
          maxW={imageWidth ? `${imageWidth}px` : '100%'}
          target="_blank"
        >
          <Image objectFit="contain" src={src} />
        </Link>
      )}
    </Flex>
  );
};
