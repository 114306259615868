import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { RsvpSettingsInput } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  UpdateRsvpSettingsMutation,
  UpdateRsvpSettingsMutationVariables,
} from './useUpdateRsvpSettings.generated';

const query = gql`
  mutation UpdateRsvpSettings($scheduleId: ID!, $settings: RsvpSettingsInput!) {
    updateSchedule(scheduleId: $scheduleId, rsvpSettings: $settings) {
      rsvpSettings {
        question
        yesLabel
        noLabel
      }
    }
  }
`;

export const useUpdateRsvpSettings = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    UpdateRsvpSettingsMutation,
    QueryError,
    UpdateRsvpSettingsMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateRsvpSettingsMutation,
        UpdateRsvpSettingsMutationVariables
      >(query, variables),
  });

  const updateRsvpSettings = useCallback(
    (
      settings: RsvpSettingsInput,
      options?: MutateOptions<
        UpdateRsvpSettingsMutation,
        QueryError,
        UpdateRsvpSettingsMutationVariables
      >
    ) => {
      return mutate({ scheduleId, settings }, options);
    },
    [mutate, scheduleId]
  );

  return {
    ...rest,
    updateRsvpSettings,
  };
};
