import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { ProfileFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { getLocalTimeZone } from '@/utils/dates';
import { QueryError } from '@/utils/errors';
import {
  RegisterMutation,
  RegisterMutationVariables,
} from './useRegister.generated';

const query = gql`
  ${ProfileFragment}
  mutation Register(
    $name: String
    $email: LowercaseString!
    $password: String!
    $timeZone: TimeZone
    $campaign: String
  ) {
    register(
      name: $name
      email: $email
      password: $password
      timeZone: $timeZone
      campaign: $campaign
    ) {
      ...Profile
      schedules {
        id
      }
    }
  }
`;

export const useRegister = () => {
  const { setUser } = useCurrentUserContext();

  const { mutate, ...result } = useMutation<
    RegisterMutation,
    QueryError,
    RegisterMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<RegisterMutation, RegisterMutationVariables>(
        query,
        variables
      ),
  });

  const register: typeof mutate = (variables, options) => {
    mutate(
      {
        ...variables,
        timeZone: variables.timeZone ?? getLocalTimeZone(),
      },
      {
        ...options,
        onSuccess: (data, ...args) => {
          setUser(data.register);
          options?.onSuccess?.(data, ...args);
        },
      }
    );
  };

  return {
    ...result,
    register,
  };
};
