import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  RequestScheduleAccessMutation,
  RequestScheduleAccessMutationVariables,
} from './useRequestAccess.generated';

const query = gql`
  mutation RequestScheduleAccess($scheduleId: ID!) {
    requestScheduleAccess(scheduleId: $scheduleId)
  }
`;

export const useRequestAccess = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    RequestScheduleAccessMutation,
    QueryError,
    RequestScheduleAccessMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        RequestScheduleAccessMutation,
        RequestScheduleAccessMutationVariables
      >(query, variables),
  });

  const requestAccess = (
    options?: MutateOptions<
      RequestScheduleAccessMutation,
      QueryError,
      RequestScheduleAccessMutationVariables
    >
  ) => {
    mutate({ scheduleId }, options);
  };

  return {
    ...rest,
    requestAccess,
  };
};
