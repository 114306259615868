import { Flex, Spinner } from '@chakra-ui/react';
import { Suspense, forwardRef, lazy } from 'react';
import { TelephoneInputProps } from './types';

const LazyTelephoneInput = lazy(() => import('./TelephoneInput'));

export const TelephoneInput = forwardRef<HTMLInputElement, TelephoneInputProps>(
  function TelephoneInputWithRef(props: TelephoneInputProps, ref) {
    return (
      <Suspense
        fallback={
          // size 10 is the default input height
          <Flex align="center" h="10">
            <Spinner size="sm" />
          </Flex>
        }
      >
        <LazyTelephoneInput {...props} ref={ref} />
      </Suspense>
    );
  }
);
