import { Box, Flex, FlexProps, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = FlexProps & {
  title?: string;
  children?: ReactNode;
};

export const AuthHeader = ({ title, children, ...props }: Props) => {
  if (!title && !children) {
    return null;
  }
  return (
    <Flex align="center" direction="column" gap={children ? 0.5 : 0} {...props}>
      {title && <Heading fontSize="3xl">{title}</Heading>}
      {children && <Box>{children}</Box>}
    </Flex>
  );
};
