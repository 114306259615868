import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  CreateEntriesJobFromPromptMutation,
  CreateEntriesJobFromPromptMutationVariables,
} from './useCreateEntriesJobFromPrompt.generated';

const query = gql`
  mutation CreateEntriesJobFromPrompt(
    $prompt: String!
    $scheduleId: ID!
    $createDrafts: Boolean
  ) {
    createEntriesJobFromPrompt(
      prompt: $prompt
      scheduleId: $scheduleId
      createDrafts: $createDrafts
    ) {
      id
    }
  }
`;

export const useCreateEntriesJobFromPrompt = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    CreateEntriesJobFromPromptMutation,
    QueryError,
    CreateEntriesJobFromPromptMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateEntriesJobFromPromptMutation,
        CreateEntriesJobFromPromptMutationVariables
      >(query, variables),
  });

  const createEntriesJobFromPrompt = useCallback(
    (
      prompt: string,
      options?: MutateOptions<
        CreateEntriesJobFromPromptMutation,
        QueryError,
        CreateEntriesJobFromPromptMutationVariables
      >
    ) => mutate({ prompt, scheduleId, createDrafts: true }, options),
    [mutate, scheduleId]
  );

  return { createEntriesJobFromPrompt, ...rest };
};
