import { ForwardedRef, forwardRef, useLayoutEffect, useState } from 'react';
import ReactTextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

// Component exists to fix this bug:
// https://github.com/Andarist/react-textarea-autosize/issues/337
const Component = (
  props: TextareaAutosizeProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const [, setIsRerendered] = useState(false);
  useLayoutEffect(() => setIsRerendered(true), []);
  return <ReactTextareaAutosize {...props} ref={ref} />;
};

export const TextareaAutosize = forwardRef(Component);
