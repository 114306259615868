import { Box, BoxProps, Flex, CloseButton, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsLightningCharge, BsTag } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useScheduleFilters } from '@/pages/Schedule/contexts';
import { getEntryCategoryColors } from '@/pages/Schedule/utils/entry';
import { setAlpha } from '@/utils/colors';

type Props = {
  onClick: () => void;
  onClear: () => void;
};

const CategoryDot = (props: BoxProps) => (
  <Box borderRadius="50%" h="10px" w="10px" {...props} />
);

export const ButtonWithFilters = forwardRef(
  ({ onClick, onClear }: Props, ref) => {
    const isMobileBreakpoint = useIsMobileBreakpoint();
    const { t } = useTranslation('filterPopover');
    const {
      filteredCategories,
      filteredWhoLabels,
      filteredLabels,
      filteredFeeds,
    } = useScheduleFilters();

    return (
      <Flex
        align="center"
        borderRadius="50px"
        data-group
        fontSize="sm"
        fontWeight="medium"
        h="42px"
        overflow={isMobileBreakpoint ? 'visible' : 'hidden'}
        ref={ref}
      >
        <Flex
          _hover={{ borderColor: 'gray.400' }}
          align="center"
          borderColor="customgray.gray"
          borderLeftRadius="full"
          borderWidth="1px 0 1px 1px"
          gap="6"
          h="100%"
          lineHeight="normal"
          minW="0"
          overflow={isMobileBreakpoint ? 'visible' : 'hidden'}
          pl="5"
          pr="4"
          role="button"
          onClick={onClick}
        >
          {filteredCategories.length > 0 && (
            <Flex align="center" gap="2">
              {filteredCategories.map((category) => {
                const colors = getEntryCategoryColors(category.color);
                const bg = setAlpha(colors.base, 0.3);

                return (
                  <Flex align="center" gap="2" key={category.id} minW="0">
                    <CategoryDot bg={bg} flexShrink="0" />
                    {category.text && (
                      <Box
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {category.text}
                      </Box>
                    )}
                  </Flex>
                );
              })}
            </Flex>
          )}

          {filteredWhoLabels.length > 0 && (
            <Flex align="center" gap="2" minW="50px">
              <Box flexShrink="0">
                <FaRegUser />
              </Box>
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {filteredWhoLabels.map((label) => label.text).join(', ')}
              </Box>
            </Flex>
          )}

          {filteredLabels.length > 0 && (
            <Flex align="center" gap="2" minW="50px">
              <Box flexShrink="0">
                <BsTag />
              </Box>
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {filteredLabels.map((label) => label.text).join(', ')}
              </Box>
            </Flex>
          )}

          {filteredFeeds.length > 0 && (
            <Flex align="center" gap="2" minW="0">
              <Box flexShrink="0">
                <BsLightningCharge />
              </Box>
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {filteredFeeds.map((feed) => feed.title).join(', ')}
              </Box>
            </Flex>
          )}
        </Flex>

        <Box
          _groupHover={{ bg: 'gray.400' }}
          bg="customgray.gray"
          h="100%"
          w="1px"
        />

        <CloseButton
          aria-label={t('clear_button_aria')}
          borderColor="customgray.gray"
          borderLeftWidth="0"
          borderRightRadius="full"
          borderWidth="1px 1px 1px 0"
          color="customgray.mid"
          h="100%"
          pl="4"
          pr="5"
          size="sm"
          _hover={{
            color: 'customgray.dark',
            borderColor: 'gray.400',
          }}
          onClick={onClear}
        />
      </Flex>
    );
  }
);
