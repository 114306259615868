import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';

import { useScheduleCache } from '@/pages/Schedule/hooks';
import { LabelType, Label } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import { definitions as labelDefinitions } from '../../../utils/labelDefinitions';
import {
  CreateLabelMutation,
  CreateLabelMutationVariables,
} from './useCreateLabel.generated';

const query = gql`
  mutation CreateLabel(
    $scheduleId: ID!
    $labelType: LabelType!
    $labelId: ID
    $text: String!
  ) {
    createLabel(
      scheduleId: $scheduleId
      type: $labelType
      labelId: $labelId
      text: $text
    ) {
      id
      text
    }
  }
`;

export const useCreateLabel = (labelType: LabelType) => {
  const labelDefinition = labelDefinitions[labelType];
  const { scheduleId, schedule } = useScheduleContext();
  const { addLabel, updateLabel, deleteLabel } = useScheduleCache();
  const nextSortOrder = schedule?.[labelDefinition.scheduleKey].length ?? 0;

  const { mutate } = useMutation<
    CreateLabelMutation,
    QueryError,
    CreateLabelMutationVariables,
    { labelId: string }
  >({
    mutationFn: (variables) =>
      gqlClient.request<CreateLabelMutation, CreateLabelMutationVariables>(
        query,
        variables
      ),
    onMutate: (variables) => {
      if (variables.labelId) {
        addLabel(variables.labelType, {
          id: variables.labelId,
          text: variables.text,
          sortOrder: nextSortOrder,
        });
        return { labelId: variables.labelId };
      }
    },
    onSuccess: (mutation) => {
      const result = mutation.createLabel;
      if (result) {
        const label = {
          ...result,
          // Sort order always comes back as 0 unless it's explicitly overridden
          sortOrder: nextSortOrder,
        };
        updateLabel(labelType, label);
      }
    },
    onError: (err, data, context) => {
      if (context?.labelId) {
        deleteLabel(labelType, context.labelId);
      }
    },
  });

  const createLabel = useCallback(
    (
      label: Label,
      options?: MutateOptions<
        CreateLabelMutation,
        QueryError,
        CreateLabelMutationVariables
      >
    ) => {
      return mutate(
        {
          scheduleId,
          labelType,
          labelId: label.id,
          text: label.text,
        },
        options
      );
    },
    [mutate, scheduleId, labelType]
  );

  return {
    createLabel,
  };
};
