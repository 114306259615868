import { Button, IconButton, IconButtonProps, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Trigger } from './Trigger';

type Props = {
  onClick: () => void;
  buttonComponent?: JSX.Element;
} & Omit<IconButtonProps, 'aria-label' | 'onClick'>;

export const CreateButton = forwardRef(function BatchCreateButtonWithRef(
  { buttonComponent, ...buttonProps }: Props,
  ref
) {
  const { t } = useTranslation('batchCreate');
  const isMobileBreakpoint = useIsMobileBreakpoint();

  if (buttonComponent) {
    return <Trigger {...buttonProps}>{buttonComponent}</Trigger>;
  }

  if (isMobileBreakpoint) {
    return (
      <IconButton
        aria-label={t('trigger_button')}
        borderRadius="full"
        colorScheme="dark"
        icon={<AiOutlinePlus color="white" size="19" />}
        {...buttonProps}
      />
    );
  }

  return (
    <Button
      _focus={{ borderColor: 'yellow.400' }}
      _focusVisible={{ borderColor: 'yellow.400' }}
      _hover={{ borderColor: 'yellow.400' }}
      colorScheme="dark"
      leftIcon={<Text lineHeight="normal">✨</Text>}
      ref={ref}
      {...buttonProps}
    >
      {t('trigger_button')}
    </Button>
  );
});
