import { Flex, FlexProps } from '@chakra-ui/react';
import { useItemCardContext } from '../../context/useItemCardContext';
import { Category } from '../Category';
import { Label } from './Label';

export const Labels = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();

  const category = instance?.category || entry.category;
  const labels = instance?.labels || entry.labels;
  const whoLabels = instance?.whoLabels || entry.whoLabels;

  const hasCategoryOrLabels =
    Boolean(category) || labels.length > 0 || whoLabels.length > 0;

  if (!hasCategoryOrLabels) {
    return null;
  }

  return (
    <Flex align="center" display="inline-flex" gap="2" wrap="wrap" {...props}>
      {category && <Category category={category} />}
      {labels.map((label) => (
        <Label key={label.id}>{label.text}</Label>
      ))}
      {whoLabels.map((label) => (
        <Label key={label.id} type="whoLabel">
          {label.text}
        </Label>
      ))}
    </Flex>
  );
};
