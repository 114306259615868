export const colors = {
  gradients: {
    magic: {
      text: 'linear-gradient(to right, #685EDB, #D5257C)',
    },
  },
  brand: {
    40: '#fae5f0',
    50: '#f3bad6',
    100: '#eb8ebc',
    200: '#e878af',
    300: '#e463a3',
    400: '#e04d96',
    500: '#D5257C',
    600: '#aa1d63',
    700: '#941a56',
    800: '#7e1649',
    900: '#530e30',
  },
  gray: {
    50: '#fbfbfb',
    100: '#f3f3f3',
    200: '#e6e6e6',
    300: '#d9d9d9',
    400: '#cccccc',
    500: '#b3b3b3',
    600: '#9a9a9a',
    700: '#8d8d8d',
    800: '#808080',
    900: '#666666',
  },
  customgray: {
    bglight: '#F7F8FA',
    lightest: '#F4F4F4',
    light: '#e5e5e5',
    alsolight: '#b5b6b5',
    itsalildark: '#9E9E9E',

    gray: '#E7E7E7',
    mid: '#9A9A9A',
    middark: '#555555',
    dark: '#464646',
    darker2: '#3C3C3C',
    darker: '#313131',
    alsodarker: '#2D2D2D',
    textdark: '#222222',
    evendarker: '#1E1E1E',
  },
  dark: {
    50: '#959388',
    100: '#7b796e',
    200: '#6e6c62',
    300: '#605f56',
    400: '#53514a',
    500: '#383732',
    600: '#1d1d1a',
    700: '#100f0e',
    800: '#020202',
    900: '#000000',
  },
};
