import { useQueryNormalizer } from '@normy/react-query';
import { QueryKey } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { queryClient } from '@/lib/query-client';
import { LabelType } from '@/types/gql.generated';
import {
  createEntriesQueryKey,
  parseCreateEntriesQueryKey,
} from '@/utils/queryKeys';
import { useScheduleContext } from '../../../ScheduleContext';
import { RawEntry } from '../../types';
import { GetScheduleEntriesQuery } from '../useEntries.generated';
import { isEntryInsideDateRange } from './utils';

export type EntriesCacheItem = {
  queryKey: QueryKey;
  data: GetScheduleEntriesQuery | undefined;
};

export const useEntriesCache = () => {
  const { scheduleId } = useScheduleContext();
  const allCachesQueryKey = createEntriesQueryKey(scheduleId);
  const queryNormalizer = useQueryNormalizer();

  /** Return a list of all the caches where `entryId` exists */
  const getCachesHoldingEntry = useCallback(
    (entryId: string) => {
      return queryClient
        .getQueriesData<GetScheduleEntriesQuery>({
          queryKey: allCachesQueryKey,
        })
        .reduce<EntriesCacheItem[]>((acc, [queryKey, data]) => {
          const match = data?.getSchedule.entries.find(
            (entry) => entry.id === entryId
          );
          if (match) {
            acc.push({ queryKey, data });
          }
          return acc;
        }, []);
    },
    [allCachesQueryKey]
  );

  /**
   * Return a list of all the caches that match `entry`'s attributes
   *
   * TODO This function has some limitations:
   * 1. Filters - if the schedule has filters applied and a new item is created
   *    without those filters, the item will appear on the schedule briefly
   *    until data is refreshed.
   * 2. Recurrence - entries that are recurring will only be inserted into the
   *    cache that matches the entries date range. The server is smart enough
   *    to calculate the correct date range for the entry, but the client is not.
   */
  const getCachesMatchingEntry = useCallback(
    (entry: RawEntry) => {
      return queryClient
        .getQueriesData<GetScheduleEntriesQuery>({
          queryKey: allCachesQueryKey,
        })
        .reduce<EntriesCacheItem[]>((acc, [queryKey, data]) => {
          const { dateRange } = parseCreateEntriesQueryKey(queryKey);

          if (dateRange && isEntryInsideDateRange(entry, dateRange)) {
            acc.push({ queryKey, data });
          }

          return acc;
        }, []);
    },
    [allCachesQueryKey]
  );

  /**
   * Insert an entry into whichever active cache(s) match its attributes.
   */
  const insert = useCallback(
    (entryToInsert: RawEntry) => {
      getCachesMatchingEntry(entryToInsert).forEach(({ queryKey }) => {
        queryClient.setQueriesData<GetScheduleEntriesQuery | undefined>(
          { queryKey },
          (data) => {
            if (!data) {
              return data;
            }
            return {
              ...data,
              getSchedule: {
                ...data.getSchedule,
                entries: data.getSchedule.entries.concat(entryToInsert),
              },
            };
          }
        );
      });
    },
    [getCachesMatchingEntry]
  );

  /** Update an entry in all caches where it exists */
  const update = useCallback(
    (entry: RawEntry) => queryNormalizer.setNormalizedData(entry),
    [queryNormalizer]
  );

  /** Remove `entryId` from all the caches it exists in */
  const remove = useCallback(
    (entryId: string) => {
      queryClient.setQueriesData<GetScheduleEntriesQuery | undefined>(
        { queryKey: allCachesQueryKey },
        (data) => {
          if (!data) {
            return data;
          }
          return {
            ...data,
            getSchedule: {
              ...data.getSchedule,
              entries: data.getSchedule.entries.filter(
                (entry) => entry.id !== entryId
              ),
            },
          };
        }
      );
    },
    [allCachesQueryKey]
  );

  const deleteLabel = useCallback(
    (labelType: LabelType, labelId: string) => {
      queryClient.setQueriesData<GetScheduleEntriesQuery | undefined>(
        { queryKey: allCachesQueryKey },
        (data) => {
          if (!data) {
            return data;
          }
          return {
            ...data,
            getSchedule: {
              ...data.getSchedule,
              entries: data.getSchedule.entries.map((entry) => {
                const property =
                  labelType === LabelType.Default ? 'labels' : 'whoLabels';
                const labels = entry[property];
                const hasLabelToDelete = labels.find(
                  ({ id }) => id === labelId
                );

                if (!hasLabelToDelete) {
                  // Maintain referential integrity if the entry doesn't have the label
                  return entry;
                }

                return {
                  ...entry,
                  [property]: entry[property].filter(
                    (label) => label.id !== labelId
                  ),
                };
              }),
            },
          };
        }
      );
    },
    [allCachesQueryKey]
  );

  return useMemo(
    () => ({
      insert,
      update,
      remove,
      deleteLabel,
      getCachesHoldingEntry,
    }),
    [insert, update, remove, deleteLabel, getCachesHoldingEntry]
  );
};
