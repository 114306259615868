import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  label: string;
  children: ReactNode;
};

export const AccordionSection = ({
  children,
  icon,
  label,
}: Props): JSX.Element => {
  return (
    <AccordionItem _first={{ mb: 3 }} border="none" key={status}>
      <AccordionButton
        bg="customgray.bglight"
        borderRadius="lg"
        px="2"
        py="2.5"
      >
        {icon}

        <Box
          flex="1"
          fontWeight="medium"
          lineHeight="normal"
          ml="2"
          textAlign="left"
        >
          {label}
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel
        display="flex"
        flexDir="column"
        gap="4"
        pb="0"
        pt="3"
        px="2"
      >
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};
