import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Flex,
  Heading,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  GoogleApiDisclaimer,
  GoogleCalendarAuthButton,
} from '@/components/auth';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { DisconnectDialog } from './DisconnectDialog';
import { IntegrationList } from './IntegrationList';

export const IntegrationsSection = () => {
  const { t } = useTranslation('settingsModal', { keyPrefix: 'calendars' });
  const { data, isLoading, isError } = useIntegrations();

  const showDivider = isLoading || isError || (data && data.length > 0);

  return (
    <Box as="section">
      <Heading as="h3" fontWeight="600" mb="3" size="sm">
        Connected calendars
      </Heading>
      {isLoading || !data ? (
        <Flex align="center" gap="2">
          <Spinner size="sm" /> {t('integrations_loading')}
        </Flex>
      ) : isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{t('integrations_error')}</AlertTitle>
        </Alert>
      ) : (
        <IntegrationList integrations={data} />
      )}

      {showDivider && <Divider my="6" />}

      <Box>
        <GoogleCalendarAuthButton launchOnRedirect="settings:calendars" />
      </Box>
      <GoogleApiDisclaimer mt="6" />
      <DisconnectDialog />
    </Box>
  );
};
