import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/useToast';
import { ConfirmDialog } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useLeaveSchedule } from './useLeaveSchedule';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const LeaveConfirmation = ({ isOpen, onCancel }: Props) => {
  const { t } = useTranslation('leaveScheduleConfirm');
  const { schedule } = useScheduleContext();
  const { leaveSchedule, isPending } = useLeaveSchedule();
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <ConfirmDialog
      confirmButtonText={t('confirm_submit')}
      isLoading={isPending}
      isOpen={isOpen}
      message={t('confirm_message')}
      title={t('confirm_title', {
        title: schedule?.title || t('untitled_schedule', { ns: 'common' }),
      })}
      onCancel={onCancel}
      onConfirm={() =>
        leaveSchedule({
          onSuccess: () => navigate('/'),
          onError: () => toast.error(t('error_toast')),
        })
      }
    />
  );
};
