import { Box, Button, Container, Flex, Icon, Link } from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsChatTextFill } from 'react-icons/bs';
import { FaRegHeart } from 'react-icons/fa';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { SchedulesWithCustomCTA } from '@/pages/Schedule/constants/custom-ctas';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { AddToCalendarModal } from '../AddToCalendarModal';
import { ScheduleTitle } from './ScheduleTitle';

export const ScheduleHero = () => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { scheduleId } = useScheduleContext();

  const scheduleIdsWithFeedCta: string[] = [
    SchedulesWithCustomCTA.BEAUTY_AND_BEAST_PLAY,
    SchedulesWithCustomCTA.SAN_DIMAS_STARS,
    SchedulesWithCustomCTA.ELF_MAINSTAGE_FAMILY_SCHEDULE,
  ];

  return (
    <Container bg="white" pt={{ base: 3, md: 6 }} variant="max">
      <Flex
        direction={isMobileBreakpoint ? 'column' : 'row'}
        gap="6"
        justify="space-between"
        wrap="wrap"
      >
        <Box flexGrow="1" flexShrink="1" maxW="100%" pos="relative">
          <ScheduleTitle />
        </Box>

        {scheduleId === SchedulesWithCustomCTA.SF_AI && (
          <Flex
            align="center"
            color="brand.500"
            gap="1"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
            mt={{ base: 3, sm: 5, md: 3 }}
          >
            <Icon as={AiOutlinePlus} />
            <Link
              _hover={{ textDecor: 'underline' }}
              href="https://forms.gle/dMVHz5WpMQGmhrix7"
              target="_blank"
            >
              Submit Event
            </Link>
          </Flex>
        )}
        {scheduleId === SchedulesWithCustomCTA.FAIR_PLAY && (
          <Flex
            align="center"
            color="brand.500"
            gap="1"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
            mt={{ base: 3, sm: 5, md: 3 }}
          >
            <Icon as={AiOutlinePlus} />
            <Link
              _hover={{ textDecor: 'underline' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSckDgaAYzjVFpBfZ3eKTFf6emqbhucC3Ca_XVl9b34Si3cEKQ/viewform?usp=sf_link"
              target="_blank"
            >
              Submit Event
            </Link>
          </Flex>
        )}
        {/* Elf on a Shelf schedule */}
        {scheduleId === SchedulesWithCustomCTA.ELF_ON_A_SHELF && (
          <Flex
            align="center"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
          >
            <Button
              _hover={{ textDecoration: 'none' }}
              as={Link}
              colorScheme="green"
              fontWeight="medium"
              href="https://forms.gle/fUkk4mjVshtLDLwCA"
              leftIcon={<Icon as={BsChatTextFill} />}
              lineHeight="1"
            >
              Get Daily Text Reminders
            </Button>
          </Flex>
        )}
        {scheduleId === SchedulesWithCustomCTA.MRS_B_TARAS_NANNY_SITTING && (
          <Flex
            align="center"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
          >
            <Button
              _hover={{ textDecoration: 'none', bg: '#641684' }}
              as={Link}
              bg="#4A1062"
              color="#EFDFE8"
              fontWeight="medium"
              href="https://forms.gle/xM9bZXCj75QFz3kT9"
              leftIcon={<Icon as={FaRegHeart} />}
              lineHeight="1"
            >
              Request Care
            </Button>
          </Flex>
        )}
        {/* Mrs B's (Tara's nanny) gift-wrapping schedule */}
        {scheduleId ===
          SchedulesWithCustomCTA.MRS_B_TARAS_NANNY_GIFT_WRAPPING && (
          <Flex
            align="center"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
          >
            <Button
              _hover={{ textDecoration: 'none' }}
              as={Link}
              colorScheme="green"
              fontWeight="medium"
              href="https://forms.gle/GTV2ZpLeVCrCuRTH6"
              leftIcon={<Box>{'🎁'}</Box>}
              lineHeight="1"
            >
              Request Gift Wrapping
            </Button>
          </Flex>
        )}

        {scheduleIdsWithFeedCta.includes(scheduleId) && (
          <Flex
            align="center"
            grow="1"
            justify={isMobileBreakpoint ? 'center' : 'end'}
          >
            <AddToCalendarModal
              fetchSecretBeforeOpen
              renderButton={(props) => (
                <Button
                  {...props}
                  _hover={{ textDecoration: 'none' }}
                  fontWeight="medium"
                  lineHeight="1"
                  variant="outline"
                >
                  Subscribe to calendar
                </Button>
              )}
            />
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
