import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import { DecoratedEntry } from '../../types';
import {
  FollowEntryMutation,
  FollowEntryMutationVariables,
} from './useFollowEntry.generated';

const query = gql`
  mutation FollowEntry($scheduleId: ID!, $entryId: ID!) {
    followEntry(scheduleId: $scheduleId, entryId: $entryId)
  }
`;

export const useFollowEntry = (entry: DecoratedEntry) => {
  const { scheduleId } = useScheduleContext();
  const { id: entryId, contextId } = entry;

  const { mutate, isPending, isSuccess, reset } = useMutation<
    FollowEntryMutation,
    QueryError,
    FollowEntryMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<FollowEntryMutation, FollowEntryMutationVariables>(
        query,
        variables
      ),
    onMutate: () => {
      return {
        optimisticData: {
          contextId,
          isFollowing: true,
        },
        rollbackData: {
          contextId,
          isFollowing: false,
        },
      };
    },
  });

  const followEntry = useCallback(
    (
      options?: MutateOptions<
        FollowEntryMutation,
        QueryError,
        FollowEntryMutationVariables
      >
    ) => mutate({ scheduleId, entryId }, options),
    [mutate, scheduleId, entryId]
  );

  return {
    followEntry,
    isPending,
    isSuccess,
    reset,
  };
};
