import {
  BoxProps,
  Button,
  Container,
  Flex,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { DateTime } from 'luxon';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  CopyScheduleButton,
  FollowScheduleButton,
  BatchCreateModal,
} from '@/pages/Schedule/components';
import {
  useEntriesContext,
  useScheduleContext,
  useScheduleFilters,
} from '@/pages/Schedule/contexts';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { useShowLoadingSpinner } from '@/pages/Schedule/hooks/useShowLoadingSpinner';
import { FilterPopover } from '../FilterPopover';
import { ToggleHiddenInstancesButton } from '../ToggleHiddenInstancesButton';
import { CreateSnippetPopover } from './components/CreateSnippetPopover';
import { ScheduleTimeZone } from './components/ScheduleTimeZone';
import { ViewSelect } from './components/ViewSelect';
import { useStickyClassName } from './hooks/useStickyClassName';

type Props = BoxProps & {
  onTodayClick: () => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  onDateRangeChange: (startDate: DateTime, endDate?: DateTime) => void;
};

export const ActionBar = ({
  onTodayClick,
  onPrevClick,
  onNextClick,
  onDateRangeChange,
  ...boxProps
}: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation('actionBar');
  const { ref } = useStickyClassName('is-pinned');
  const { hasSchedulePermission } = useSchedulePermissions();
  const { allEntryInstances } = useEntriesContext();
  const { hasAppliedFilters } = useScheduleFilters();
  const showLoadingSpinner = useShowLoadingSpinner();
  const {
    startDate,
    endDate,
    isOwnerRole,
    isEditorRole,
    isPublicRole,
    viewModule,
    schedule,
  } = useScheduleContext();
  const { value: snippetsEnabled } = useFeatureFlag('snippetsEnabled');

  const canCreateEntries = hasSchedulePermission('ENTRY_CREATE');

  const hasHiddenEntries =
    allEntryInstances.reduce((count, instance) => {
      return (count += instance.isHidden ? 1 : 0);
    }, 0) > 0;

  const showToggleHideButton =
    hasHiddenEntries && !isMobileBreakpoint && (isOwnerRole || isEditorRole);

  const showSnippetsButton =
    snippetsEnabled &&
    hasAppliedFilters &&
    hasSchedulePermission('SNIPPET_EDIT');

  const showPan = Boolean(
    viewModule.adjustPreviousStartDate && viewModule.adjustNextStartDate
  );

  const showDesktopLoader = showLoadingSpinner && !isMobileBreakpoint;
  const showCopyScheduleButton = schedule?.isCopyable && !isMobileBreakpoint;
  const showSaveScheduleButton =
    !showCopyScheduleButton && isPublicRole && !isMobileBreakpoint;
  const showCreateButton = canCreateEntries && !isMobileBreakpoint;
  const showTodayButton = Boolean(viewModule.DateNavigationComponent);

  const loader = <Spinner data-testid="entries-loader" size="sm" />;

  return (
    <Container
      bg="white"
      borderRadius={schedule?.header.enabled ? 0 : 'xl'}
      pos="sticky"
      ref={ref}
      top={0}
      transition="all 0.2s ease"
      variant="max"
      zIndex="1"
      css={css`
        &.is-pinned {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        }
      `}
      {...boxProps}
    >
      <Container display="flex" flexDir="row" px="0" variant="max">
        {/* Left col */}
        <Flex align="center" flex="1" gap={{ base: 2, sm: 3 }}>
          <ViewSelect />

          {viewModule.DateNavigationComponent && (
            <Suspense fallback={null}>
              <viewModule.DateNavigationComponent
                endDate={endDate}
                startDate={startDate}
                onChange={onDateRangeChange}
              />
            </Suspense>
          )}

          {showTodayButton && (
            <Button
              colorScheme={isMobileBreakpoint ? 'dark' : undefined}
              variant={isMobileBreakpoint ? 'ghost' : 'secondary'}
              onClick={() => onTodayClick()}
            >
              {t('week_header.today')}
            </Button>
          )}

          {showPan && (
            <Flex align="center">
              <IconButton
                aria-label={t('week_header.prev_week')}
                colorScheme="dark"
                h="75%"
                icon={<HiOutlineChevronLeft size="22" />}
                minW="auto"
                p="1"
                variant="ghost"
                onClick={onPrevClick}
              />
              <IconButton
                aria-label={t('week_header.next_week')}
                colorScheme="dark"
                h="75%"
                icon={<HiOutlineChevronRight size="22" />}
                minW="auto"
                p="1"
                variant="ghost"
                onClick={onNextClick}
              />
            </Flex>
          )}

          <ScheduleTimeZone display={{ base: 'none', sm: 'inherit' }} />
        </Flex>

        {/* Right col */}
        <Flex
          align="center"
          gap="3"
          overflow={isMobileBreakpoint ? 'visible' : 'hidden'}
        >
          {showDesktopLoader && loader}

          {showToggleHideButton && <ToggleHiddenInstancesButton />}

          {showSnippetsButton && <CreateSnippetPopover />}

          <FilterPopover />

          {showCreateButton && <BatchCreateModal />}

          {showSaveScheduleButton && <FollowScheduleButton />}

          {showCopyScheduleButton && <CopyScheduleButton />}
        </Flex>
      </Container>
    </Container>
  );
};
