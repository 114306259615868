import { DateTime } from 'luxon';
import { RawEntry } from '../../types';

type Range = {
  startDate: string;
  endDate: string;
};

export const isEntryInsideDateRange = (
  entry: RawEntry,
  { startDate, endDate }: Range
): boolean => {
  const entryStart = DateTime.fromISO(entry.recurrences[0].startDate);
  const entryEnd = DateTime.fromISO(entry.recurrences[0].endDate);
  const rangeStart = DateTime.fromISO(startDate);
  const rangeEnd = DateTime.fromISO(endDate);
  return entryStart >= rangeStart && entryEnd <= rangeEnd;
};
