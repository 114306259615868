import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  TogglePublicScheduleMutation,
  TogglePublicScheduleMutationVariables,
} from './useTogglePublicSchedule.generated';

const query = gql`
  mutation TogglePublicSchedule($scheduleId: ID!, $isPublic: Boolean!) {
    updateSchedule(scheduleId: $scheduleId, isPublic: $isPublic) {
      id
      isPublic
    }
  }
`;

export const useTogglePublicSchedule = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    TogglePublicScheduleMutation,
    QueryError,
    TogglePublicScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        TogglePublicScheduleMutation,
        TogglePublicScheduleMutationVariables
      >(query, variables),
  });

  const togglePublicSchedule = (isPublic: boolean) => {
    return mutate({ scheduleId, isPublic });
  };

  return { togglePublicSchedule };
};
