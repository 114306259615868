import {
  Text,
  Link,
  useMultiStyleConfig,
  useTab,
  forwardRef,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

type Props = {
  selectedBgColor: string;
};

export const StyledNavLink = forwardRef(
  ({ selectedBgColor, ...props }: Props, ref) => {
    const tabProps = useTab({ ...props, ref });
    const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <Link
        __css={styles.tab}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        _hover={{ bgColor: '#f4f2fa' }}
        as={NavLink}
        bgColor={isSelected ? selectedBgColor : undefined}
        borderRadius="full"
        fontSize="lg"
        fontWeight="bold"
        px="3"
        py="2"
        shadow="none"
        textDecor="none"
        variant="unstyled"
        {...tabProps}
      >
        <Text textStyle={isSelected ? 'magic' : undefined}>
          {tabProps.children}
        </Text>
      </Link>
    );
  }
);
