import {
  Box,
  Container,
  Link as ChakraLink,
  Text,
  type StyleProps,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';

const textStyle: StyleProps = {
  fontWeight: '500',
  lineHeight: 'normal',
  color: 'brand.500',
};

const isAppLink = (href: string): boolean => {
  return href.startsWith('/');
};

export const Banner = () => {
  const { value: bannerMessage } = useFeatureFlag('bannerMessage');
  const { value: bannerLink } = useFeatureFlag('bannerLink');

  if (!bannerMessage.trim()) {
    // ConfigCat does not allow an empty string, so we just use whitespace to
    // set it to empty
    return null;
  }

  return (
    <Box
      bg="white"
      borderBottomColor="customgray.gray"
      borderBottomWidth="1px"
      display={bannerMessage ? 'block' : 'none'}
      py="2"
      sx={{ '@media print': { display: 'none' } }}
      w="100%"
    >
      <Container
        alignItems="center"
        display="flex"
        fontSize={{ base: 12, sm: 14 }}
        gap={{ base: 3, md: 4 }}
        justifyContent="center"
        variant="max"
      >
        {bannerLink ? (
          isAppLink(bannerLink) ? (
            <ChakraLink {...textStyle} as={Link} to={bannerLink}>
              {bannerMessage}
            </ChakraLink>
          ) : (
            <ChakraLink {...textStyle} href={bannerLink} target="_blank">
              {bannerMessage}
            </ChakraLink>
          )
        ) : (
          <Text {...textStyle}>{bannerMessage}</Text>
        )}
      </Container>
    </Box>
  );
};
