import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { ScheduleCategory, useScheduleCache } from '@/pages/Schedule/hooks';
import { QueryError } from '@/utils/errors';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import {
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from './useRemoveCategory.generated';

const query = gql`
  mutation DeleteCategory($scheduleId: ID!, $categoryId: ID!) {
    deleteCategory(scheduleId: $scheduleId, categoryId: $categoryId)
  }
`;

export const useRemoveCategory = () => {
  const { scheduleId } = useScheduleContext();
  const scheduleCache = useScheduleCache();

  const { mutate } = useMutation<
    DeleteCategoryMutation,
    QueryError,
    DeleteCategoryMutationVariables,
    ScheduleCategory
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteCategoryMutation,
        DeleteCategoryMutationVariables
      >(query, variables),
    onMutate: ({ categoryId }) => {
      const category = scheduleCache.getCategory(categoryId);
      scheduleCache.removeCategory(categoryId);
      return category;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(variables.scheduleId),
      });
    },
    onError: (_, variables, category) => {
      scheduleCache.addCategory(variables.categoryId, {
        color: category?.color,
        text: category?.text,
      });
    },
  });

  const removeCategory = useCallback(
    (
      categoryId: string,
      options?: MutateOptions<
        DeleteCategoryMutation,
        QueryError,
        DeleteCategoryMutationVariables,
        ScheduleCategory
      >
    ) =>
      mutate(
        {
          categoryId,
          scheduleId,
        },
        options
      ),
    [mutate, scheduleId]
  );

  return { removeCategory };
};
