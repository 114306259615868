import { Container, ContainerProps, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { SlimHeader } from '../SlimHeader';

type Props = ContainerProps & {
  children?: ReactNode;
};

export const AuthLayout = ({ children, ...props }: Props) => {
  return (
    <>
      <SlimHeader rightColumn="none" />

      <Container
        as={Flex}
        flex="1"
        justifyContent="center"
        p={10}
        variant="max"
        {...props}
      >
        {children ? children : <Outlet />}
      </Container>
    </>
  );
};
