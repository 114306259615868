import { init, use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';

export const defaultNS = 'common';
export const resources = { en } as const;

export const i18n = use(initReactI18next);

init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
