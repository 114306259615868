import { defineStyleConfig } from '@chakra-ui/react';
import { notionVariantBaseStyles } from '@/theme/styles';

export const Input = defineStyleConfig({
  baseStyle: {
    field: {
      borderRadius: '8px',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    // Using a primary variant that's set as the default instead of
    // defining `baseStyle` so that other variants don't inherit these
    // styles. Otherwise, variants like `underline` will need to unset
    // some of the focus styles.
    primary: {
      field: {
        bg: 'transparent',
        borderWidth: '1px',
        borderColor: 'gray.300',
        _focusVisible: {
          borderColor: 'brand.500',
        },
        _focus: {
          borderColor: 'brand.500',
          borderWidth: '1px',
          boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.1)',
          _hover: {
            borderColor: 'brand.500',
          },
        },
      },
    },
    underline: {
      field: {
        borderBottomWidth: '1px',
        borderBottomColor: 'customgray.gray',
        borderRadius: 0,
        padding: 0,
        _focus: {
          borderBottomColor: 'brand.500',
        },
        _placeholder: {
          color: 'customgray.alsolight',
        },
        _dark: {
          color: 'white',
          background: 'transparent',
        },
      },
    },
    notion: {
      field: {
        ...notionVariantBaseStyles,
        transition: 'box-shadow 0.2s ease',
        _focus: {
          boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
          _hover: {
            bg: 'inherit',
          },
        },
      },
    },
  },
});
