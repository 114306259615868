import { DateTime } from 'luxon';
import { MakeOptional, Recurrence } from '@/types/gql.generated';
import { createEntryDate, dateInZone } from '@/utils/dates';
import { DecoratedRecurrence, MinimumDecoratedEntryInputProps } from '../types';

type InputObject = MakeOptional<
  MinimumDecoratedEntryInputProps,
  'createdAt' | 'hidden' | 'exclusions'
>;

/**
 * Given a subset of the GQL `Entry` shape (typically an `EntryFragment` but
 * maybe not, like in the case of snippets), and assuming the subset contains
 * the required minimum fields, convert all the date properties into luxon
 * DateTime objects for easier consumption throughout the app.
 */
export const createDecoratedEntry = <E extends InputObject>(
  entry: E,
  scheduleTimeZone: string
) => {
  function createDecoratedRecurrence(
    recurrence: Recurrence
  ): DecoratedRecurrence {
    const startDate = createEntryDate(
      recurrence.startDate,
      entry.timeZone,
      scheduleTimeZone,
      recurrence.isOnDay
    );
    const endDate = createEntryDate(
      recurrence.endDate,
      entry.timeZone,
      scheduleTimeZone,
      recurrence.isOnDay
    );

    return {
      ...recurrence,
      startDate,
      endDate,
    };
  }

  return {
    ...entry,
    createdAt: entry.createdAt
      ? dateInZone(entry.createdAt, entry.timeZone, scheduleTimeZone)
      : DateTime.local({ zone: scheduleTimeZone }),
    exclusions:
      entry.exclusions?.map((date) =>
        dateInZone(date, entry.timeZone, scheduleTimeZone)
      ) ?? [],
    recurrences: entry.recurrences.map(createDecoratedRecurrence),
    hidden:
      entry.hidden?.map((date) =>
        createEntryDate(
          date,
          entry.timeZone,
          scheduleTimeZone,
          entry.recurrences[0].isOnDay
        )
      ) ?? [],
    messages: entry.messages?.map((message) => ({
      ...message,
      instance: createEntryDate(
        message.instance,
        entry.timeZone,
        scheduleTimeZone,
        entry.recurrences[0].isOnDay
      ),
    })),
  };
};
