import { Flex, FlexProps } from '@chakra-ui/react';
import { Navigation } from './Navigation';

export const Sidebar = (props: FlexProps) => {
  return (
    <Flex
      bg="gray.100"
      borderBottomLeftRadius="20"
      borderRightColor="gray.200"
      borderRightWidth="1px"
      borderTopLeftRadius="20"
      direction="column"
      gap="1"
      pb="2"
      pt="8"
      px="3"
      w="250px"
      {...props}
    >
      <Navigation />
    </Flex>
  );
};
