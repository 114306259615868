import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createScheduleMembersQueryKey } from '@/utils/queryKeys';
import {
  GetScheduleMembersQuery,
  GetScheduleMembersQueryVariables,
} from './useScheduleMembers.generated';

const query = gql`
  query GetScheduleMembers($scheduleId: ID!) {
    getSchedule(scheduleId: $scheduleId) {
      members {
        id
        name
        phoneNumber
      }
    }
  }
`;

const selector = (data: GetScheduleMembersQuery) => data.getSchedule.members;

export const useScheduleMembers = () => {
  const { scheduleId, isLoading } = useScheduleContext();

  const { data, isSuccess } = useQuery({
    queryKey: createScheduleMembersQueryKey(scheduleId),
    select: selector,
    queryFn: () =>
      gqlClient.request<
        GetScheduleMembersQuery,
        GetScheduleMembersQueryVariables
      >(query, { scheduleId }),
  });

  return {
    members: data ?? [],
    isLoading,
    isSuccess,
  };
};
