import { ScheduleView } from '../types';
import { AgendaModule } from './AgendaView';
import { ConflictModule } from './ConflictView';
import { MonthModule } from './MonthView';
import { SummaryModule } from './SummaryView';
import { ViewModule } from './types';
import { WeekModule } from './WeekView';

export const VIEW_TO_MODULE: Record<ScheduleView, ViewModule> = {
  agenda: AgendaModule,
  month: MonthModule,
  week: WeekModule,
  conflict: ConflictModule,
  summary: SummaryModule,
};
