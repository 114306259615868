import { AiOutlineUser } from 'react-icons/ai';
import { GoTag } from 'react-icons/go';
import { i18n } from '@/i18n';
import { LabelType, Label } from '@/types/gql.generated';
import { DecoratedSchedule } from '../contexts';

type Definition = {
  title: string;
  icon: JSX.Element;
  iconSm: JSX.Element;
  prompt: string;
  scheduleKey: 'labels' | 'whoLabels';
  scheduleGetter: (schedule: DecoratedSchedule) => Label[];
};

export const definitions: Record<LabelType, Definition> = {
  [LabelType.Default]: {
    scheduleGetter: (schedule) => schedule.labels,
    title: i18n.t('labelSelect:label.title'),
    prompt: i18n.t('labelSelect:label.empty_cta'),
    icon: <GoTag size="20" />,
    iconSm: <GoTag size="12" />,
    scheduleKey: 'labels',
  },
  [LabelType.Who]: {
    scheduleGetter: (schedule) => schedule.whoLabels,
    title: i18n.t('labelSelect:who.title'),
    prompt: i18n.t('labelSelect:who.empty_cta'),
    icon: <AiOutlineUser size="18" />,
    iconSm: <AiOutlineUser size="12" />,
    scheduleKey: 'whoLabels',
  },
};
