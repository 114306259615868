import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Button,
  Icon,
  IconButton,
  MenuDivider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsCalendar3 } from 'react-icons/bs';
import { FiHelpCircle, FiMenu, FiUser } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { shouldRedirectOnLogout } from '@/components/auth';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useLogout } from '@/hooks/useLogout';
import { useSettingsModal } from '../store';
import { Tab } from './Tab';

export const Navigation = () => {
  const { tab, close, changeTab } = useSettingsModal();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation(['settingsModal', 'auth']);
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleAccountClick = () => {
    changeTab('account');
  };

  const handleHelpClick = () => {
    changeTab('help');
  };

  const handleCalendarsClick = () => {
    changeTab('calendars');
  };

  const handleLogoutClick = () => {
    logout({
      onSuccess: () => {
        close();

        const { shouldRedirect, path } = shouldRedirectOnLogout();
        if (shouldRedirect) {
          navigate(path);
        }
      },
    });
  };

  if (isMobileBreakpoint) {
    return (
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          colorScheme="dark"
          icon={<Icon as={FiMenu} boxSize="20px" />}
          ml="-2"
          size="sm"
          variant="ghost"
        />
        <MenuList fontSize="md">
          <MenuItem icon={<FiUser />} onClick={handleAccountClick}>
            {t('navigation.account')}
          </MenuItem>
          <MenuItem icon={<BsCalendar3 />} onClick={handleCalendarsClick}>
            {t('navigation.calendars')}
          </MenuItem>
          <MenuItem icon={<FiHelpCircle />} onClick={handleHelpClick}>
            {t('navigation.help')}
          </MenuItem>
          <MenuDivider />
          <MenuItem icon={<MdLogout />} onClick={handleLogoutClick}>
            {t('auth:sign_out')}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <>
      <Tab
        isActive={tab === 'account'}
        leftIcon={<FiUser />}
        onClick={handleAccountClick}
      >
        {t('navigation.account')}
      </Tab>
      <Tab
        isActive={tab === 'calendars'}
        leftIcon={<BsCalendar3 />}
        onClick={handleCalendarsClick}
      >
        {t('navigation.calendars')}
      </Tab>
      <Tab
        isActive={tab === 'help'}
        leftIcon={<FiHelpCircle />}
        onClick={handleHelpClick}
      >
        {t('navigation.help')}
      </Tab>

      <Divider my="4" />

      <Button
        justifyContent="flex-start"
        leftIcon={<MdLogout />}
        pl="4"
        variant="link"
        onClick={handleLogoutClick}
      >
        {t('auth:sign_out')}
      </Button>
    </>
  );
};
