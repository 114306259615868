import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { Label } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  UpdateLabelMutation,
  UpdateLabelMutationVariables,
} from './useUpdateLabel.generated';

const query = gql`
  mutation UpdateLabel($scheduleId: ID!, $labelId: ID!, $text: String!) {
    updateLabel(scheduleId: $scheduleId, labelId: $labelId, text: $text) {
      id
      sortOrder
      text
    }
  }
`;

export const useUpdateLabel = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    UpdateLabelMutation,
    QueryError,
    UpdateLabelMutationVariables,
    { previousLabel?: Label }
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        UpdateLabelMutation,
        UpdateLabelMutationVariables
      >(query, variables);
    },
  });

  return {
    updateLabel: (
      labelId: string,
      text: string,
      options?: MutateOptions<
        UpdateLabelMutation,
        QueryError,
        UpdateLabelMutationVariables
      >
    ) => {
      return mutate({ scheduleId, labelId, text }, options);
    },
  };
};
