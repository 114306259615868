import { DateTime } from 'luxon';
import { datetime, RRule, RRuleSet } from 'rrule';
import { isMultiDay } from '@/utils/dates';
import {
  CreateDecoratedInstanceType,
  DecoratedRecurrence,
  MinimumDecoratedEntryProps,
} from '../types';

/**
 * Prepare a date that's ready to be compared against dates in an entry's
 * `hidden` or `exclusions` arrays.
 */
const formatExclusionDate = (
  date: DateTime,
  timeZone: string,
  isOnDay: boolean
): DateTime => {
  if (isOnDay) {
    const exclusionDatePart = date.setZone(timeZone).toISODate();
    return DateTime.fromISO(exclusionDatePart, { zone: 'UTC' });
  }
  return date.setZone('UTC', { keepLocalTime: true });
};

/**
 * Expand an entry's recurrence rule into an array of dates
 */
const getDatesFromRecurrence = (
  recurrence: DecoratedRecurrence,
  timeZone: string,
  startDate: DateTime,
  endDate: DateTime,
  exclusions: DateTime[],
  scheduleTimeZone: string
): DateTime[] => {
  const rruleSet = new RRuleSet();

  // rrule.js requires UTC dates with local times
  // See https://github.com/jakubroztocil/rrule#timezone-support
  const boundaryStartDate = startDate
    .setZone('UTC', { keepLocalTime: true })
    .toJSDate();

  const boundaryEndDate = endDate
    .setZone('UTC', { keepLocalTime: true })
    .toJSDate();

  // add exclusions
  exclusions.forEach((exclusion) => {
    const date = formatExclusionDate(exclusion, timeZone, recurrence.isOnDay);
    rruleSet.exdate(date.toJSDate());
  });

  const options = RRule.parseString(recurrence.rule ? recurrence.rule : '');

  // dtstart defines the first instance in the recurrence set
  options.dtstart = datetime(
    recurrence.startDate.year,
    recurrence.startDate.month,
    recurrence.startDate.day,
    recurrence.startDate.hour,
    recurrence.startDate.minute,
    recurrence.startDate.second
  );

  if (options.until) {
    // If this is an all day event AND the event is coming from Google, it won't
    // have a time part. If it's not an all day event then we want to interpret
    // the until date in the user's local zone.
    let until = DateTime.fromJSDate(options.until, { zone: timeZone });
    if (!recurrence.isOnDay) {
      until = until.setZone(scheduleTimeZone);
    }

    options.until = datetime(
      until.year,
      until.month,
      until.day,
      until.hour,
      until.minute,
      until.second
    );
  }

  rruleSet.rrule(new RRule(options));

  return rruleSet
    .between(boundaryStartDate, boundaryEndDate, true)
    .map((date) => {
      // The timepart in `date` is the correct local time but shifted into UTC.
      // Example: 10pm EDT (22:00) in EDT (-4) is expressed as 18:00-0400.
      // We must parse the date in UTC then convert it back to the user's current
      // zone to get the correct local time.
      // See Luxon example @ https://github.com/jakubroztocil/rrule#important-use-utc-dates
      const dateInUtc = DateTime.fromJSDate(date).toUTC();
      const dateInLocal = dateInUtc.setZone(scheduleTimeZone, {
        keepLocalTime: true,
      });
      return dateInLocal;
    });
};

/**
 * Given an entry and an upper date boundary, loop through its `recurrences`
 * array and expand each one into one or more instances of the entry.
 *
 * All entries have at least one instance but they may have more if the
 * recurrence specifies a `rule`.
 */
type DecoratedInstance<E> = CreateDecoratedInstanceType<E>;
export const getEntryInstances = <E extends MinimumDecoratedEntryProps>(
  entry: E,
  startDate: DateTime,
  endDate: DateTime,
  scheduleTimeZone: string
): DecoratedInstance<E>[] => {
  const isHidden = (instanceDate: DateTime) => {
    return !!entry.hidden?.find((date) => +date === +instanceDate);
  };

  const createInstance = (
    props: DecoratedRecurrence & { isHidden?: boolean }
  ): DecoratedInstance<E> => ({
    ...entry,
    ...props,
    id: `${entry.id}_${props.startDate.toISODate()}`,
    parentId: entry.id,
    isHidden: props.isHidden ?? isHidden(props.startDate),
  });

  const instances = entry.recurrences.reduce<DecoratedInstance<E>[]>(
    (acc, recurrence) => {
      // Handle non-recurring, multi-day entries.
      // Each day of the entry is split into its own instance.
      if (
        !recurrence.rule &&
        isMultiDay(recurrence.startDate, recurrence.endDate)
      ) {
        const entryEndDate = recurrence.endDate;
        const dates = [];
        let cursor = recurrence.startDate;

        while (cursor < entryEndDate) {
          const endDate =
            cursor.toISODate() === entryEndDate.toISODate()
              ? entryEndDate
              : cursor.startOf('day').plus({ days: 1 });
          dates.push({
            startDate: cursor,
            endDate,
          });
          cursor = endDate;
        }

        const splitDayInstances: DecoratedInstance<E>[] = dates.map(
          ({ startDate, endDate }, index) => {
            return createInstance({
              ...recurrence,
              startDate,
              endDate,
              // Hiding split multi-day applies to the entire entry vs. its individual instances
              isHidden: isHidden(recurrence.startDate),
              isFirstInSequence: index === 0,
              isLastInSequence: index === dates.length - 1,
              sequence: index,
            });
          }
        );

        return acc.concat(splitDayInstances);
      }

      // Handle non-recurring, one-off entries
      else if (!recurrence.rule) {
        const instance = createInstance(recurrence);
        return acc.concat(instance);
      }

      // Handle recurring instances
      else {
        const duration = recurrence.endDate.diff(
          recurrence.startDate,
          'minutes'
        ).minutes;

        const instancesForRecurrence: DecoratedInstance<E>[] =
          getDatesFromRecurrence(
            recurrence,
            entry.timeZone,
            startDate,
            endDate,
            entry.exclusions,
            scheduleTimeZone
          ).map((startDate) => {
            const endDate = recurrence.isOnDay
              ? startDate.plus({ days: 1 })
              : startDate.plus({ minutes: duration });

            return createInstance({
              ...recurrence,
              startDate,
              endDate,
            });
          });

        return acc.concat(instancesForRecurrence);
      }
    },
    []
  );

  return instances;
};
