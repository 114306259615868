import { Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsChatText } from 'react-icons/bs';
import { HeaderButton, HeaderButtonProps } from './HeaderButton';

export const MessagesButton = (props: HeaderButtonProps) => {
  const { t } = useTranslation('batchCreate');

  return (
    <HeaderButton
      aria-label={t('aria_messages')}
      icon={<Icon as={BsChatText} />}
      {...props}
    />
  );
};
