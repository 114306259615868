import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Logo } from '@/components/Logo';
import { config } from '@/config';

export const BrandLink = () => {
  const { t } = useTranslation('header');

  return (
    <Flex
      _hover={{ textDecor: 'none', color: 'black' }}
      align="center"
      as="a"
      fontSize="17"
      fontWeight="900"
      gap="4"
      href={config.marketingUrl}
      lineHeight="normal"
      transition="color 0.1s ease-in-out"
    >
      <Logo m="auto" />
      <Text>{t('agendahero', { ns: 'common' })}</Text>
    </Flex>
  );
};
