import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Input,
} from '@chakra-ui/react';
import { ChangeEventHandler, useRef } from 'react';
import { MemberFieldType } from '@/types/gql.generated';
import { type TelInput, TelephoneInput } from '../TelephoneInput';
import { type MemberFormField } from './types';

type Props = {
  field: MemberFormField;
  error?: string;
  name: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const FormField = forwardRef(
  ({ field, error, ...props }: Props, ref) => {
    const itiInstanceRef = useRef<TelInput | null>(null);

    return (
      <FormControl isInvalid={!!error} sx={{ '.iti': { width: '100%' } }}>
        <FormLabel>{field.label}</FormLabel>
        {field.helpText && (
          <FormHelperText mb="2">{field.helpText}</FormHelperText>
        )}
        {field.type === MemberFieldType.PhoneNumber ? (
          <TelephoneInput
            itiInstanceRef={itiInstanceRef}
            ref={ref}
            {...props}
          />
        ) : (
          <Input ref={ref} type="text" {...props} />
        )}
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }
);
