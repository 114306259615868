import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { createSchedulesQueryKey } from '@/utils/queryKeys';
import { GetSchedulesQuery } from './useSchedules.generated';

const queryKey = createSchedulesQueryKey();

export const useSchedulesCache = () => {
  const queryClient = useQueryClient();

  const removeSchedule = useCallback(
    (scheduleId: string) => {
      queryClient.setQueryData<GetSchedulesQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            getSchedules: oldData.getSchedules.filter(
              (schedule) => schedule.id !== scheduleId
            ),
          };
        }
      );
    },
    [queryClient]
  );

  return {
    removeSchedule,
  };
};
