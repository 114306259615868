import { Flex, FlexProps } from '@chakra-ui/react';

export const Pill = (props: FlexProps) => {
  return (
    <Flex
      align="center"
      bg="customgray.bglight"
      borderColor="gray.200"
      borderRadius="md"
      borderWidth="1px"
      fontSize="sm"
      fontWeight="bold"
      gap="2"
      h="26px"
      justify="center"
      lineHeight="1"
      minW="0"
      overflow="hidden"
      px="1.5"
      py="1"
      {...props}
    />
  );
};
