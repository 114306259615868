import React from 'react';
import { createRoot } from 'react-dom/client';
import '@/lib/sentry';

// throw on invalid luxon dates after Sentry is initialized
// eslint-disable-next-line import/order
import { Settings } from 'luxon';
Settings.throwOnInvalid = true;

import { config } from '@/config';
import { App } from './App';

// eslint-disable-next-line import/no-unresolved
import 'unfonts.css'; // vite fonts loader

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

// ensure app.agendahero.com is not accessed directly
const ensureDomain = () => {
  const { location } = window;
  if (
    config.env === 'production' &&
    !config.isLocalProdBuild &&
    !location.href.startsWith(config.server.url) &&
    !location.hostname.includes(config.server.pr)
  ) {
    const redirectUrl = new URL(location.href);
    const serverUrl = new URL(config.server.url);
    redirectUrl.host = serverUrl.host;
    redirectUrl.port = serverUrl.port;
    window.location.href = redirectUrl.toString();
  }
};
ensureDomain();

// reload when chunks from a previous deployment are still in the cache
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

// https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
const setAppHeight = () => {
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`
  );
};
window.addEventListener('resize', setAppHeight);
setAppHeight();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
