import { AnimatePresence } from 'framer-motion';
import { useMountedState } from 'react-use';
import { MotionBox, MotionBoxProps } from '@/components/MotionBox';

type Props = MotionBoxProps & {
  in: boolean;
  duration?: number;
};

// Custom `Collapse` component that works around an issue in Chakra's
// `Collapse` component.
//
// See:
// * https://github.com/chakra-ui/chakra-ui/issues/7775
// * https://github.com/chakra-ui/chakra-ui/issues/7935
export const Collapse = ({ in: isOpen, duration = 0.1, ...props }: Props) => {
  const isMounted = useMountedState();

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          overflow="hidden"
          transition={{ duration }}
          initial={
            isMounted()
              ? { height: 0, opacity: 0 }
              : { height: 'auto', opacity: 1 }
          }
          {...props}
        />
      )}
    </AnimatePresence>
  );
};
