import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type Props = Omit<IconButtonProps, 'aria-label'> & {
  Icon: IconType;
  label: string;
};

export const PopoverActionButton = forwardRef(
  ({ Icon, label, ...props }: Props, ref) => {
    return (
      <IconButton
        _focusVisible={{ outline: '1px solid', outlineColor: 'brand.500' }}
        aria-label={label}
        borderRadius="full"
        colorScheme="dark"
        icon={<Icon size="18" />}
        ref={ref}
        size="sm"
        tabIndex={1}
        type="button"
        variant="ghost"
        {...props}
      />
    );
  }
);
