import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import { AuthLocationState } from '../types';

export const useFromPath = (): string | undefined => {
  const { queryParams } = useQueryParams();
  const { state: locationState } = useLocation() as AuthLocationState;

  return queryParams.get('from') || locationState?.from?.pathname;
};
