import { useEffect, useState } from 'react';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { EntryModalSidebarPanel } from '../types';

export const useSidebarState = (isModalOpen: boolean) => {
  const { hasSchedulePermission } = useSchedulePermissions();
  const slideSidebarUpFromBottom = useIsBreakpoint('lg');
  const sidebarOpensOnModalOpen = !slideSidebarUpFromBottom;

  const defaultActivePanel =
    sidebarOpensOnModalOpen && hasSchedulePermission('MESSAGES')
      ? 'messages'
      : undefined;

  const [activeSidebarPanel, setActiveSidebarPanel] = useState<
    EntryModalSidebarPanel | undefined
  >(defaultActivePanel);

  // when moving between mobile <> desktop breakpoints with the modal closed,
  // reset the logic that determines which panel to open by default the next
  // time the modal opens.
  //
  // example test case that breaks without this effect:
  //
  // 1. open modal on desktop. messages sidebar opens by default
  // 2. close modal
  // 3. resize window to mobile breakpoint
  // 4. open modal again
  // 5. without this effect, observe that the messages panel continues to open by default
  useEffect(() => {
    if (!isModalOpen) {
      setActiveSidebarPanel(defaultActivePanel);
    }
  }, [isModalOpen, defaultActivePanel]);

  const openSidebar = (panel: EntryModalSidebarPanel) =>
    setActiveSidebarPanel(panel);

  const closeSidebar = () => setActiveSidebarPanel(undefined);

  const toggleSidebar = (panel: EntryModalSidebarPanel) => {
    if (activeSidebarPanel === panel) {
      closeSidebar();
    } else {
      openSidebar(panel);
    }
  };

  const reset = () => {
    setActiveSidebarPanel(defaultActivePanel);
  };

  return {
    slideSidebarUpFromBottom,
    activeSidebarPanel,
    openSidebar,
    closeSidebar,
    toggleSidebar,
    reset,
  };
};
