import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import { QueryError } from '@/utils/errors';
import { Category as CategoryWithoutSortOrder } from '../types';
import {
  UpdateCategorySortOrderMutation,
  UpdateCategorySortOrderMutationVariables,
} from './useUpdateCategorySortOrder.generated';

const query = gql`
  mutation UpdateCategorySortOrder(
    $scheduleId: ID!
    $categories: [CategorySortOrderInput!]!
  ) {
    updateCategorySortOrder(scheduleId: $scheduleId, categories: $categories)
  }
`;

export const useUpdateCategorySortOrder = () => {
  const { scheduleId } = useScheduleContext();
  const scheduleCache = useScheduleCache();

  const { mutate, ...rest } = useMutation<
    UpdateCategorySortOrderMutation,
    QueryError,
    UpdateCategorySortOrderMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateCategorySortOrderMutation,
        UpdateCategorySortOrderMutationVariables
      >(query, variables),
  });

  const updateSortOrder = (categories: CategoryWithoutSortOrder[]) => {
    const categoriesWithSortOrder: UpdateCategorySortOrderMutationVariables['categories'] =
      categories.map((category, index) => ({
        id: category.id,
        sortOrder: index,
      }));

    return mutate(
      { scheduleId, categories: categoriesWithSortOrder },
      {
        onSuccess: () => {
          scheduleCache.updateSchedule({
            categories,
          });
        },
      }
    );
  };

  return {
    updateSortOrder,
    ...rest,
  };
};
