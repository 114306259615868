import { Button } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { SlimHeader } from '@/components/SlimHeader';
import { useCurrentUserContext } from '@/contexts';
import { ScheduleContent, ScheduleLayout } from '@/pages/Schedule/components';
import { ScheduleContextProvider } from '@/pages/Schedule/contexts';

type Props = {
  /** The snippet's schedule ID or null if the user isn't a collaborator on the snippet's schedule */
  scheduleId?: string | null;
  children: ReactNode;
};

export const SnippetContainer = ({ scheduleId, children }: Props) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useCurrentUserContext();

  // If logged in with permissions to the schedule, render content inside the
  // full authenticated frame
  if (isAuthenticated && scheduleId) {
    return (
      <ScheduleContextProvider scheduleSlug={scheduleId}>
        <ScheduleLayout>
          <ScheduleContent>{children}</ScheduleContent>
        </ScheduleLayout>
      </ScheduleContextProvider>
    );
  }

  // If logged out or logged in but without access to the schedule,
  // render the snippet inside a basic, paired-down frame
  return (
    <ScheduleLayout
      bg="white"
      header={
        <SlimHeader
          rightColumn={
            isAuthenticated ? (
              'default'
            ) : (
              <Button onClick={() => navigate('/register')}>
                Create a schedule
              </Button>
            )
          }
        />
      }
    >
      <ScheduleContent>{children}</ScheduleContent>
    </ScheduleLayout>
  );
};
