import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AuthPanel, AuthHeader, type AuthResult } from '@/components/auth';
import { AuthAction } from '@/types/gql.generated';
import { ActionText } from './components/ActionText';
import { useRegisterModalStore } from './store';

export const RegisterModal = () => {
  const { t } = useTranslation('auth');

  const { isOpen, action, launch, defaults, close, onCancel, onAuthenticated } =
    useRegisterModalStore();

  const handleAuthenticated = (result: AuthResult) => {
    close();
    onAuthenticated?.(result);
  };

  const handleCancel = () => {
    close();
    onCancel?.();
  };

  let redirectTo = `${location.pathname}${location.search}`;
  if (launch) {
    redirectTo += location.search.startsWith('?') ? '&' : '?';
    redirectTo += `launch=${launch}`;
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      returnFocusOnClose={false}
      variant="primary"
      onClose={handleCancel}
    >
      <ModalOverlay />

      <ModalContent
        data-testid="register-modal"
        minW={{ base: '100vw', md: '30rem' }}
      >
        <ModalHeader>{t('sign_in_or_create')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody display="flex" flexDir="column" gap="6">
          <ActionText action={action} />
          <AuthPanel
            defaults={defaults}
            fullWidth
            hideLogo
            redirectTo={redirectTo}
            renderHeader={({ authAction }) => {
              if (authAction === AuthAction.LoginPassword) {
                return <AuthHeader>{t('sign_into_account')}</AuthHeader>;
              }
              if (authAction === AuthAction.LoginGoogle) {
                return <AuthHeader>{t('sign_in_with_google')}</AuthHeader>;
              }
              return null;
            }}
            onAuthenticated={handleAuthenticated}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
