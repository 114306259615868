import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { useCurrentUserContext } from '@/contexts';

type Props = {
  children: ReactNode;
};

/**
 * Require that the user be logged in to access a route.
 */
export const RequireAuth = ({ children }: Props) => {
  // Errors are delegated to the error boundary
  const { isLoading, isAuthenticated } = useCurrentUserContext();
  const location = useLocation();

  if (isLoading) {
    return <AppLoader data-testid="profile-loader" />;
  }
  if (!isAuthenticated) {
    return <Navigate replace state={{ from: location }} to="/auth" />;
  }

  return children;
};
