import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { config } from '@/config';
import { getClientName } from '@/utils/getClientName';

Sentry.init({
  enabled: config.sentry.enabled,
  environment: config.env,
  dsn: config.sentry.dsn,
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
  denyUrls: ['pr.agendahero.com'],
  release: config.release,
  initialScope: (scope) => {
    scope.setTags({
      version: config.version,
      client: getClientName(),
    });
    return scope;
  },
});

export { Sentry };
