import { Icon, Text } from '@chakra-ui/react';
import { AiOutlineTag } from 'react-icons/ai';
import { FaRegUser } from 'react-icons/fa';
import { Pill } from '../Pill';

type Props = {
  type?: 'label' | 'whoLabel';
  children: string;
};

export const Label = ({ type = 'label', children }: Props) => {
  return (
    <Pill>
      {type === 'label' ? (
        <Icon as={AiOutlineTag} boxSize="17px" />
      ) : (
        <Icon as={FaRegUser} boxSize="14px" />
      )}
      <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {children}
      </Text>
    </Pill>
  );
};
