import { Button, Flex, Text, TextProps, Link } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '@/contexts';
import { LaunchOption, LaunchParams } from '@/hooks/useLauncher';
import { useExtensionPopup } from '@/pages/QuickCreate/components/GoogleCalendar/hooks/useExtensionPopup';
import { getAuthUrl } from '@/pages/QuickCreate/components/GoogleCalendar/util';
import { useIsExtension } from '@/pages/QuickCreate/hooks/useIsExtension';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import GoogleCalendarIcon from './GoogleCalendarIcon.svg?react';

type Props = {
  launchOnRedirect?: LaunchOption;
  redirectParams?: LaunchParams;
  email?: string;
  quickCreateJobId?: string;
};

export const GoogleCalendarAuthButton = ({
  launchOnRedirect,
  redirectParams = {},
  quickCreateJobId,
  email,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('settingsModal');
  const { currentUser } = useCurrentUserContext();
  const { data: integrations = [] } = useIntegrations();
  const { isExtension } = useIsExtension();
  const { onAuth: handlePopupAuth } = useExtensionPopup({
    launch: launchOnRedirect,
    launchParams: redirectParams,
    email,
  });

  const handleGoogleSignin = () => {
    if (isExtension) {
      return handlePopupAuth();
    }

    const query: Record<string, string> = { ...redirectParams };
    if (launchOnRedirect) {
      query.launch = launchOnRedirect;
    }

    window.location.href = getAuthUrl(
      currentUser,
      { email },
      quickCreateJobId,
      query
    );
  };

  return (
    <Button
      bgColor="#FFF"
      border="1px solid #dadce0"
      borderRadius="full"
      color="#3c4043"
      display="flex"
      fontFamily="Roboto"
      fontSize="14px"
      fontWeight="500"
      h={38}
      justifyContent="center"
      letterSpacing="0.25px"
      overflow="hidden"
      p="0"
      pos="relative"
      px="4"
      transition="background-color .218s"
      type="button"
      _hover={{
        borderColor: '#d2e3fc',
        backgroundColor: 'rgba(66, 133, 244, .04)',
      }}
      onClick={handleGoogleSignin}
    >
      <Flex align="center" gap="2" left={-1} pos="relative">
        <GoogleCalendarIcon />
        <Text>
          {integrations.length > 0
            ? t('calendars.connect_google_another')
            : t('calendars.connect_google')}
        </Text>
      </Flex>
    </Button>
  );
};

export const GoogleApiDisclaimer = (props: TextProps) => (
  <Text color="customgray.mid" fontSize="sm" {...props}>
    <Trans i18nKey="calendars.google_limited_use" ns="settingsModal">
      Agenda Hero&apos;s use and transfer of information received from Google
      APIs to any other app will adhere to{' '}
      <Link
        colorScheme="dark"
        href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        rel="noreferrer"
        target="_blank"
      >
        Google API Services User Data Policy
      </Link>
      , including the Limited Use requirements.
    </Trans>
  </Text>
);
