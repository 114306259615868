import { forwardRef } from '@chakra-ui/react';
import { MotionText, MotionTextProps } from '@/components/MotionText';

export const SlideText = forwardRef((props: MotionTextProps, ref) => {
  return (
    <MotionText
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      ref={ref}
      {...props}
    />
  );
});
