import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { EntryModalMessage } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import {
  createEntriesQueryKey,
  createEntryMessagesQueryKey,
  createScheduleEntryMessagesQueryKey,
} from '@/utils/queryKeys';
import {
  UpdateEntryMessageMutation,
  UpdateEntryMessageMutationVariables,
} from './useUpdateEntryMessage.generated';

const query = gql`
  mutation UpdateEntryMessage(
    $scheduleId: ID!
    $entryMessageId: ID!
    $instance: DateTime!
    $body: String
    $deliveryTimeType: DeliveryTimeType!
    $hybridDeliveryTime: LocalTime
    $hybridRelativeTime: HybridRelativeTime
    $hybridTimeZone: TimeZone
    $relativeTimeToEntryStart: Int
    $relativeTimeToEntryStartUnit: TimeUnit
    $relativeTimeToEntryStartDirection: TimeDirection
    $recipients: [ID!]
    $sendToEveryone: Boolean
  ) {
    updateEntryMessage(
      scheduleId: $scheduleId
      entryMessageId: $entryMessageId
      instance: $instance
      body: $body
      deliveryTimeType: $deliveryTimeType
      hybridDeliveryTime: $hybridDeliveryTime
      hybridRelativeTime: $hybridRelativeTime
      hybridTimeZone: $hybridTimeZone
      relativeTimeToEntryStart: $relativeTimeToEntryStart
      relativeTimeToEntryStartUnit: $relativeTimeToEntryStartUnit
      relativeTimeToEntryStartDirection: $relativeTimeToEntryStartDirection
      recipients: $recipients
      sendToEveryone: $sendToEveryone
    ) {
      id
    }
  }
`;

export const useUpdateEntryMessage = (entryId: string) => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    UpdateEntryMessageMutation,
    QueryError,
    UpdateEntryMessageMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });

      return Promise.all([
        // invalidate messages tab
        queryClient.invalidateQueries({
          queryKey: createScheduleEntryMessagesQueryKey(scheduleId),
        }),
        // invalidate entry modal
        queryClient.invalidateQueries({
          queryKey: createEntryMessagesQueryKey(scheduleId, entryId),
        }),
      ]);
    },
  });

  const updateEntryMessage = useCallback(
    (
      message: EntryModalMessage,
      options?: MutateOptions<
        UpdateEntryMessageMutation,
        QueryError,
        UpdateEntryMessageMutationVariables
      >
    ) => {
      if (!message.id) {
        throw new Error("Cannot update a message that doesn't have an id");
      }

      const { recipients, instance, ...rest } = message;

      return mutate(
        {
          scheduleId,
          entryMessageId: message.id,
          instance: instance.toUTC().toISO(),
          recipients: recipients.map(({ id }) => id),
          sendToEveryone: !recipients.length,
          ...rest,
        },
        options
      );
    },
    [scheduleId, mutate]
  );

  return {
    updateEntryMessage,
    ...rest,
  };
};
