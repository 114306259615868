import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { QueryError } from '@/utils/errors';
import {
  CreateQuickEntriesJobFromFileMutation,
  CreateQuickEntriesJobFromFileMutationVariables,
} from './useCreateQuickEntriesJobFromFile.generated';

const query = gql`
  mutation createQuickEntriesJobFromFile(
    $file: Upload
    $base64File: Base64FileInput
    $timeZone: TimeZone!
    $instructions: String
  ) {
    createQuickEntriesJobFromFile(
      file: $file
      base64File: $base64File
      timeZone: $timeZone
      instructions: $instructions
    ) {
      id
    }
  }
`;

export const useCreateQuickEntriesJobFromFile = () => {
  return useMutation<
    string,
    QueryError,
    CreateQuickEntriesJobFromFileMutationVariables
  >({
    mutationFn: async (variables) => {
      const result = await gqlClient.request<
        CreateQuickEntriesJobFromFileMutation,
        CreateQuickEntriesJobFromFileMutationVariables
      >(query, variables);

      return result.createQuickEntriesJobFromFile.id;
    },
  });
};
