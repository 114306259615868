import { ReactNode, useMemo } from 'react';
import { Role } from '@/types/gql.generated';
import { createContext } from '@/utils';
import { useActiveViewModule } from './hooks/useActiveViewModule';
import { useSchedule } from './hooks/useSchedule';
import { useScheduleDates } from './hooks/useScheduleDates';
import { ScheduleContextValue } from './types';
import { getScheduleIdFromSlug } from './utils';

type ScheduleContextProps = {
  scheduleSlug: string;
  children: ReactNode | ((value: ScheduleContextValue) => JSX.Element);
};

export const ScheduleContext = createContext<ScheduleContextValue | undefined>(
  undefined,
  'ScheduleContext'
);

export const ScheduleContextProvider = ({
  scheduleSlug,
  children,
}: ScheduleContextProps) => {
  const scheduleId = getScheduleIdFromSlug(scheduleSlug);
  const { data: schedule, isLoading, isError, error } = useSchedule(scheduleId);
  const { viewModule } = useActiveViewModule(scheduleId, schedule);

  const isOwnerRole = schedule?.role === Role.Owner;
  const isEditorRole = schedule?.role === Role.Editor;
  const isViewerRole = schedule?.role === Role.Viewer;
  const isPublicRole = schedule?.role === Role.Public;

  const timeZone = schedule?.timeZone ?? 'UTC';
  const dates = useScheduleDates(viewModule, timeZone);

  // Some views can display dates before the start date, like the month
  // view showing the end of the previous month's week if the current month
  // starts mid-week.
  const visibleStartDate =
    viewModule.getVisibleStartDate?.(dates.startDate) ?? dates.startDate;

  const value: ScheduleContextValue = useMemo(
    () => ({
      schedule,
      scheduleId,
      scheduleSlug,
      timeZone,
      error,
      isLoading,
      isError,
      isOwnerRole,
      isEditorRole,
      isViewerRole,
      isPublicRole,
      viewModule,
      visibleStartDate,
      ...dates,
    }),
    [
      schedule,
      scheduleId,
      scheduleSlug,
      error,
      timeZone,
      isLoading,
      isError,
      isOwnerRole,
      isEditorRole,
      isViewerRole,
      isPublicRole,
      viewModule,
      dates,
      visibleStartDate,
    ]
  );

  return (
    <ScheduleContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ScheduleContext.Provider>
  );
};
