import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';

type AuthAction = 'login' | 'register';

export const useAuthClickHandler = () => {
  const { t } = useTranslation(['header', 'auth']);
  const { trackEvent } = useAnalytics();
  const { scheduleId } = useScheduleContext();
  const { openRegisterModal } = useOpenRegisterModal();
  const toast = useToast();

  const handleAuthClick = useCallback(
    (action: AuthAction) => {
      trackEvent('schedule:click auth-link', { action, scheduleId });

      openRegisterModal({
        onAuthenticated: (result) => {
          toast.notify(
            result == 'register'
              ? t('auth:sign_up_success_toast')
              : t('auth:sign_in_success_toast')
          );
        },
      });
    },
    [t, openRegisterModal, toast, scheduleId, trackEvent]
  );

  return { handleAuthClick };
};
