import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import useEvent from 'react-use-event-hook';
import { gqlClient, queryClient } from '@/lib';
import {
  useScheduleContext,
  useScheduleFilters,
} from '@/pages/Schedule/contexts';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { createDecoratedEntry } from '@/pages/Schedule/utils';
import { QueryError } from '@/utils/errors';
import { EntriesContextFragment } from '../EntriesContextFragment';
import { EntriesContextOptions } from '../types';
import { createEntriesQueryConfig } from '../utils';
import {
  GetScheduleEntriesQuery,
  GetScheduleEntriesQueryVariables,
} from './useEntries.generated';

const query = gql`
  ${EntriesContextFragment}
  query GetScheduleEntries(
    $scheduleId: ID!
    $startDate: DateTime
    $endDate: DateTime
    $categories: [ID!]
    $whoLabels: [ID!]
    $labels: [ID!]
    $feeds: [ID!]
    $includeMessages: Boolean!
    $includeRsvps: Boolean!
  ) {
    getSchedule(scheduleId: $scheduleId) {
      entries(
        startDate: $startDate
        endDate: $endDate
        categories: $categories
        whoLabels: $whoLabels
        labels: $labels
        feeds: $feeds
      ) {
        ...EntriesContextFragment
      }
    }
  }
`;

export const useEntries = (
  startDate: DateTime,
  endDate: DateTime,
  options?: EntriesContextOptions
) => {
  const {
    scheduleId,
    isLoading: isLoadingSchedule,
    timeZone: scheduleTimeZone,
  } = useScheduleContext();
  const { filterIds } = useScheduleFilters();
  const { queryKey, variables } = useMemo(() => {
    return createEntriesQueryConfig(
      scheduleId,
      startDate,
      endDate,
      filterIds,
      options
    );
  }, [scheduleId, startDate, endDate, filterIds, options]);

  const selector = useCallback(
    (data: GetScheduleEntriesQuery): DecoratedEntry[] => {
      return (
        data.getSchedule?.entries.map((entry) =>
          createDecoratedEntry(entry, scheduleTimeZone)
        ) ?? []
      );
    },
    [scheduleTimeZone]
  );

  const { data, error, isLoading, isFetching, isSuccess, isPlaceholderData } =
    useQuery({
      queryKey,
      queryFn: () =>
        gqlClient.request<
          GetScheduleEntriesQuery,
          GetScheduleEntriesQueryVariables
        >(query, variables),
      enabled: !isLoadingSchedule, // don't fetch entries until we have the schedule's tz
      placeholderData: keepPreviousData,
      select: selector,
    });

  const prefetchData = useEvent((startDate: DateTime, endDate: DateTime) => {
    const { queryKey, variables } = createEntriesQueryConfig(
      scheduleId,
      startDate,
      endDate,
      filterIds,
      options
    );
    return queryClient.prefetchQuery<
      GetScheduleEntriesQuery,
      QueryError,
      DecoratedEntry[]
    >({
      queryKey,
      queryFn: () =>
        gqlClient.request<
          GetScheduleEntriesQuery,
          GetScheduleEntriesQueryVariables
        >(query, variables),
    });
  });

  return {
    entries: data ?? [],
    prefetchData,
    error,
    isLoading,
    isFetching,
    isSuccess,
    isPlaceholderData,
  };
};
