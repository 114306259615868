import { i18n } from '@/i18n';
import { createViewModule } from '../utils';
import {
  adjustEndDate,
  adjustStartDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
} from './handlers';
import Icon from './icon.svg?react';

export const SummaryModule = createViewModule({
  id: 'summary',
  adjustStartDate,
  adjustEndDate,
  adjustTodayStartDate,
  adjustTodayEndDate,
  viewSelect: {
    label: i18n.t('actionBar:view_select.summary'),
    Icon: Icon,
  },
  containerProps: {
    bg: 'transparent',
  },
});
