import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { TIME_ZONE_UTC } from '@/constants';
import { EntryFragment } from '@/fragments';
import { gqlClient, queryClient } from '@/lib';
import { useEntriesCache, useScheduleContext } from '@/pages/Schedule/contexts';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { undecorateEntry } from '@/pages/Schedule/utils/undecorateEntry';
import { CreateEntryInput } from '@/types/gql.generated';
import { datePartInZone } from '@/utils/dates';
import { QueryError } from '@/utils/errors';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import {
  CreateEntryMutation,
  CreateEntryMutationVariables,
} from './useCreateEntry.generated';

export const query = gql`
  ${EntryFragment}
  mutation CreateEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      ...Entry
    }
  }
`;

export const createInput = (
  scheduleId: string,
  entry: DecoratedEntry
): CreateEntryInput => ({
  scheduleId,
  entryId: entry.id,
  title: entry.title,
  emoji: entry.emoji,
  locationWithPlace: entry.locationWithPlace,
  description: entry.description,
  notes: entry.notes,
  timeZone: entry.timeZone,
  categoryId: entry.category?.id,
  whoLabels: entry.whoLabels.map(({ id }) => ({ id })),
  labels: entry.labels.map(({ id }) => ({ id })),
  recurrences: entry.recurrences.map(
    ({ startDate, endDate, rule, isOnDay }) => ({
      startDate: isOnDay
        ? datePartInZone(startDate, TIME_ZONE_UTC).toISO()
        : startDate.toUTC().toISO(),
      endDate: isOnDay
        ? datePartInZone(endDate, TIME_ZONE_UTC).toISO()
        : endDate.toUTC().toISO(),
      isOnDay,
      rule,
    })
  ),
});

export const useCreateEntry = () => {
  const { scheduleId } = useScheduleContext();
  const entriesCache = useEntriesCache();

  const { mutate, isPending } = useMutation<
    CreateEntryMutation,
    QueryError,
    DecoratedEntry,
    DecoratedEntry
  >({
    mutationFn: (entry) => {
      const input = createInput(scheduleId, entry);

      return gqlClient.request<
        CreateEntryMutation,
        CreateEntryMutationVariables
      >(query, { input });
    },
    onMutate: (entry) => {
      // insert the entry into the cache optimistically
      const rawEntry = undecorateEntry(entry);
      entriesCache.insert(rawEntry);
      return entry;
    },
    onSuccess: (result) => {
      // update cache(s) that hold this entry with the server's version.
      // this ensures that the server remains the source of truth in case
      // the client gets out of sync. there are some properties that the client
      // doesn't fill out when composing an entry yet, like `feed`.
      entriesCache.update(result.createEntry);

      // invalidate as a safe-guard to ensure we're always working with the latest.
      // this is necessary because the optimistic update logic may not have placed
      // the entry in all the caches that it should have, particularly if the entry
      // is recurring.
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });
    },
    onError: (err, variables, entry) => {
      if (entry) {
        entriesCache.remove(entry.id);
      }
    },
  });

  return {
    createEntry: mutate,
    isPending,
  };
};
