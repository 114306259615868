import { Flex } from '@chakra-ui/react';
import { Description } from './Description';
import { Labels } from './Labels';
import { Location } from './Location';
import { Time } from './Time';

export const Metadata = () => {
  return (
    <>
      <Flex direction="column" gap="2" ml="-0.5" mt="-1">
        <Time data-time />
        <Location data-location />
      </Flex>
      <Description data-description />
      <Labels />
    </>
  );
};
