import {
  READ_CALENDAR_SCOPES,
  WRITE_SCOPES,
} from '@/components/auth/utils/googleCalendar';
import { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import {
  Calendar,
  IntegrationStatus,
  IntegrationType,
} from '@/types/gql.generated';

export const isPrimaryCalendar = (
  integration: DecoratedIntegration,
  calendar: Calendar
) => {
  return calendar.id === integration.externalId;
};

export const getSortedCalendars = (
  integration: DecoratedIntegration
): Calendar[] => {
  return integration.calendars.sort((a, b) => {
    if (a.readonly !== b.readonly) {
      return a.readonly ? 1 : -1;
    }
    if (isPrimaryCalendar(integration, a)) {
      return -1;
    }
    if (isPrimaryCalendar(integration, b)) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
};

const canWrite = (integration: DecoratedIntegration): boolean => {
  const scopes = integration.scope.split(' ');
  return WRITE_SCOPES.every((writeScope) => scopes.includes(writeScope));
};

const canReadCalendar = (integration: DecoratedIntegration): boolean => {
  const scopes = integration.scope.split(' ');
  return READ_CALENDAR_SCOPES.every((scope) => scopes.includes(scope));
};

export const isSuitableIntegration = (
  integration: DecoratedIntegration
): boolean => {
  return (
    integration.type === IntegrationType.GoogleCalendar &&
    integration.status === IntegrationStatus.Success &&
    canWrite(integration) &&
    canReadCalendar(integration)
  );
};

export const hasGoogleIntegration = (
  integrations: DecoratedIntegration[]
): boolean => {
  return integrations.some(isSuitableIntegration);
};

export const selectGoogleIntegrations = (
  integrations: DecoratedIntegration[]
): DecoratedIntegration[] => {
  return integrations.filter(isSuitableIntegration);
};

export const getScheduleSourceCount = (
  integration: DecoratedIntegration
): number => {
  return integration.calendars.reduce<number>((count, calendar) => {
    return count + calendar.scheduleCount;
  }, 0);
};
