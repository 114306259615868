import { Prompt, PromptSource } from '@/pages/QuickCreate/types';
import { isPDF } from './file';

export enum ConversionType {
  FormInput = 'form_input',
  Screenshot = 'screenshot',
  PageText = 'page_text',
  Image = 'image',
  Pdf = 'pdf',
}

export const getConversionType = (
  prompt: Prompt,
  source: PromptSource
): ConversionType | 'unknown' => {
  if (prompt.base64) {
    return ConversionType.Screenshot;
  }
  if (prompt.file instanceof File) {
    if (isPDF(prompt.file)) {
      return ConversionType.Pdf;
    }
    return ConversionType.Image;
  }
  if (source === 'post-message') {
    return ConversionType.PageText;
  }
  if (prompt.html.length > 0) {
    return ConversionType.FormInput;
  }
  // Handle case where we add a new mechanism and forget to track it
  return 'unknown';
};
