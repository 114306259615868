import { ChakraProvider } from '@chakra-ui/react';
import { QueryNormalizerProvider } from '@normy/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigCatProvider } from 'configcat-react';
import { useEffect } from 'react';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { config } from '@/config';
import { CurrentUserContextProvider } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import { queryClient } from '@/lib/query-client';
import { AppRouter } from './router';
import { theme } from './theme';
import './i18n';

export const App = (): JSX.Element => {
  const { init: initAnalytics, trackEvent } = useAnalytics();

  useEffect(() => {
    initAnalytics();
  }, [initAnalytics]);

  useEffect(() => {
    const handler = () => trackEvent('util:focus');
    window.addEventListener('focus', handler);

    return () => window.removeEventListener('focus', handler);
  }, [trackEvent]);

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <QueryNormalizerProvider
          queryClient={queryClient}
          normalizerConfig={{
            devLogging: false,
            getNormalizationObjectKey: (obj) => {
              if ('contextId' in obj && typeof obj.contextId === 'string') {
                return obj.contextId;
              }
              return typeof obj.id === 'string' ? obj.id : undefined;
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools buttonPosition="bottom-left" />
            <ConfigCatProvider sdkKey={config.configCat.key}>
              <CurrentUserContextProvider>
                <AppRouter />
              </CurrentUserContextProvider>
            </ConfigCatProvider>
          </QueryClientProvider>
        </QueryNormalizerProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
};
