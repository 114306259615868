import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import {
  useScheduleContext,
  GetScheduleQuery,
} from '@/pages/Schedule/contexts';
import { ScheduleHeaderType } from '@/types/gql.generated';
import { QueryError } from '@/utils/errors';
import {
  ToggleScheduleHeaderTypeMutation,
  ToggleScheduleHeaderTypeMutationVariables,
} from './useToggleScheduleHeaderType.generated';

const query = gql`
  mutation ToggleScheduleHeaderType(
    $scheduleId: ID!
    $headerType: ScheduleHeaderType!
  ) {
    updateSchedule(scheduleId: $scheduleId, headerType: $headerType) {
      id
      header {
        id
        imageBaseUrl
        type
      }
    }
  }
`;

export const useToggleScheduleHeaderType = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    ToggleScheduleHeaderTypeMutation,
    QueryError,
    ToggleScheduleHeaderTypeMutationVariables,
    GetScheduleQuery
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        ToggleScheduleHeaderTypeMutation,
        ToggleScheduleHeaderTypeMutationVariables
      >(query, variables),
  });

  const toggleScheduleHeaderType = (
    headerType: ScheduleHeaderType,
    options?: MutateOptions<
      ToggleScheduleHeaderTypeMutation,
      QueryError,
      ToggleScheduleHeaderTypeMutationVariables
    >
  ) => {
    return mutate({ scheduleId, headerType }, options);
  };

  return {
    toggleScheduleHeaderType,
    isPending,
  };
};
