import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, Sentry } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { QueryError } from '@/utils/errors';
import { isPDF, toBase64 } from '@/utils/file';
import {
  CreateEntriesJobFromFileMutation,
  CreateEntriesJobFromFileMutationVariables,
} from './useCreateEntriesJobFromFile.generated';

const query = gql`
  mutation createEntriesJobFromFile(
    $file: Upload
    $base64File: Base64FileInput
    $scheduleId: ID!
    $instructions: String
    $createDrafts: Boolean
  ) {
    createEntriesJobFromFile(
      file: $file
      base64File: $base64File
      scheduleId: $scheduleId
      instructions: $instructions
      createDrafts: $createDrafts
    ) {
      id
    }
  }
`;

export const useCreateEntriesJobFromFile = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    CreateEntriesJobFromFileMutation,
    QueryError,
    CreateEntriesJobFromFileMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateEntriesJobFromFileMutation,
        CreateEntriesJobFromFileMutationVariables
      >(query, variables),
  });

  const createEntriesJobFromFile = useCallback(
    async (
      file: File,
      instructions: string,
      options?: MutateOptions<
        CreateEntriesJobFromFileMutation,
        QueryError,
        CreateEntriesJobFromFileMutationVariables
      >
    ) => {
      const variables: CreateEntriesJobFromFileMutationVariables = {
        scheduleId,
        instructions,
        createDrafts: true,
      };

      if (isPDF(file)) {
        try {
          const data = await toBase64(file);

          return mutate(
            {
              ...variables,
              base64File: {
                mimetype: 'application/pdf',
                filename: file.name,
                data: data.replace('data:application/pdf;base64,', ''),
              },
            },
            options
          );
        } catch (err) {
          const error = new Error('Error converting image to PDF');
          error.cause = err;
          Sentry.captureException(err);
        }
      }

      return mutate({ ...variables, file }, options);
    },
    [mutate, scheduleId]
  );

  return { createEntriesJobFromFile, ...rest };
};
