import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback, useState } from 'react';
import { EntryFragment } from '@/fragments/entry';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { DecoratedEntry } from '@/pages/Schedule/types';
import { createDecoratedEntry } from '@/pages/Schedule/utils';
import { isJobInProgress } from '@/pages/Schedule/utils/jobs';
import { JobStatus } from '@/types/gql.generated';
import { ErrorWithCode, QueryError } from '@/utils/errors';
import { createPollEntriesJobStatusQueryKey } from '@/utils/queryKeys';
import {
  PollEntriesJobStatusQuery,
  PollEntriesJobStatusQueryVariables,
} from './usePollEntriesJobStatus.generated';

export type Job = Omit<
  PollEntriesJobStatusQuery['getEntriesJobForSchedule'],
  'entries'
> & {
  entries: DecoratedEntry[];
};

const query = gql`
  ${EntryFragment}
  query PollEntriesJobStatus($scheduleId: ID!, $entriesJobId: ID!) {
    getEntriesJobForSchedule(
      scheduleId: $scheduleId
      entriesJobId: $entriesJobId
    ) {
      id
      scheduleId
      type
      status
      errorCode
      errorMessage
      createdAt
      prompt
      file {
        id
        url
      }
      entries {
        ...Entry
      }
    }
  }
`;

export const usePollEntriesJobStatus = () => {
  const { scheduleId, timeZone } = useScheduleContext();
  const [activeJobId, setActiveJobId] = useState<string | null>(null);
  const queryKey = createPollEntriesJobStatusQueryKey(scheduleId, activeJobId);
  const enabled = !!activeJobId;

  const selector = useCallback(
    (data: PollEntriesJobStatusQuery): Job => ({
      ...data.getEntriesJobForSchedule,
      entries: (data.getEntriesJobForSchedule.entries ?? []).map((entry) =>
        createDecoratedEntry(entry, timeZone)
      ),
    }),
    [timeZone]
  );

  const { data: job, error: pollError } = useQuery<
    PollEntriesJobStatusQuery,
    QueryError,
    Job
  >({
    queryKey,
    enabled,
    select: selector,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: () => {
      return gqlClient.request<
        PollEntriesJobStatusQuery,
        PollEntriesJobStatusQueryVariables
      >(query, {
        scheduleId,
        entriesJobId: activeJobId!,
      });
    },
    refetchInterval: (query) => {
      const job = query.state.data?.getEntriesJobForSchedule;

      if (job && isJobInProgress(job)) {
        return 1000;
      }
      return false;
    },
  });

  const isPending = !!job && isJobInProgress(job);
  const isSuccess =
    job?.status === JobStatus.Completed || job?.status === JobStatus.Reviewed;

  const poll = (jobId: string) => setActiveJobId(jobId);
  const reset = () => setActiveJobId(null);

  const error =
    pollError ||
    (job?.status === JobStatus.Failed &&
      new ErrorWithCode(job.errorMessage ?? 'Job failed'));

  return {
    poll,
    job,
    error,
    isPending,
    isSuccess,
    reset,
  };
};
