import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAuthErrorMessage } from '@/components/auth';
import { useToast } from '@/hooks/useToast';
import { useChangePassword } from '../hooks/useChangePassword';

export const PasswordForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { mutate, isError, isPending, error } = useChangePassword();
  const { t } = useTranslation('settingsModal');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirm: '',
    },
  });

  const password = watch('password');

  return (
    <form
      noValidate
      onSubmit={handleSubmit(({ currentPassword, password }) => {
        mutate(
          { currentPassword, updatedPassword: password },
          {
            onSuccess: () => {
              toast.notify(t('account.password_section.success_toast'));
              navigate('/');
            },
          }
        );
      })}
    >
      <VStack alignItems="flex-start" spacing="24px">
        {isError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{getAuthErrorMessage(error)}</AlertTitle>
          </Alert>
        )}

        <FormControl isInvalid={!!errors.currentPassword}>
          <FormLabel htmlFor="currentPassword">
            {t('account.password_section.current_password')}
          </FormLabel>
          <Input
            autoComplete="current-password"
            id="currentPassword"
            type="password"
            {...register('currentPassword', {
              required: t('validation.required', { ns: 'auth' }),
            })}
          />
          <FormErrorMessage>{errors.currentPassword?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <FormLabel htmlFor="password">
            {t('account.password_section.password')}
          </FormLabel>
          <Input
            autoComplete="new-password"
            id="password"
            type="password"
            {...register('password', {
              required: t('validation.required', { ns: 'auth' }),
              minLength: {
                value: 8,
                message: t('validation.password_too_short', { ns: 'auth' }),
              },
            })}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.confirm}>
          <FormLabel htmlFor="confirm">
            {t('account.password_section.confirm')}
          </FormLabel>
          <Input
            autoComplete="new-password"
            id="confirm"
            type="password"
            {...register('confirm', {
              validate: (value) =>
                (value && value === password) ||
                t('validation.passwords_dont_match', { ns: 'auth' }),
            })}
          />
          <FormErrorMessage>{errors.confirm?.message}</FormErrorMessage>
        </FormControl>

        <Button isLoading={isPending} type="submit" variant="secondary">
          {t('account.password_section.save')}
        </Button>
      </VStack>
    </form>
  );
};
