import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { notionVariantBaseStyles } from '@/theme/styles';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
);

export const Select = defineMultiStyleConfig({
  baseStyle: {
    field: {
      borderRadius: '8px',
      _focusVisible: {
        outline: `1px solid`,
        outlineColor: 'brand.500',
      },
    },
  },
  variants: {
    underline: {
      field: {
        borderRadius: 0,
        borderBottomWidth: '1px',
        borderBottomColor: 'customgray.gray',
        fontSize: 'md', // Font size is always the same regardless of "size" prop
        padding: 0,
        '~ div': {
          display: 'none',
        },
      },
    },
    notion: {
      field: {
        ...notionVariantBaseStyles,
        paddingRight: undefined,
      },
      icon: {
        color: 'customgray.mid',
      },
    },
  },
});
