import { Box, BoxProps, Flex, FlexProps, forwardRef } from '@chakra-ui/react';

type Props = FlexProps & {
  icon: JSX.Element;
  iconPlacement?: 'top' | 'center';
  containerProps?: BoxProps;
};

export const EntryModalField = forwardRef(
  (
    {
      icon,
      iconPlacement = 'center',
      containerProps,
      children,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <Flex
        align={iconPlacement === 'top' ? 'start' : 'center'}
        minH="32px"
        ref={ref}
        w="100%"
        {...props}
      >
        <Box
          transform={iconPlacement === 'top' ? 'translateY(9px)' : undefined}
          w="36px"
        >
          {icon}
        </Box>
        <Flex align="center" flex="1" {...containerProps}>
          {children}
        </Flex>
      </Flex>
    );
  }
);
