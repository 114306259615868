import { Alert, AlertProps, Icon, Text } from '@chakra-ui/react';
import { MdOutlineWarning } from 'react-icons/md';

export const AiDisabledAlert = (props: AlertProps) => {
  return (
    <Alert alignItems="flex-start" flexDir="column" variant="purple" {...props}>
      <Text alignItems="center" display="flex" fontWeight="bold" gap="1.5">
        <Icon as={MdOutlineWarning} boxSize="22px" color="yellow.400" />
        ️Oops, we&apos;re sorting out a glitch!
      </Text>
      <Text>
        Hey there! It seems like we&apos;ve hit a bit of a snag on our end and
        this tool is temporarily out of order. Our team is already on it,
        working hard to fix the issue as quickly as possible.
      </Text>
    </Alert>
  );
};
