import { GraphQLError } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { createClient } from 'graphql-ws';
import { config } from '@/config';
import { Sentry } from '@/lib';

export const gqlClient = new GraphQLClient(`${config.api.url}/api/graphql`, {
  credentials: 'include',
  mode: 'cors',
});

export const gqlWsClient = createClient({
  url: `${config.websockets.url}/api/graphql`,
});

const isCloseEvent = (value: unknown): value is CloseEvent => {
  const valueAsCloseEvent = value as CloseEvent;
  return Boolean(valueAsCloseEvent.code && valueAsCloseEvent.reason);
};

/*
 * Besides the errors being `Error` and `readonly GraphQLError[]`, it
 * can also be a `CloseEvent`, but to avoid bundling DOM typings because
 * the client can run in Node env too, you should assert the close event
 * type during implementation.
 */
export const handleError = (error: unknown): void => {
  if (isCloseEvent(error)) {
    Sentry.captureException(
      new Error(`Websocket closed: ${error.code}, ${error.reason}`)
    );
  } else if (Array.isArray(error)) {
    error.map((e: GraphQLError) => Sentry.captureException(e));
  } else if (error instanceof Error) {
    Sentry.captureException(error);
  }
};
