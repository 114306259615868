import { DateTime } from 'luxon';
import {
  DeliveryTimeType,
  EntryMessage,
  HybridRelativeTime,
} from '@/types/gql.generated';

export const hybridRelativeTimeLookup = {
  [HybridRelativeTime.DayOf]: 'day of',
  [HybridRelativeTime.DayBefore]: 'day before',
  [HybridRelativeTime.DayAfter]: 'day after',
} as const;

export type Message = Pick<
  EntryMessage,
  | 'deliveryTimeType'
  | 'relativeTimeToEntryStart'
  | 'relativeTimeToEntryStartUnit'
  | 'relativeTimeToEntryStartDirection'
  | 'hybridDeliveryTime'
  | 'hybridRelativeTime'
  | 'hybridTimeZone'
> & {
  sendAt?: DateTime;
};

export const summarizeMessageSendDate = (message: Message): string => {
  if (message.deliveryTimeType === DeliveryTimeType.Relative) {
    return [
      message.relativeTimeToEntryStart,
      message.relativeTimeToEntryStartUnit?.toLowerCase(),
      message.relativeTimeToEntryStartDirection?.toLowerCase(),
      ...(message.sendAt
        ? [
            'at',
            message.sendAt.toFormat('h:mm a').toLowerCase(),
            message.sendAt.toFormat('ZZZZ'),
          ]
        : []),
    ].join(' ');
  }

  if (
    message.hybridDeliveryTime &&
    message.hybridRelativeTime &&
    message.hybridTimeZone
  ) {
    const hybridDeliveryTime = DateTime.fromFormat(
      message.hybridDeliveryTime,
      'HH:mm',
      { zone: message.hybridTimeZone }
    );

    return [
      hybridDeliveryTime.toFormat('h:mm a').toLowerCase(),
      hybridDeliveryTime.toFormat('ZZZZ'),
      hybridRelativeTimeLookup[message.hybridRelativeTime],
    ].join(' ');
  }

  return '';
};
