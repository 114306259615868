import debounce from 'lodash.debounce';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useCurrentUserContext } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import { QuickEntriesJobType } from '@/types/gql.generated';
import { createContext } from '@/utils';
import { getConversionType } from '@/utils/convertEntry';
import { useMultiExportStore } from '../components/GoogleCalendar/hooks';
import { useCreateQuickEntries } from '../hooks/useCreateQuickEntries';
import { usePlatformExports } from '../hooks/usePlatformExports';
import { useUpdateQuickEntry } from '../hooks/useUpdateQuickEntry';
import {
  DecoratedQuickCreateEntry,
  QuickCreateState,
  DecoratedQuickCreateEntryJob,
  Prompt,
  PromptSource,
  DecoratedQuickCreateEntryPage,
} from '../types';
import { useInitialPromptType } from './hooks/useInitialPromptType';
import { usePrompt } from './hooks/usePrompt';

type State = {
  readOnly: boolean;
  job: DecoratedQuickCreateEntryJob | null;
  setJob: (job: DecoratedQuickCreateEntryJob | null) => void;
  page?: DecoratedQuickCreateEntryPage | null;
  createEntries: (
    prompt: Prompt,
    options?: {
      onSuccess?: () => void;
      onError: () => void;
    }
  ) => void;
  updateEntry: (
    entryId: string,
    updatedEntry: Partial<DecoratedQuickCreateEntry>
  ) => void;
  state: QuickCreateState;
  resetAppState: () => void;
  prompt: Prompt;
  promptType: QuickEntriesJobType;
  promptSource: PromptSource;
  setPrompt: (prompt: Partial<Prompt>, source?: PromptSource) => void;
  setPromptSource: (value: PromptSource) => void;
  setPromptType: (value: QuickEntriesJobType) => void;
  setPromptFromJob: (job: DecoratedQuickCreateEntryJob) => void;
  instructions: string;
  setInstructions: (value: string) => void;
  createMutation: ReturnType<typeof useCreateQuickEntries>;
  updateMutation: ReturnType<typeof useUpdateQuickEntry>;
  lastEmailAddress: string;
  setLastEmailAddress: (value: string) => void;
};

type Props = {
  readOnly?: boolean;
  job?: DecoratedQuickCreateEntryJob | null;
  page?: DecoratedQuickCreateEntryPage | null;
  onReset?: () => void;
  children: ReactNode;
};

export const QuickCreateContext = createContext<State | undefined>(
  undefined,
  'QuickCreateContext'
);

export const QuickCreateContextProvider = ({
  readOnly = false,
  job: jobProp,
  page,
  onReset,
  children,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { trackEvent } = useAnalytics();
  const [job, setJob] = useState<DecoratedQuickCreateEntryJob | null>(
    jobProp ?? null
  );
  const [promptSource, setPromptSource] = useState<PromptSource>(null);
  const [promptType, setPromptType] = useInitialPromptType();
  const [instructions, setInstructions] = useState<string>('');
  const updateMutation = useUpdateQuickEntry();
  const createMutation = useCreateQuickEntries(promptType);
  const { reset: resetMultiExports } = usePlatformExports();
  const closeMultiExportCard = useMultiExportStore((store) => store.close);
  const { prompt, setPrompt: setPromptState, resetPrompt } = usePrompt();

  const [lastEmailAddress = '', setLastEmailAddress] = useLocalStorage(
    '_ah_magic_email',
    currentUser?.email || ''
  );

  useEffect(() => {
    setJob(jobProp ?? null);
  }, [jobProp]);

  let state: QuickCreateState = 'default';
  if (createMutation.isPending) {
    state = 'loading';
  } else if (job?.entries && job.entries.length > 0) {
    state = 'edit';
  }

  const createEntries: State['createEntries'] = (prompt, options) => {
    setJob(null);
    resetMultiExports();

    trackEvent('quick-create:convert content', {
      conversionType: getConversionType(prompt, promptSource),
    });

    createMutation.createQuickEntries(prompt, instructions, {
      ...options,
      onSuccess: (job) => {
        setJob(job);
        options?.onSuccess?.();
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePersistUpdates = useCallback(
    debounce((entry: DecoratedQuickCreateEntry) => {
      updateMutation.updateQuickEntry(entry);
    }, 500),
    []
  );

  const updateEntry: State['updateEntry'] = (entryId, updatedEntry) => {
    const entry = job?.entries.find((entry) => entry.id === entryId);
    if (!entry) {
      return;
    }
    const entryWithUpdates: DecoratedQuickCreateEntry = {
      ...entry,
      ...updatedEntry,
    };
    // update the UI immediately
    setJob((prevJob) => ({
      ...prevJob,
      entries:
        prevJob?.entries.map((entry) => {
          return entry.id === entryId ? entryWithUpdates : entry;
        }) ?? [],
    }));
    // persist changes periodically
    debouncePersistUpdates(entryWithUpdates);
  };

  const setPrompt: State['setPrompt'] = (prompt, source = 'user-input') => {
    setPromptState(prompt);
    setPromptSource(source);
  };

  const setPromptFromJob = (job: DecoratedQuickCreateEntryJob): void => {
    const textPrompt = job.prompt ?? '';
    setPromptType(job.type!);
    setPrompt({ text: textPrompt, html: textPrompt, file: job.file });
  };

  const resetAppState: State['resetAppState'] = useCallback(() => {
    createMutation.reset();
    updateMutation.reset();
    setPromptSource(null);
    setJob(null);
    resetPrompt();
    resetMultiExports();
    closeMultiExportCard();
    onReset?.();
  }, [
    createMutation,
    updateMutation,
    resetMultiExports,
    resetPrompt,
    closeMultiExportCard,
    onReset,
  ]);

  const value: State = {
    readOnly,

    job,
    setJob,
    createEntries,
    updateEntry,

    page,

    state,
    resetAppState,

    prompt,
    promptType,
    promptSource,
    setPrompt,
    setPromptType,
    setPromptSource,
    setPromptFromJob,

    instructions,
    setInstructions,

    createMutation,
    updateMutation,

    lastEmailAddress,
    setLastEmailAddress,
  };

  return (
    <QuickCreateContext.Provider value={value}>
      {children}
    </QuickCreateContext.Provider>
  );
};
