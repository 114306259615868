import { AvatarProps, ResponsiveObject, Text, Flex } from '@chakra-ui/react';
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { theme } from '@/theme';

// Custom avatar component avoids flickering on re-renders
// https://github.com/chakra-ui/chakra-ui/issues/149

const getInitials = (name?: string | null, size?: AvatarProps['size']) => {
  if (size === '2xs') {
    return (name || '').charAt(0);
  }
  const [firstName, lastName] = (name ?? '').split(' ');
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0);
};

const sizes: ResponsiveObject<string> = {
  '2xs': theme.sizes[4],
  xs: theme.sizes[6],
  sm: theme.sizes[8],
  md: theme.sizes[12],
  lg: theme.sizes[16],
  xl: theme.sizes[24],
  '2xl': theme.sizes[32],
  full: theme.sizes['100%'],
};

const fontSizes: Record<string, string> = {
  '2xs': '10px',
  xs: '12px',
  sm: '13px',
  '2xl': '38px',
};

type Props = Omit<AvatarProps, 'name'> & {
  name?: string | null;
};

const Component = (
  { children, ...props }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { name, size = 'md', src, showBorder, ...avatarProps } = props;
  const [imageUnavailable, setImageUnavailable] = useState(!src);
  const initials = useMemo(() => getInitials(name, size), [name, size]);

  // Preload image
  useLayoutEffect(() => {
    if (src) {
      const image = new Image();
      image.src = src;
    }
  }, [src]);

  useEffect(() => {
    setImageUnavailable(!src);
  }, [src]);

  return (
    <Flex
      align="center"
      bgColor="brand.500"
      borderColor="white"
      borderRadius="100%"
      borderWidth={showBorder ? '2px' : '0'}
      boxSize={sizes[size as string]}
      color="white"
      flex="none"
      justify="center"
      pos="relative"
      ref={ref}
      {...avatarProps}
    >
      {imageUnavailable ? (
        <Text
          color="inherit"
          fontSize={fontSizes[size as string] ?? 20}
          fontWeight="400"
          textTransform="uppercase"
        >
          {initials}
        </Text>
      ) : (
        <img
          alt=""
          src={src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '100%',
            overflow: 'hidden',
          }}
          onError={() => setImageUnavailable(true)}
        />
      )}
      {children}
    </Flex>
  );
};

export const Avatar = forwardRef(Component);
