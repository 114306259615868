import { MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useTranslation } from 'react-i18next';
import { ScheduleFragment } from '@/fragments/schedule';
import { useAnalytics } from '@/hooks/useAnalytics';
import { gqlClient } from '@/lib';
import { getLocalTimeZone } from '@/utils/dates';
import { QueryError } from '@/utils/errors';
import {
  CreateScheduleMutation,
  CreateScheduleMutationVariables,
} from './useCreateSchedule.generated';

const query = gql`
  ${ScheduleFragment}
  mutation CreateSchedule(
    $title: String!
    $timeZone: TimeZone
    $type: ScheduleType
  ) {
    createSchedule(title: $title, timeZone: $timeZone, type: $type) {
      ...Schedule
    }
  }
`;

export const useCreateSchedule = () => {
  const { t } = useTranslation('common');
  const { incrementUserProperty } = useAnalytics();

  const { mutate, ...rest } = useMutation<
    CreateScheduleMutation['createSchedule'],
    QueryError,
    CreateScheduleMutationVariables
  >({
    mutationFn: async (variables) => {
      const result = await gqlClient.request<
        CreateScheduleMutation,
        CreateScheduleMutationVariables
      >(query, variables);
      return result?.createSchedule;
    },
  });

  const createSchedule = (
    defaults: Partial<CreateScheduleMutationVariables>,
    options: MutateOptions<
      CreateScheduleMutation['createSchedule'],
      QueryError,
      CreateScheduleMutationVariables
    > = {}
  ) => {
    const { onSuccess, ...restOptions } = options;
    return mutate(
      {
        title: t('untitled_schedule'),
        timeZone: getLocalTimeZone(),
        ...defaults,
      },
      {
        onSuccess: (...args) => {
          incrementUserProperty('scheduleCount', 1);
          onSuccess?.(...args);
        },
        ...restOptions,
      }
    );
  };

  return {
    ...rest,
    createSchedule,
  };
};
